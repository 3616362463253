import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import * as moment from "moment";

export const ExcelService = {
  ExcelData,
  ExcelData1,
  ExcelData2,
};

function ExcelData(data, fileName) {
  let todayDate = moment(new Date()).format("DD-MM-YYYY");
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const ws = XLSX.utils.json_to_sheet(data);

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const datas = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(datas, fileName + todayDate + fileExtension);
  //return;
}
function ExcelData1(successData, failedData, fileName) {
  // Get today's date in the desired format
  let todayDate = moment(new Date()).format("DD-MM-YYYY");
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  // Create sheets from the provided data
  const successSheet = XLSX.utils.json_to_sheet(successData);
  const failedSheet = XLSX.utils.json_to_sheet(failedData);

  // Create the workbook with two sheets
  const wb = {
    Sheets: {
      "Success Records": successSheet,
      "Failed Records": failedSheet,
    },
    SheetNames: ["Success Records", "Failed Records"],
  };

  // Write the workbook to a binary array
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  // Create a blob and save the file
  const datas = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(datas, fileName + todayDate + fileExtension);
}

async function ExcelData2(data, fileName) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Data");

  // Dynamically set the columns based on the first object keys
  worksheet.columns = Object.keys(data[0]).map((key) => ({ header: key, key }));

  // Add the data rows
  data.forEach((row) => worksheet.addRow(row));

  const buffer = await workbook.xlsx.writeBuffer();

  // Create a Blob from the buffer and save it using FileSaver
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Save the Excel file
  FileSaver.saveAs(
    blob,
    `${fileName}_${moment(new Date()).format("DD-MM-YYYY")}.xlsx`
  );
}
