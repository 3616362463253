// eslint-disable-next-line no-unused-vars
import RestDataSource from "../../services/restdatasource";
import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import MyProfile from "./myprofile";
import MyProgress from "./myprogress";
import MyCalander from "./mycalander";
import MyStatus from "../statusStepper/statusStepper";
import MyProductExtract from "./myproduct";
import MySocialStreaming from "./mysocialstreaming";
import MyProgressPath from "./myprogresspath";
import MyProductAllExtract from "./myallproduct";
import MyProductNoRecord from "./myproductNoRecords";
import MyPrequisite from "./myprequesite";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import linq from "linq";
import { LoginService } from "../../services/login.service";
import { ProductService } from "../../services/product.service";
import {
  errorNotification,
  error,
  warning,
  warningNotification,
  success,
  successNotification,
  info2, infoNotification2,
} from "../notification/notifications";
import { DeploymentMessage } from "./NotificationMessage";
import { RegisterUserService } from "../../services/registration.service";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import moment from "moment";
import { initateGDPI } from "../../services/fetchLearners";
import $ from "jquery";
import { ReattemptTestService } from "../../services/reattemptTest.service";
import { updateStatus } from "../../services/preAssessment.Service";
import { OrderService } from "../../services/order.service";
import { LearnerAssessmentService } from "../../services/learnerAssessment.service";
import { reattemptSendMail } from "../../services/preAssessment.Service";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { RegionManagementService } from "../../services/regionManagement.service";
import TC from "../../assets/pdf/T-C_RCP.pdf";
import RENEWALTC from "../../assets/pdf/EUINRenewal.pdf";
import NISMInst from "../../assets/pdf/NISM_Instructions.pdf";
import ExamPdf from "../../assets/pdf/Exam_T&C.pdf";
import CAMSList from "../../assets/pdf/CAMS_POCS_list.pdf";
import NISMList from "../../assets/pdf/NISM_Online_examination-Instructions_to_Candidates.pdf";
import EuinTerms from "../../assets/pdf/EuinApplication.pdf";
import MandateTerms from "../../assets/pdf/MandateTandC.pdf";
import Select, { createFilter } from "react-select";
import ShowVerticalLinearStepper from "../statusStepper/showStepper";
import AuditTrail from "../statusStepper/auditTrail";
import DatePicker from "react-datepicker";
import AdityaBirlaSunLifeMutualFund from "../../assets/pdf/Aditya_Birla_Sun_Life_Mutual_Fund.pdf";
import AxisMutualFund from "../../assets/pdf/Axis_Mutual_Fund.pdf";
import ICICIPrudentialMutualFund from "../../assets/pdf/ICICI_Prudential_Mutual_Fund.pdf";
import NipponIndiaMutualFund from "../../assets/pdf/Nippon_India_Mutual_Fund.pdf";
import LTMutualFund from "../../assets/pdf/L&T_Mutual_Fund.pdf";
import SBIMutualFund from "../../assets/pdf/SBI_Mutual_Fund.pdf";
import SundaramMutualFund from "../../assets/pdf/Sundaram_Mutual_Fund.pdf";
import HDFCMutualFund from "../../assets/pdf/HDFC_Mutual_Fund.pdf";
import "react-datepicker/dist/react-datepicker.css";
import { LookUpService } from "../../services/lookupService";
import Modal from "react-responsive-modal";
import { Global_var } from "../../global/global_var";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import "../../assets/css/style.css";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { FetchNotificationService } from "../../services/fetchnotification";
import { fetchProcessType } from "../../services/fetchLearners";
import axios from "axios";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 4, // optional, default to 1.
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

class LearnerDashboard extends Component {
  constructor(props) {
    super(props);

    console.log("PROPSSS_______________" + this.props.productDetailsByPartyId);
    // if (localStorage.getItem('login') !== null) {
    this.state = {
      showProductprequisite: false,
      showAllproductprerequisite: false,
      selectedprodduct: 0,
      currentProd: [],
      currentAllProd: [],
      data: this.props.productDetailsAll,
      AllProductLeavingSelf: [],
      subscribedProduct: [],
      showprogressbar: false,
      currentProdkey: "",
      learnerEuinOption: "",
      showAllprogressbar: false,
      currentAllProdkey: "",
      currentKey: "",
      isexistProgressBarOpen: false,
      isNewProgressBarOpen: false,
      sameproduct: false,
      productDetailsByPartyId: this.props.productDetailsByPartyId,
      loading: false,
      learnerProgressOverviewData: this.props.learnerProgressOverviewData,
      disabled: true,
      checkout: [],
      stateListForCo: [],
      getDateLocation: [],
      center: [],
      termsCondtions: false,
      disabledAll: false,
      StateId: "",
      centerList: [],
      checkTermsaandcondition: false,
      display: "none",
      signuptabName: "tab active",
      logintabName: "tab active",
      preRequisite: [],
      termsconditionKYD: false,
      AmcList: [],
      termsconditionemp: false,
      AMCData: [],
      loading1: "none",
      currentState: "",
      companyName: "",
      mandateName: "",
      mandateId: "",
      relatedTo: "",
      learnerID: "",
      companyId: "",
      companyNameOther: "",
      learnerPartyID: "",
      assignedUserMandateName: "",
      productNames: [],
      productThumbnailList: [],
      termsandconditionmandate: false,
      termsandconditionEuinMapping: false,
      alertNotificationEvents: JSON.parse(
        localStorage.getItem("AdminAlertNotification")
      ),
      //notificationEvents: JSON.parse(localStorage.getItem('AdminNotification')),
      popupHideShow: false,
      popupErrorHideShow: false,
      learnerSubscribedProduct: [],
      euinFlag: false,

      listOfYear1: [],
      listOfMonth: [],
      listOfDay: [],
      listOfYear2: [],
      certificateYear: [],
      arnName: "",
      arnNumber: "",
      euinName: "",
      euinNumber: "",
      id: "",
      panNumber: "",
      registeredType: "",
      registrationDate: "",
      status: "",
      rejectionReason: "",
      euinNumberGen: "",
      panNumberGen: "",
      enrollmentNo: "",

      renewalarnName: "",
      renewalarnNumber: "",
      renewaleuinName: "",
      renewaleuinNumber: "",
      renewalid: "",
      renewalpanNumber: "",
      renewalregisteredType: "",
      renewalregistrationDate: "",
      renewalstatus: "",
      renewalrejectionReason: "",
      url: "",
      session_id: "",
      kyd_data: {},
      newEmail: this.props.personnelData?.emailID,
      emailOtp: "",
      isEmailValid: false,
      submitClicked: false,
      otherCompId: "",
      applicationStatusStage: "",
      productSubscribedList: [],
      mandateComponents: [],
      //disabledAll1: false
      mandateProceedBtnDisabled: false,
      courseProductIDList:[],
      submitOtpEmailDisabled: false,
    };
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }


  
  handleOpenDialog() {
    this.setState({
      openDialog: true,
    });
  }

  handleCloseDialog() {
    this.setState({
      openDialog: false,
    });
  }
  handleOpenDialog1=()=> {
   
    // setTimeout(() => {
      this.setState({
        openDialog1: true,
      });
    // }, 2000);
  }

  handleCloseDialog1=()=> {
    this.setState({
      openDialog1: false,
    });

   
  }


  titleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  handleClick() {
    this.inputElement.click();
  }

  redirectToPaymentProducts = () => {
    debugger;
    let learnerParty = "learnerPartyID=" + localStorage.getItem("userid-Token");
    let checkoutd = "";
    console.log(this.props.personnelData);
    var personaldetails = {
      partyId: this.props.personnelData.learnerPartyID,
      mobileNo: this.props.personnelData.mobileNo,
      firstName: this.props.personnelData.firstName,
      lastName: this.props.personnelData.lastName,
      emailID: this.props.personnelData.emailID,
      entryMode: 1,
      dateOfBirth: this.props.personnelData.dob,
    };
    let data = [];
    let orderItemListArray = [];
    console.log("productdetail:::::::::::", this.state.productDetailsByPartyId);
    var productPrice1 = 0;

    if (this.state.mandateId !== 0) {
      for (let i = 0; i < this.state.mandateComponents.length; i++) {
        if (
          this.state.mandateComponents[i].productid === 300005 ||
          this.state.mandateComponents[i].productid === 203402
        ) {
          productPrice1 = this.state.mandateComponents[i].productPrice;
          break;
        }
      }
    }

    this.state.productDetailsByPartyId.map((e) => {
      if (
        (e.productEntity.productName == "NISM VA Preparatory Course" ||
          e.productEntity.productName == "NISM VA Certification- Reattempt") &&
        e.attemptNumberGrade == "Make Payment"
      ) {
        let obj = {
          productId: e.productEntity.productID,
          productName: e.productEntity.productName,
          productPrice:
            productPrice1 != 0 ? productPrice1 : e.productEntity.productPrice,
          partnerID: e.productEntity.partnerID,
        };
        data.push(obj);
        let orderItemList = {
          itemID: 1,
          productID: e.productEntity.productID,
          couponApplied: "ZERO",
          prodctPrice:
            productPrice1 != 0 ? productPrice1 : e.productEntity.productPrice,
        };
        orderItemListArray.push(orderItemList);
        checkoutd = {
          learnerPartyID: localStorage.getItem("userid-Token"),
          orderPlacedOn: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"), //'15/11/2018 15:18:33',
          totalItemPrice:
            productPrice1 != 0 ? productPrice1 : e.productEntity.productPrice,
          totalOrderPrice:
            productPrice1 != 0 ? productPrice1 : e.productEntity.productPrice,
          paymentStatus: "Initiated",
          orderItemList: orderItemListArray,
          currency: "INR", // Field added for RazorPAy
        };
      }
    });

    this.state.checkout.push({ personaldetails, data });

    //productdetails

    OrderService.RazorPayInsertOrder(
      checkoutd,
      (res) => {
        if (res.status === "success") {
          localStorage.setItem("reload", "true");
          this.props.history.push("/razorpay", {
            props: this.state.checkout,
            orderDetails: res.responseObject,
          });
        } else if (res.status === "fail") {
          if (res.reasonCode === "SM_11") {
            window.location.href = "/";
          } else {
            error(res.reasonText, errorNotification);
          }
        }
      },
      (error) => {
        console.log("Order Service ", error);
      }
    );
  };
  getMandateName=()=>{
    if(!["",null,undefined,"null","undefined"].includes(localStorage.getItem("PersonnelData"))){
    var PersonnelData = JSON.parse(localStorage.getItem("PersonnelData"));
    let dataValidate = {
      companyName: PersonnelData?.responseObject?.companyName,
      otherCompanyName: PersonnelData?.responseObject?.mandateId,
    };
    RegionManagementService.checkMandateDetails(dataValidate,otherRes => {
      if(otherRes.status === "success"){
        localStorage.setItem("learnerEuinOption1",this.state.learnerEuinOption === "NISM" ? "" : this.state.learnerEuinOption)
        localStorage.setItem("preferredMandateName",otherRes.responseObject.mandateEntity[0].mandateName)
        localStorage.setItem("mandateDetails",JSON.stringify(otherRes.responseObject.mandateEntity[0]))
        if(otherRes.responseObject.mandateEntity[0].mandateName === "NISM"){
          let data = {
            mandateId: this.state.mandateId,
            relatedTo: this.state.relatedTo,
            learnerID: PersonnelData.responseObject?.learnerID,
            learnerPartyID: PersonnelData.responseObject?.learnerPartyID,
            mandateUserId: this.state.mandateUserId,
            companyName: this.state.companyId,
            otherCompanyName: this.state.otherCompId,
            componentList: this.state.productNames,
            isTearmsAccepted: "1",
            learnerEuinOption:
              this.state.learnerEuinOption === "NISM"
                ? null
                : this.state.learnerEuinOption,
          };
          RegionManagementService.saveMandateDetails(data, (response) => {
            if (response.status === "success") {
              localStorage.setItem("UpdateUser","Yes")

              this.handleProfilePercentageUpdate()
              if(response.responseObject.isMandateSaved === "N"){
                let saveRcpLearnerAssessmentData = {
                  learnerID: PersonnelData.responseObject?.learnerID,
                  learnerPartyID: PersonnelData.responseObject?.learnerPartyID,
                  mandateName: this.state.mandateName,
                  component1: "",
                  component2: "",
                  component3: "",
                  component4: "",
                  componentList: this.state.productNames,
                };
                this.setState({ loading: true });
                RegionManagementService.saveRcpLearnerAssessment(
                  saveRcpLearnerAssessmentData,
                  (response1) => {
                    if(response1.status === "success"){
                      this.setState({ loading: false });
                      document.querySelector("#loader").style.display = "none";
                      this.userprofile();
                    }else{
                      this.setState({ loading: false });
                      document.querySelector("#loader").style.display = "none";
                      warning(response1.reasonText,warningNotification);    
                    }
                  }
                );
              }
            }else{
              document.querySelector("#loader").style.display = "none";
              warning(response.reasonText,warningNotification);
            }
          });
        }
      }else{
        document.querySelector("#loader").style.display = "none";
        warning(otherRes.reasonText,warningNotification);
      }
    })
    document.querySelector("#loader").style.display = "none";
    }
  }
  componentDidMount() {
    this.setState({ loading: true });
    setTimeout(()=>{
      document.querySelector("#loader").style.display = "block";
    },2500)
    setTimeout(()=>{
      this.getMandateName();
    },5000)
    setTimeout(()=>{
      const { timeRemaining, isNotified, timeNotiRemaining } = this.props;
        if([1,"1"].includes(isNotified)){
          let timeParts = timeNotiRemaining.split(':');
          let updatedTimeNotiRemaining = timeParts.slice(0, 2).join(':');  
          info2(<DeploymentMessage timeNotiRemaining={timeNotiRemaining} timeRemaining={timeRemaining} updatedTimeNotiRemaining={updatedTimeNotiRemaining} />, infoNotification2)
        }
    },5000)

    // localStorage.setItem(
    //   "profilecomlitionvalue",
    //   this.props.personnelData.profileCompletion !== "0"
    //     ? this.props.personnelData.profileCompletion
    //     : 37
    // );

    RegionManagementService.getUserMandateById(
      "mandateId=" + this.props.personnelData.mandateId,
      (mandateres) => {
        if (mandateres.status === "success") {
          let assignedUserMandateName = "";
          assignedUserMandateName = mandateres.responseObject.mandateName;
          this.setState({ assignedUserMandateName: assignedUserMandateName });
          localStorage.setItem(
            "assignedUserMandateName",
            assignedUserMandateName
          );
        }
      }
    );
    RegionManagementService.getMandateComponents(
      "mandateId=" + this.props.personnelData.mandateId,
      (res2) => {
        if (res2.status === "success") {
          this.setState({ mandateComponents: res2.responseListObject });
        }
      }
    );
    var data1 = localStorage.getItem("PersonnelData");

    RegisterUserService.getEuinDetails(
      "panNumber=" + this.props.personnelData.panNumber,
      (resEuin) => {
        if (resEuin.status === "success") {
          this.setState({
            euinNumberGen: resEuin.responseObject.euinNumber,
            panNumberGen: resEuin.responseObject.panNumber,
            enrollmentNo: resEuin.responseObject.enrollmentNo,
            // euinNumber: resEuin.responseObject.euinNumber,
            // panNumber: resEuin.responseObject.panNumber,
            // arnName: resEuin.responseObject.arnName,
            // arnNumber: resEuin.responseObject.arnNumber,
            // euinName: resEuin.responseObject.euinName,
            // id: resEuin.responseObject.id,
            // registeredType: resEuin.responseObject.registeredType,
            // registrationDate:resEuin.responseObject.registrationDate.split(" ")[0],
            // status: resEuin.responseObject.status,
            rejectionReason: resEuin.responseObject.remark,
          });
          localStorage.setItem("EuinNumberData", JSON.stringify(resEuin));
        }
      }
    );

    RegisterUserService.getEuinRenewalDetails(
      "panNumber=" + this.props.personnelData.panNumber,
      (resEuinRenew) => {
        if (resEuinRenew.status === "success") {
          this.setState({
            renewalrejectionReason: resEuinRenew.responseObject.remark,
            renewalarnName: resEuinRenew.responseObject.arnName,
            renewalarnNumber: resEuinRenew.responseObject.arnNumber,
            renewaleuinName: resEuinRenew.responseObject.euinName,
            renewaleuinNumber: resEuinRenew.responseObject.euinNumber,
            renewalid: resEuinRenew.responseObject.id,
            renewalpanNumber: resEuinRenew.responseObject.panNumber,
            renewalregisteredType: resEuinRenew.responseObject.registeredType,
            renewalregistrationDate: resEuinRenew.responseObject.registrationDate.split(
              " "
            )[0],
            renewalstatus: resEuinRenew.responseObject.status,
          });
        }
      }
    );

    if (
      JSON.parse(data1).responseObject.euinNumber !== "" &&
      JSON.parse(data1).responseObject.euinNumber !== null &&
      JSON.parse(data1).responseObject.euinNumber != undefined
    ) {
      if (
        JSON.parse(data1).responseObject.euinValidUpto !== "" &&
        JSON.parse(data1).responseObject.euinValidUpto !== null &&
        JSON.parse(data1).responseObject.euinValidUpto != undefined
      ) {
        var today = new Date();
        var euinDt = JSON.parse(data1).responseObject.euinValidUpto.split(" ");
        let euinDate = euinDt[0].split("/");
        let euinDate2 = euinDate[2] + "-" + euinDate[1] + "-" + euinDate[0];

        let mydate = new Date(euinDate2);

        var diff = mydate.getTime() - today.getTime();

        var daydiff = diff / (1000 * 60 * 60 * 24);

        if (daydiff > 365) {
          // this.setState({ euinFlag: true  });
          this.setState({ euinFlag: false });
        } else {
          this.setState({ euinFlag: false });
        }
      }
    }

    RegionManagementService.getUserDetails(
      "pan=" +
        this.props.personnelData.panNumber +
        "&mobileNo=" +
        this.props.personnelData.mobileNo +
        "&emailId=" +
        this.props.personnelData.emailID,
      (res) => {
        this.setState({ loading: true });

        //debugger;
        if (res.reasonText !== "No record found") {
          let mandateUserId = "";
          let mandateId = "";

          for (let i = 0; i < res.responseListObject.length; i++) {
            mandateUserId = res.responseListObject[i].mandateUserId;
            mandateId = res.responseListObject[i].mandateId;
          }
          this.setState({ mandateUserId: mandateUserId });
          this.setState({ mandateId: mandateId });
          setTimeout(() => {
            RegionManagementService.learnerMandateDetails(
              "learnerID=" +
                this.props.personnelData.learnerID +
                "&learnerPartyID=" +
                this.props.personnelData.learnerPartyID +
                "&mandateUserId=" +
                "" +
                "&learnerMandateId=",
              (resM) => {
                //  debugger
                if (resM.status === "success") {
                  this.setState({ loading: false });
                  window.$("#MandateModal").modal("hide");
                  localStorage.setItem("MandateModalPopup", "hide");
                } else {
                  if (
                    this.props.personnelData.isMandateUser === 1 ||
                    this.props.personnelData.isMandateUser === "1"
                  ) {
                    RegionManagementService.getLearnerCompany(
                      "learnerID=" + this.props.personnelData.learnerID,
                      (lernerCompany) => {
                        if (lernerCompany.status === "success") {
                          this.setState({
                            companyId: lernerCompany.responseObject.companyId,
                          });
                          this.setState({
                            companyNameOther:
                              lernerCompany.responseObject.companyName,
                          });
                          this.onChangeCompanyParameter(
                            lernerCompany.responseObject.companyId,
                            lernerCompany.responseObject.companyName
                          );
                          this.setState({
                            otherCompId: lernerCompany.responseObject.id,
                          });
                          //  localStorage.setItem("MandateModalPopup", "show");
                          setTimeout(() => {
                            this.setState({ loading: false });
                            var PersonnelData = JSON.parse(localStorage.getItem("PersonnelData"));
                            let dataValidate = {
                              companyName: PersonnelData.responseObject?.companyName,
                              otherCompanyName: PersonnelData.responseObject?.mandateId,
                            };
                            RegionManagementService.checkMandateDetails(dataValidate,otherRes => {
                              if(otherRes.responseObject.mandateEntity[0].mandateName !== "NISM"){
                              localStorage.setItem("MandateModalPopup", "show");
                            window
                              .$("#MandateModal")
                              .modal(
                                { backdrop: "static", keyboard: false },
                                "show"
                              );
                            }else{
                              localStorage.setItem("MandateModalPopup", "hide");
                              window.$("#MandateModal").modal("hide");
                            }});
                            // window
                            //   .$("#MandateModal")
                            //   .modal(
                            //     { backdrop: "static", keyboard: false },
                            //     "show"
                            //   );
                    
                   
                            
                        //  }
                          }, 1000);
                        
                        }
                      }
                    );
                  } else {
                    localStorage.setItem("MandateModalPopup", "hide");
                    this.setState({ loading: false });
                    window.$("#MandateModal").modal("hide");
                  }
                }
              }
            );
          }, 1000);
        }
        RegionManagementService.learnerMandateDetails(
          "learnerID=" +
            this.props.personnelData.learnerID +
            "&learnerPartyID=" +
            this.props.personnelData.learnerPartyID +
            "&mandateUserId=" +
            "&learnerMandateId=",
          (resM) => {
            //debugger
            this.setState({ loading: false });
            let mandateId = "";
            let mandateUserId = "";
            if (resM.status === "success") {
              for (let i = 0; i < resM.responseListObject.length; i++) {
                mandateUserId = resM.responseListObject[i].mandateUserId;
                mandateId = resM.responseListObject[i].mandateId;
              }
              this.setState({ mandateId: mandateId });
              this.setState({ mandateUserId: mandateUserId });
              if (
                mandateId !== "" ||
                mandateId !== null ||
                mandateId !== undefined
              ) {
                RegionManagementService.getUserMandateById(
                  "mandateId=" + mandateId,
                  (mandateres) => {
                    if (mandateres.status === "success") {
                      let assignedUserMandateName = "";
                      assignedUserMandateName =
                        mandateres.responseObject.mandateName;
                      this.setState({
                        assignedUserMandateName: assignedUserMandateName,
                      });
                      RegionManagementService.getLearnerSubscribedProduct(
                        "learnerPartyId=" +
                          this.props.personnelData.learnerPartyID,
                        (prodResponse) => {
                          if (prodResponse.responseListObject !== []) {
                            let learnerSubscribedProduct = [];
                            learnerSubscribedProduct =
                              prodResponse.responseListObject;
                            let productDetailsByPartyId = [];
                            productDetailsByPartyId = this.props.productDetailsByPartyId
                            .concat(
                              learnerSubscribedProduct.filter(product => !this.props.productDetailsByPartyId.some(productDetail => productDetail.productEntity.productID === product.productEntity.productID))
                              );

                            this.setState({
                              productDetailsByPartyId: productDetailsByPartyId,
                            });
                            // if (this.state.euinFlag && this.state.assignedUserMandateName !== "Training and Certificate") {
                            if (
                              this.state.euinFlag &&
                              this.state.assignedUserMandateName !== "NISM"
                            ) {
                              let euinData = {
                                assessmentID: 236383,
                                buID: 2,
                                learnerPartyID: 236337,
                                productEntity: {
                                  productID: 10000,
                                  //"productName": "EUIN is still active",
                                  productName: "EUIN",
                                  parentProductID: null,
                                  partnerID: 1,
                                  isParentProduct: null,
                                  buid: 2,
                                  productDescription:
                                    "Your EUIN is still active and not eligible for renewal. You can subscribe to other courses in the What’s next section.",
                                  productType: 10502,
                                  productCode: "NISMVA",
                                  productPrice: 2000,
                                  isActive: 1,
                                  createdBy: 101,
                                  createdDate: "27/08/2021 11:48:28",
                                  lastModifiedBy: 101,
                                  lastModifiedDate: "27/08/2021 11:48:28",
                                  productLink: null,
                                  productAnchor: null,
                                  productSubtitle: null,
                                  productLanguage: "1",
                                  providerProductID: null,
                                  productVideourl: null,
                                  productMinPassingScore: null,
                                  isDisplayinCourseCatalog: 1,
                                  totalScore: null,
                                  productThumbnailDocId: null,
                                  productVidoeDocId: null,
                                  termsAndCondition: "Test",
                                  displaySequence: 1,
                                  productReattemptPrice: 0,
                                  productThumbnail: null,
                                  hasModules: 0,
                                },
                                paymentID: "0",
                                attemptNumber: 1,
                                attemptNumberGrade: "EUIN Number Generated",
                                isActive: 1,
                                createdDate: "06/10/2021 04:30:26",
                                lastModifiedDate: "06/10/2021 04:55:46",
                                isScoreUpdated: 0,
                              };

                              productDetailsByPartyId.push(euinData);
                              this.setState({
                                productDetailsByPartyId: productDetailsByPartyId,
                              });

                              console.log(
                                "productDetailsByPartyId ::::::",
                                this.state.productDetailsByPartyId
                              );
                            }
                          }
                        }
                      );
                    }
                  }
                );
              } else {
                RegionManagementService.getLearnerSubscribedProduct(
                  "learnerPartyId=" + this.props.personnelData.learnerPartyID,
                  (prodResponse) => {
                    if (prodResponse.responseListObject !== []) {
                      let learnerSubscribedProduct = [];
                      learnerSubscribedProduct =
                        prodResponse.responseListObject;
                      let productDetailsByPartyId = [];
                      productDetailsByPartyId = this.props.productDetailsByPartyId.concat(
                        learnerSubscribedProduct
                      );

                      this.setState({
                        productDetailsByPartyId: productDetailsByPartyId,
                      });
                    }
                  }
                );
              }
            }
          }
        );

        RegionManagementService.getLearnerSubscribedProduct(
          "learnerPartyId=" + this.props.personnelData.learnerPartyID,
          (prodResponse) => {
            if (prodResponse.responseListObject !== []) {
              let learnerSubscribedProduct = [];
              learnerSubscribedProduct = prodResponse.responseListObject;
              let productDetailsByPartyId = [];
              productDetailsByPartyId = this.props.productDetailsByPartyId.concat(
                learnerSubscribedProduct
              );

              this.setState({
                productDetailsByPartyId: productDetailsByPartyId,
              });
            }
          }
        );

        //  if(this.state.mandateId !== "" || this.state.mandateId !== null || this.state.mandateId !== undefined){
        //     RegionManagementService.getUserMandateById("mandateId=" + this.state.mandateId, (mandateres) => {
        //         if (mandateres.status === "success") {
        //             let assignedUserMandateName = ""
        //             assignedUserMandateName = mandateres.responseObject.mandateName;
        //             this.setState({ assignedUserMandateName: assignedUserMandateName });
        //             RegionManagementService.getLearnerSubscribedProduct("learnerPartyId=" + this.props.personnelData.learnerPartyID, (prodResponse) => {

        //                 if (prodResponse.responseListObject !== []) {
        //                     let learnerSubscribedProduct = []
        //                     learnerSubscribedProduct = prodResponse.responseListObject
        //                     let productDetailsByPartyId = []
        //                     productDetailsByPartyId = this.props.productDetailsByPartyId.concat(learnerSubscribedProduct);

        //                     this.setState({ productDetailsByPartyId: productDetailsByPartyId });
        //                     if (this.state.euinFlag && this.state.assignedUserMandateName !== "Training and Certificate") {
        //                         let euinData = {
        //                             "assessmentID": 236383,
        //                             "buID": 2,
        //                             "learnerPartyID": 236337,
        //                             "productEntity": {
        //                                 "productID": 10000,
        //                                 "productName": "EUIN is still active",
        //                                 "parentProductID": null,
        //                                 "partnerID": 1,
        //                                 "isParentProduct": null,
        //                                 "buid": 2,
        //                                 "productDescription": "Your EUIN is still active and not eligible for renewal. You can subscribe to other courses in the What’s next section.",
        //                                 "productType": 10502,
        //                                 "productCode": "NISMVA",
        //                                 "productPrice": 2000,
        //                                 "isActive": 1,
        //                                 "createdBy": 101,
        //                                 "createdDate": "27/08/2021 11:48:28",
        //                                 "lastModifiedBy": 101,
        //                                 "lastModifiedDate": "27/08/2021 11:48:28",
        //                                 "productLink": null,
        //                                 "productAnchor": null,
        //                                 "productSubtitle": null,
        //                                 "productLanguage": "1",
        //                                 "providerProductID": null,
        //                                 "productVideourl": null,
        //                                 "productMinPassingScore": null,
        //                                 "isDisplayinCourseCatalog": 1,
        //                                 "totalScore": null,
        //                                 "productThumbnailDocId": null,
        //                                 "productVidoeDocId": null,
        //                                 "termsAndCondition": "Test",
        //                                 "displaySequence": 1,
        //                                 "productReattemptPrice": 0,
        //                                 "productThumbnail": null,
        //                                 "hasModules": 0
        //                             },
        //                             "paymentID": "0",
        //                             "attemptNumber": 1,
        //                             "attemptNumberGrade": "Test",
        //                             "isActive": 1,
        //                             "createdDate": "06/10/2021 04:30:26",
        //                             "lastModifiedDate": "06/10/2021 04:55:46",
        //                             "isScoreUpdated": 0
        //                         }

        //                         productDetailsByPartyId.push(euinData)
        //                         this.setState({ productDetailsByPartyId: productDetailsByPartyId });

        //                         console.log("productDetailsByPartyId ::::::", this.state.productDetailsByPartyId)
        //                     }
        //                 }
        //             })

        //         }
        //     })

        // } else {
        //     RegionManagementService.getLearnerSubscribedProduct("learnerPartyId=" + this.props.personnelData.learnerPartyID, (prodResponse) => {

        //         if (prodResponse.responseListObject !== []) {
        //             let learnerSubscribedProduct = []
        //             learnerSubscribedProduct = prodResponse.responseListObject
        //             let productDetailsByPartyId = []
        //             productDetailsByPartyId = this.props.productDetailsByPartyId.concat(learnerSubscribedProduct);

        //             this.setState({ productDetailsByPartyId: productDetailsByPartyId });

        //         }
        //     })
        // }
      }
    );

    RegionManagementService.getCompanyList((res) => {
      let company = [];
      console.log("getCompanyList::", res.responseListObject);
      for (let i = 0; i < res.responseListObject.length; i++) {
        let obj = {};
        obj["value"] = res.responseListObject[i].companyId;
        obj["label"] = res.responseListObject[i].companyName;
        company.push(obj);
      }
      this.setState({
        companyList: company,
      });
      // this.setState({
      //   companyList:res.responseListObject,
      // });
    });

    //Reattempt flow commented
    RegionManagementService.getLearnerSubscribedProduct(
      "learnerPartyId=" + this.props.personnelData.learnerPartyID,
      (prodResponse) => {
        if (prodResponse.status === "success") {
          //debugger
          if (prodResponse.responseListObject !== []) {
            let learnerSubscribedProduct = [];
            learnerSubscribedProduct = prodResponse.responseListObject;
            let productDetailsByPartyId = [];
            productDetailsByPartyId = this.props.productDetailsByPartyId.concat(
              learnerSubscribedProduct
            );
            this.setState({
              productSubscribedList: productDetailsByPartyId,
            });
          }
          setTimeout(() => {
            var nismdata = JSON.parse(localStorage.getItem("PersonnelData"));
            let tempdata = {
              workflowId: nismdata.responseObject.learnerID,
              history: true,
            };
            ReattemptTestService.getStatuscompleted(tempdata, (res) => {
              let profilePercentage =
                localStorage.getItem("profilecomlitionvalue") === undefined
                  ? parseInt(0)
                  : parseInt(localStorage.getItem("profilecomlitionvalue"));

              this.setState({
                applicationStatusStage: res.data.responseListObject[0].action,
              });
              let data = res.data.responseListObject.reverse();
              console.log("res.data.responseListObject[0]", data);

              if (this.state.productSubscribedList !== null) {
                for (
                  let i = 0;
                  i < this.state.productSubscribedList.length;
                  i++
                ) {
                  if (
                    this.state.productSubscribedList[i].productEntity
                      .productName == "NISM VA Certification- Reattempt"
                  ) {
                    //debugger

                    // alert("res.data.responseListObject[0].state.applicationStatus:: "+ res.data.responseListObject[0].state.applicationStatus)
                    // alert("Application Status:: "+ res.data.responseListObject[0].state.applicationStatus)
                    // alert("res.data.responseListObject[0].hasOwnProperty('additionalDetails'):: "+ data[0].hasOwnProperty("additionalDetails"))
                    // alert("res.data.responseListObject[1].hasOwnProperty('additionalDetails'):: "+ res.data.responseListObject.reverse()[1].hasOwnProperty('additionalDetails'))
                    // alert("res.data.responseListObject[2].hasOwnProperty('additionalDetails'):: "+ res.data.responseListObject.reverse()[2].hasOwnProperty('additionalDetails'))
                    if (
                      this.props.personnelData.isMandateUser === 0 ||
                      this.props.personnelData.isMandateUser === "0"
                    ) {
                      //alert("b2c"+ this.props.personnelData.nismRegistrationNo)
                      //debugger
                      //b2c user
                      if (
                        (data[1].state.applicationStatus ==
                          "PROFILE COMPLETED" ||
                          data[2].state.applicationStatus ==
                            "NISM VA SUBSCRIBED") &&
                        profilePercentage >= 60 &&
                        this.props.personnelData.nismRegistrationNo === "" &&
                        this.props.personnelData.applicationStatus ===
                          "SUBMITTED"
                      ) {
                        this.state.productDetailsByPartyId && this.state.productDetailsByPartyId.map((e)=>{
                        if (
                          !data[1].hasOwnProperty("additionalDetails") &&
                          !data[2].hasOwnProperty("additionalDetails") &&
                          e.attemptNumberGrade !== "Make Payment"
                        ) {
                          //alert("s1")
                          window
                            .$("#showStatusPopup")
                            .modal(
                              { backdrop: "static", keyboard: false },
                              "show"
                            );
                        } else {
                          // alert("h1")
                          window.$("#showStatusPopup").modal("hide");
                          //alert("1");
                          debugger;
                          this.state.productDetailsByPartyId.map((e) => {
                            if (
                              (e.productEntity.productName ==
                                "NISM VA Preparatory Course" ||
                                e.productEntity.productName ===
                                  "NISM VA Certification- Reattempt") &&
                              this.props.personnelData.applicationStatus ===
                                "SUBMITTED"
                            ) {
                              if (e.attemptNumberGrade == "Make Payment") {
                                window.$("#makePaymentModal").modal("show");
                              }
                              if (e.attemptNumberGrade === "Reattempt") {
                                window.$("#termsandcondition").modal("show");
                              }
                            }
                          });
                        }
                      })
                      }
                    } else {
                      //alert("b2b"+ this.state.assignedUserMandateName)
                      //debugger
                      // b2b user
                      if (
                        this.state.assignedUserMandateName === "NISM" ||
                        this.state.assignedUserMandateName === "NISM and EUIN"
                      ) {
                        if (
                          data[1].state.applicationStatus ===
                            "NISM VA SUBSCRIBED" &&
                          (data[2].state.applicationStatus ===
                            "PROFILE COMPLETED" ||
                            data[2].state.applicationStatus ===
                              "NISM VA PAYMENT COMPLETED") &&
                          profilePercentage >= 60 &&
                          this.props.personnelData.applicationStatus ===
                            "SUBMITTED" &&
                          this.props.personnelData.nismRegistrationNo === ""
                        ) {
                          this.state.productDetailsByPartyId && this.state.productDetailsByPartyId.map((e)=>{
                          if (
                            !data[1].hasOwnProperty("additionalDetails") &&
                            !data[2].hasOwnProperty("additionalDetails") &&
                            !data[3].hasOwnProperty("additionalDetails") &&
                            e.attemptNumberGrade !== "Make Payment" 
                          ) {
                            // alert("s2")
                            window
                              .$("#showStatusPopup")
                              .modal(
                                { backdrop: "static", keyboard: false },
                                "show"
                              );
                          } else {
                            //alert("h2")
                            window.$("#showStatusPopup").modal("hide");
                            //alert("2");
                            debugger;
                            this.state.productDetailsByPartyId.map((e) => {
                              if (
                                (e.productEntity.productName ==
                                  "NISM VA Preparatory Course" ||
                                  e.productEntity.productName ===
                                    "NISM VA Certification- Reattempt") &&
                                this.props.personnelData.applicationStatus ===
                                  "SUBMITTED"
                              ) {
                                if (e.attemptNumberGrade == "Make Payment") {
                                  window.$("#makePaymentModal").modal("show");
                                }
                                if (e.attemptNumberGrade === "Reattempt") {
                                  window.$("#termsandcondition").modal("show");
                                }
                              }
                            });
                          }
                        })
                        }
                      } else {
                        // alert("h3")
                        window.$("#showStatusPopup").modal("hide");
                        // alert("3");
                        debugger;
                        this.state.productDetailsByPartyId.map((e) => {
                          if (
                            (e.productEntity.productName ==
                              "NISM VA Preparatory Course" ||
                              e.productEntity.productName ===
                                "NISM VA Certification- Reattempt") &&
                            this.props.personnelData.applicationStatus ===
                              "SUBMITTED"
                          ) {
                            if (e.attemptNumberGrade == "Make Payment") {
                              window.$("#makePaymentModal").modal("show");
                            }
                            if (e.attemptNumberGrade === "Reattempt") {
                              window.$("#termsandcondition").modal("show");
                            }
                          }
                        });
                      }
                    }
                    break;
                  }
                }
              }
            });
          }, 1000);
        }
      }
    );

    let partyID = localStorage.getItem("ciel-userid-Token");
    FetchNotificationService.fetchNotification("partyId=" + partyID, (res1) => {
      this.setState({
        notificationEvents: res1.responseListObject,
        loading1: "none",
      });
    });

    var year = new Date().getFullYear();
    year = year;
    var listOfYear = [];
    for (var i = 0; i < 100; i++) {
      var current = year - i;
      listOfYear.push(current);
    }

    //alert(year)
    var listOfYear1 = [];
    for (var i1 = 0; i1 < 2; i1++) {
      var current1 = year + i1;
      console.log(current1);
      listOfYear1.push(current1);
    }
    var listOfMonth = [];
    // for (var i = 1; i <= 12; i++) {
    //   // var current = year - i;
    //   listOfMonth.push(i);

    // }

    var listOfMonth = [
      { key: 1, value: "Jan" },
      { key: 2, value: "Feb" },
      { key: 3, value: "Mar" },
      { key: 4, value: "Apr" },
      { key: 5, value: "May" },
      { key: 6, value: "Jun" },
      { key: 7, value: "Jul" },
      { key: 8, value: "Aug" },
      { key: 9, value: "Sep" },
      { key: 10, value: "Oct" },
      { key: 11, value: "Nov" },
      { key: 12, value: "Dec" },
    ];

    var listOfDay = [];
    for (var i = 1; i <= 31; i++) {
      //    var current = year - i;
      listOfDay.push(i);
    }

    var yearcerti = 2010;
    var listOfCertificateYear = [];
    for (var i = 0; i < 100; i++) {
      var current = yearcerti + i;
      listOfCertificateYear.push(current);
    }
    this.setState({ listOfMonth: listOfMonth });
    this.setState({ listOfYear1: listOfYear });
    this.setState({ listOfYear2: listOfYear1 });
    this.setState({ certificateYear: listOfCertificateYear });

    this.setState({ listOfDay: listOfDay });

    ReattemptTestService.getAMCcount(
      "learnerId=" + this.props.personnelData.learnerID,
      (res) => {
        let title = "";
        res.responseListObject.map((e, i) => {
          let index = i + 1;
          title = title + index + ". " + e.amcName;
        });
        this.setState({
          AMCData: res.responseListObject,
          //  AMCNames: title
        });

        console.log(res.responseListObject);
      }
    );

    LookUpService.getAmc((res) => {
      console.log("amcList");
      console.log(res.responseListObject);
      this.setState({
        AmcList: res.responseListObject,
      });
    });

    if (localStorage.getItem("reload") === "true") {
      localStorage.setItem("reload", "false");
      window.location.reload();
    }

    setTimeout(() => {
      this.GenerateMyProductDetails();
    }, 3000);

    RegionManagementService.getState(
      "countryId=1",
      //+ this.state.corcountryId,
      (res) => {
        console.log("resaaaaaa");
        let city = [];
        for (let i = 0; i < res.responseListObject.length; i++) {
          let obj = {};
          obj["value"] = res.responseListObject[i].stateId;
          obj["label"] = this.titleCase(res.responseListObject[i].stateName);
          city.push(obj);
        }
        this.setState({
          stateListForCo: res.responseListObject,
          cityList: city,
        });
        var data2 = localStorage.getItem("PersonnelData");
      }
    );

    //Accordian
    $(".accordion__button").on("click", function () {
      $(".accordion__button").removeClass("active");
      $(this).addClass("active");
    });

    // ! getUserEmailFlag starts //
    RegionManagementService.getUserEmailFlag(
      "partyId=" + this.props.personnelData.learnerPartyID,
      (emailResponse) => {
        if (emailResponse.status === "success") {
          let profilePercentage = parseInt(
            emailResponse.responseObject.profilePercentage
          );
          let emailVerificationFlag = parseInt(
            emailResponse.responseObject.emailVerificationFlag
          );
          this.setState({emailVerificationFlag:emailVerificationFlag})
          // alert("profilePercentage:: "+ profilePercentage )
          //alert("emailVerificationFlag:: "+ emailVerificationFlag)
        //   if (
        //     profilePercentage >= 60 &&
        //     emailVerificationFlag === 0 &&
        //     this.props.personnelData.applicationStatus === "SUBMITTED"
        //   ) {
            // window
            //   .$("#emailValidateModal")
            //   .modal({ backdrop: "static", keyboard: false }, "show");
         //     localStorage.setItem("emailValidateModal", "show");
        //     localStorage.setItem("MandateModalPopup", "hide");
        
        //   }
          // else {
            // window.$("#emailValidateModal").modal("hide");
       

          //  if mandate modal is not submitted
          //  if(["",null,undefined].includes(this.props.personnelData.learnerEuinOption)){
         
          //   localStorage.setItem("MandateModalPopup", "show");
          //  }


            var nismdata = JSON.parse(localStorage.getItem("PersonnelData"));
            let tempdata = {
              workflowId: nismdata.responseObject.learnerID,
              history: true,
            };
            ReattemptTestService.getStatuscompleted(tempdata, (res) => {
              this.setState({
                applicationStatusStage: res.data.responseListObject[0].action,
              });
              //debugger
              // console.log("res.data.responseListObject[0]", res.data.responseListObject)
              //  alert("res.data.responseListObject[0].state.applicationStatus:: "+ res.data.responseListObject[0].state.applicationStatus)
              // alert("Application Status:: "+ res.data.responseListObject[0].state.applicationStatus)
              // alert("res.data.responseListObject[0].hasOwnProperty('additionalDetails'):: "+ res.data.responseListObject[0].hasOwnProperty('additionalDetails'))
              // alert("res.data.responseListObject[1].hasOwnProperty('additionalDetails'):: "+ res.data.responseListObject[1].hasOwnProperty('additionalDetails'))
              if (
                this.props.personnelData.isMandateUser === 0 ||
                this.props.personnelData.isMandateUser === "0"
              ) {
                // alert("b2c"+ this.props.personnelData.nismRegistrationNo)
                //debugger
                //b2c user
                if (
                  (res.data.responseListObject[0].state.applicationStatus ==
                    "NISM VA SUBSCRIBED" ||
                    res.data.responseListObject[0].state.applicationStatus ==
                      "PROFILE COMPLETED") &&
                  profilePercentage >= 60 &&
                  this.props.personnelData.nismRegistrationNo === "" &&
                  this.props.personnelData.applicationStatus === "SUBMITTED"
                ) {
                  this.state.productDetailsByPartyId && this.state.productDetailsByPartyId.map((e)=>{
                  if (
                    !res.data.responseListObject[0].hasOwnProperty(
                      "additionalDetails"
                    ) &&
                    !res.data.responseListObject[1].hasOwnProperty(
                      "additionalDetails"
                    ) && e.attemptNumberGrade !== "Make Payment"
                  ) {
                    //window.$('#showStatusPopup').modal('show')
                    // alert("s3")
                    window
                      .$("#showStatusPopup")
                      .modal({ backdrop: "static", keyboard: false }, "show");
                  } else {
                    // alert("h4")
                    window.$("#showStatusPopup").modal("hide");
                    debugger;
                    this.state.productDetailsByPartyId.map((e) => {
                      if (
                        e.productEntity.productName ==
                          "NISM VA Preparatory Course" &&
                        this.props.personnelData.applicationStatus ===
                          "SUBMITTED"
                      ) {
                        if (e.attemptNumberGrade == "Make Payment") {
                          window.$("#makePaymentModal").modal("show");
                        }
                        if (e.attemptNumberGrade === "Reattempt") {
                          window.$("#termsandcondition").modal("show");
                        }
                      }
                    });
                    let profilePercent1 =
                      localStorage.getItem("profilecomlitionvalue") ===
                      undefined
                        ? parseInt(0)
                        : parseInt(
                            localStorage.getItem("profilecomlitionvalue")
                          );
                    if (
                      localStorage.getItem("MandateModalPopup") === "hide" &&
                      profilePercent1 >= 60 &&
                      this.props.personnelData.applicationStatus === "SUBMITTED"
                    ) {
                      RegionManagementService.checkNismValidity(
                        "learnerID=" + this.props.personnelData.learnerID,
                        (res) => {
                          if (
                            res.status === "success" ||
                            res.status === "success"
                          ) {
                            // debugger;
                            if (
                              res.responseListObject[0].isNismDateValid ===
                                true ||
                              res.reasonText === "NISM DATE IS NOT PRESENT"
                            ) {
                              window.$("#nismValidityCheckPopup").modal("hide");
                              // alert("4");
                            } else if (
                              res.responseListObject[0].isNismDateValid ===
                              false
                            ) {
                              window.$("#nismValidityCheckPopup").modal("show");
                            }
                          }
                        }
                      );
                    }
                  }
                })
                } else {
                  //  debugger
                  let profilePercent1 =
                    localStorage.getItem("profilecomlitionvalue") === undefined
                      ? parseInt(0)
                      : parseInt(localStorage.getItem("profilecomlitionvalue"));
                  if (
                    localStorage.getItem("MandateModalPopup") === "hide" &&
                    profilePercent1 >= 60 &&
                    this.props.personnelData.applicationStatus === "SUBMITTED"
                  ) {
                    RegionManagementService.checkNismValidity(
                      "learnerID=" + this.props.personnelData.learnerID,
                      (res) => {
                        if (res.status === "success") {
                          if (
                            res.responseListObject[0].isNismDateValid ===
                              true ||
                            res.reasonText === "NISM DATE IS NOT PRESENT"
                          ) {
                            window.$("#nismValidityCheckPopup").modal("hide");
                          } else if (
                            res.responseListObject[0].isNismDateValid === false
                          ) {
                            window.$("#nismValidityCheckPopup").modal("show");
                          }
                        }
                      }
                    );
                  } else {
                    RegionManagementService.checkNismValidity(
                      "learnerID=" + this.props.personnelData.learnerID,
                      (res) => {
                        if (
                          res.status === "success" ||
                          res.status === "success"
                        ) {
                          // debugger;
                          if (
                            res.responseListObject[0].isNismDateValid ===
                              true ||
                            res.reasonText === "NISM DATE IS NOT PRESENT"
                          ) {
                            window.$("#nismValidityCheckPopup").modal("hide");
                          } else if (
                            res.responseListObject[0].isNismDateValid === false
                          ) {
                            window.$("#nismValidityCheckPopup").modal("show");
                          }
                        }
                      }
                    );
                  }
                }
              } else {
                //alert("b2b"+ this.state.assignedUserMandateName)
                // debugger
                // b2b user
                console.log(
                  "res.data.responseListObject   ",
                  res.data.responseListObject
                );
                // console.log("res.data.responseListObject[0]   ", res.data.responseListObject[0])
                // console.log("res.data.responseListObject[1]   ", res.data.responseListObject[1])
                // console.log("res.data.responseListObject[2]   ", res.data.responseListObject[2])
                if (
                  this.state.assignedUserMandateName === "NISM" ||
                  this.state.assignedUserMandateName === "NISM and EUIN"
                ) {
                  if (
                    (res.data.responseListObject[0].state.applicationStatus ===
                      "NISM VA SUBSCRIBED" ||
                      res.data.responseListObject[0].state.applicationStatus ===
                        "PROFILE COMPLETED" ||
                      res.data.responseListObject[0].state.applicationStatus ===
                        "NISM VA PAYMENT COMPLETED") &&
                    profilePercentage >= 60 &&
                    this.props.personnelData.applicationStatus ===
                      "SUBMITTED" &&
                    this.props.personnelData.nismRegistrationNo === ""
                  ) {
                    this.state.productDetailsByPartyId && this.state.productDetailsByPartyId.map((e)=>{
                    if (
                      !res.data.responseListObject[0].hasOwnProperty(
                        "additionalDetails"
                      ) &&
                      res.data.responseListObject[0].isActive === 1 &&
                      !res.data.responseListObject[1].hasOwnProperty(
                        "additionalDetails"
                      ) &&
                      res.data.responseListObject[1].isActive === 1 &&
                      !res.data.responseListObject[2].hasOwnProperty(
                        "additionalDetails"
                      ) &&
                      res.data.responseListObject[2].isActive === 1 &&
                      !res.data.responseListObject[3].hasOwnProperty(
                        "additionalDetails"
                      ) &&
                      res.data.responseListObject[3].isActive === 1
                      && e.attemptNumberGrade !== "Make Payment"
                    ) {
                      // window.$('#showStatusPopup').modal('show')
                      // alert("s4")
                      // window
                      //   .$("#showStatusPopup")
                      //   .modal({ backdrop: "static", keyboard: false }, "show");
                      if (
                        profilePercentage >= 60 &&
                        emailVerificationFlag === 1 &&
                        this.props.personnelData.applicationStatus === "SUBMITTED"
                      ) {
                        window
                          .$("#emailValidateModal")
                          .modal({ backdrop: "static", keyboard: false }, "show");
                        localStorage.setItem("MandateModalPopup", "hide");
                    
                      }else{
                        window.$("#emailValidateModal").modal("hide");                  
                      //  if mandate modal is not submitted
                      //  if(["",null,undefined].includes(this.props.personnelData.learnerEuinOption)){
                      //   localStorage.setItem("MandateModalPopup", "show");
                      //  }
                      }
                    } else {
                      // alert("h6")
                      window.$("#showStatusPopup").modal("hide");
                      debugger;
                      this.state.productDetailsByPartyId.map((e) => {
                        if (
                          e.productEntity.productName ==
                            "NISM VA Preparatory Course" &&
                          this.props.personnelData.applicationStatus ===
                            "SUBMITTED"
                        ) {
                          if (e.attemptNumberGrade == "Make Payment") {
                            window.$("#makePaymentModal").modal("show");
                          }else{
                            if (
                              profilePercentage >= 60 &&
                              emailVerificationFlag === 1 &&
                              this.props.personnelData.applicationStatus === "SUBMITTED"
                            ) {
                              window
                                .$("#emailValidateModal")
                                .modal({ backdrop: "static", keyboard: false }, "show");
                              localStorage.setItem("MandateModalPopup", "hide");
                          
                            }else{
                              window.$("#emailValidateModal").modal("hide");                  
                            //  if mandate modal is not submitted
                            //  if(["",null,undefined].includes(this.props.personnelData.learnerEuinOption)){
                            //   localStorage.setItem("MandateModalPopup", "show");
                            //  }
                            }
                          }
                          if (e.attemptNumberGrade === "Reattempt") {
                            window.$("#termsandcondition").modal("show");
                          }
                        }
                      });
                      //  alert("5")

                      let profilePercent1 =
                        localStorage.getItem("profilecomlitionvalue") ===
                        undefined
                          ? parseInt(0)
                          : parseInt(
                              localStorage.getItem("profilecomlitionvalue")
                            );
                      if (
                        localStorage.getItem("MandateModalPopup") === "hide" &&
                        profilePercent1 >= 60 &&
                        this.props.personnelData.applicationStatus ===
                          "SUBMITTED"
                      ) {
                        RegionManagementService.checkNismValidity(
                          "learnerID=" + this.props.personnelData.learnerID,
                          (res) => {
                            if (res.status === "success") {
                              // debugger;
                              if (
                                res.responseListObject[0].isNismDateValid ===
                                  true ||
                                res.reasonText === "NISM DATE IS NOT PRESENT"
                              ) {
                                window
                                  .$("#nismValidityCheckPopup")
                                  .modal("hide");
                              } else if (
                                res.responseListObject[0].isNismDateValid ===
                                false
                              ) {
                                window
                                  .$("#nismValidityCheckPopup")
                                  .modal("show");
                              }
                            }
                          }
                        );
                      }
                    }
                  })
                  }
                } else {
                  // alert("h7")
                  window.$("#showStatusPopup").modal("hide");
                  debugger;
                  this.state.productDetailsByPartyId.map((e) => {
                    if (
                      (e.productEntity.productName ==
                        "NISM VA Preparatory Course" ||
                        e.productEntity.productName ===
                          "NISM VA Certification- Reattempt") &&
                      this.props.personnelData.applicationStatus === "SUBMITTED"
                    ) {
                      if (e.attemptNumberGrade == "Make Payment") {
                        window.$("#makePaymentModal").modal("show");
                      }
                      if (e.attemptNumberGrade === "Reattempt") {
                        window.$("#termsandcondition").modal("show");
                      }
                    }
                  });
                  let profilePercent1 =
                    localStorage.getItem("profilecomlitionvalue") === undefined
                      ? parseInt(0)
                      : parseInt(localStorage.getItem("profilecomlitionvalue"));
                  if (
                    localStorage.getItem("MandateModalPopup") === "hide" &&
                    profilePercent1 >= 60 &&
                    this.props.personnelData.applicationStatus === "SUBMITTED"
                  ) {
                    RegionManagementService.checkNismValidity(
                      "learnerID=" + this.props.personnelData.learnerID,
                      (res) => {
                        if (res.status === "success") {
                          // debugger;
                          if (
                            res.responseListObject[0].isNismDateValid ===
                              true ||
                            res.reasonText === "NISM DATE IS NOT PRESENT"
                          ) {
                            window.$("#nismValidityCheckPopup").modal("hide");
                            //alert("6")
                          } else if (
                            res.responseListObject[0].isNismDateValid === false
                          ) {
                            window.$("#nismValidityCheckPopup").modal("show");
                          }
                        }
                      }
                    );
                  }
                }
              }
            });
          }
        }
      // }
    );
    // ! getUserEmailFlag ends //
    let profilePercent1 =
      localStorage.getItem("profilecomlitionvalue") === undefined
        ? parseInt(0)
        : parseInt(localStorage.getItem("profilecomlitionvalue"));
    if (
      localStorage.getItem("MandateModalPopup") === "hide" &&
      profilePercent1 >= 60 &&
      this.props.personnelData.applicationStatus === "SUBMITTED"
    ) {
      RegionManagementService.checkNismValidity(
        "learnerID=" + this.props.personnelData.learnerID,
        (res) => {
          if (res.status === "success") {
            // debugger;
            if (
              res.responseListObject[0].isNismDateValid === true ||
              res.reasonText === "NISM DATE IS NOT PRESENT"
            ) {
              window.$("#nismValidityCheckPopup").modal("hide");
            } else if (res.responseListObject[0].isNismDateValid === false) {
              window.$("#nismValidityCheckPopup").modal("show");
            }
          }
        }
      );
    }
    //window.$("#makePaymentModal").modal("show");
    if(localStorage.getItem("UpdateUser")==="Yes"||["",undefined,null,0,"0"].includes(this.props.personnelData?.profileCompletion)){
      setTimeout(() => {
      this.handleProfilePercentageUpdate()
      console.log("vkd comp",this.props,this.props?.personnelData?.profileCompletion)
     },
     1000);
    }
    let courseProductIDList1=[];
    if(this.state.productDetailsByPartyId && this.state.productDetailsByPartyId.length >0){
      this.state.productDetailsByPartyId.map((item)=>{
        courseProductIDList1.push(item.productEntity.productID);
        return null;
      })
      this.setState({
        courseProductIDList:courseProductIDList1
      })
      console.log('courseProductIDList',courseProductIDList1);
    }  
  } // componentDidMont-end

  userprofile = () => {
   //sending the learnereuin option for conditonal rendering of userprofile page
    this.props.history.push("/userprofile", {
      alertNotificationEvents: this.state.alertNotificationEvents,
      notificationEvents: this.state.notificationEvents,
      learnerEuinOption:this.state.learnerEuinOption || localStorage.getItem("learnerEuinOption1"),
    });
    window.location.reload();
  };

  generateOTPMail = () => {
    var data1 = localStorage.getItem("PersonnelData");
    let data = {
      userName: JSON.parse(data1).responseObject.firstName,
      emailId: this.state.newEmail,
      templateId: "70050",
      partyId: JSON.parse(data1).responseObject.learnerPartyID,
      learnerId: JSON.parse(data1).responseObject.learnerID,
      mobileNumber: JSON.parse(data1).responseObject.mobileNo,
    };
    console.log("data :::", data);
    RegisterUserService.generateOTPMail(data, (otpResponse) => {
      if (otpResponse.status === "success") {
        this.setState({ submitClicked: true });
        success("Please check your email id for OTP. ", successNotification);
      }
    });
  };

  validateOTPMail = () => {
    var data1 = localStorage.getItem("PersonnelData");
    let data = {
      userName: JSON.parse(data1).responseObject.firstName,
      emailId: this.state.newEmail,
      templateId: "70050",
      partyId: JSON.parse(data1).responseObject.learnerPartyID,
      learnerId: JSON.parse(data1).responseObject.learnerID,
      mobileNumber: JSON.parse(data1).responseObject.mobileNo,
      otp: this.state.emailOtp,
    };
    console.log("data :::", data);
    this.setState({ submitOtpEmailDisabled: true });
    RegisterUserService.validateOTPMail(data, (validateOtpResponse) => {
      if (validateOtpResponse.status === "success") {

        // success("Your email id is authenticated successfully. ", successNotification)
        success(validateOtpResponse.reasonText, {
          ...successNotification,
        });

        this.setState({ newEmail: "" });
        this.setState({ emailOtp: "" });
        this.setState({ submitOtpEmailDisabled: false });
        // localStorage.setItem("emailValidateModal", "hide");
        window.$("#emailValidateModal").modal("hide");


            if (this.props.personnelData.learnerEuinOption === "Fresh EUIN"){
    setTimeout(() => {
    document.getElementById("myCheck1").click();
    }, 5000)
    }

      

      
      } else {
        this.setState({ submitOtpEmailDisabled: false });
        error(validateOtpResponse.reasonText, {
          ...errorNotification,
        });
      }
    });
  };

  // onChangeCompanyParameter (selected, setFieldValue) {
  onChangeCompanyParameter(companyId, companyName) {
    // setFieldValue('companyId', selected.value)
    // this.setState({ companyId: selected.value });
    // this.setState({ companyNameOther: selected.label });

    // setFieldValue('companyId', companyId)
    this.setState({ companyId: companyId });
    this.setState({ companyNameOther: companyName });
    let date = this.props?.personnelData?.dob ? this.props?.personnelData?.dob?.split(" ") : "";
    let splitDate = date !== "" ? date[0]?.split("/") : "";
    let dobFinal = splitDate !== "" ? splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0] : "";

    //   RegionManagementService.getUserMandate("pan=" + this.props.personnelData.panNumber + "&companyName=" + selected.label + "&mobileNo=" + this.props.personnelData.mobileNo + "&emailId=" + this.props.personnelData.emailID + "&dob=" + dobFinal,
    RegionManagementService.getUserMandate(
      "pan=" +
        this.props.personnelData.panNumber +
        "&companyName=" +
        companyName +
        "&mobileNo=" +
        this.props.personnelData.mobileNo +
        "&emailId=" +
        this.props.personnelData.emailID +
        "&dob=" +
        dobFinal,
      (res1) => {
        if (res1.status === "success") {
          this.setState({ popupHideShow: true });
          this.setState({ popupErrorHideShow: false });
          this.setState({
            //companyId: res1.responseObject.companyId,
            //companyName: selected.label,
            companyName: companyName,
            mandateName: res1.responseObject.mandateName,
            mandateId: res1.responseObject.mandateId,
            relatedTo: res1.responseObject.relatedTo,
          });

          RegionManagementService.getMandateComponents(
            "mandateId=" + this.state.mandateId,
            (res2) => {
              let productIds = [];
              for (let i = 0; i < res2.responseListObject.length; i++) {
                productIds.push(res2.responseListObject[i].productid);
              }
              RegionManagementService.productlistWithId(productIds, (res2) => {
                let productNames = [];
                let productThumbnailList = [];

                for (let i = 0; i < res2.responseListObject.length; i++) {
                  let showProduct = res2.responseListObject[i].productThumbnail;
                  if (showProduct.includes("|")) {
                    showProduct.split("|");
                    console.log("splitProd:: ", showProduct.split("|"));
                    let arr = showProduct.split("|");
                    for (let j = 0; j < arr.length; j++) {
                      productThumbnailList.push(arr[j]);
                    }
                  } else {
                    productThumbnailList.push(
                      res2.responseListObject[i].productThumbnail
                    );
                  }
                  productNames.push(res2.responseListObject[i].productName);
                }
                this.setState({
                  productNames: productNames,
                });

                this.setState({
                  productThumbnailList: productThumbnailList,
                });
                console.log(
                  "res2.responseListObject[i].productThumbnailList",
                  this.state.productThumbnailList
                );
              });
            }
          );

          //     var data2 = localStorage.getItem("PersonnelData")
          //     let data22 = [
          //                {
          //                    "userName": JSON.parse(data2).responseObject.firstName,
          //                    "emailid": JSON.parse(data2).responseObject.emailID,
          //                    "templateId": "60020",
          //                    "mobileNumber": JSON.parse(data2).responseObject.mobileNo,
          //                    "partyId": JSON.parse(data2).responseObject.learnerPartyID,
          //                    "companyName": selected.label,
          //                    "status": "completed the first step in your mandate"

          //                }
          //            ]
          //     console.log("data :::",data22)
          //     RegisterUserService.sendSuccessMandateMail(data22, res3 => {
          //   })
        } else {
          this.setState({ popupHideShow: false });
          this.setState({ popupErrorHideShow: true });
          // warning("No mandate has been assigned to you by your company. Please connect with your HR for the same. Continue to register on the platform and proceed with your choice of training journeys. For any further assistance, contact <umber> or email <xxx@ciel.co.in>. Thank you", warningNotification)

          var data1 = localStorage.getItem("PersonnelData");
          let data = [
            {
              userName: JSON.parse(data1).responseObject.firstName,
              emailId: JSON.parse(data1).responseObject.emailID,
              templateId: "60010",
              mobileNumber: JSON.parse(data1).responseObject.mobileNo,
              //"partyId": JSON.parse(data1).responseObject.learnerPartyID,
              partyId: 102,
              // "companyName": selected.label,
              companyName: companyName,
              status: "Mandate Not Found",
            },
          ];
          console.log("data :::", data);
          RegisterUserService.sendNoMandateMail(data, (res3) => {});
        }
      }
    );
  }

  downloadKYD = (props) => {
    this.setState({
      loading1: "block",
    });
    var data = JSON.parse(localStorage.getItem("PersonnelData"));
    let birthDate = data.responseObject.dob.split(" ")[0].split("/");

    let tempdata = {
      partyId: data.responseObject.learnerPartyID,
      birthDate: birthDate[2] + "-" + birthDate[1] + "-" + birthDate[0],
      //moment(data.responseObject.dob.split(" ")).format("YYYY-MM-DD")
    };
    ReattemptTestService.getKYDpdf(tempdata, (res) => {
      let DocId = res.data.responseObject.documentId;
      RegisterUserService.downloadDocumet(
        "documentId=" + DocId + "&bucketTag=" + Global_var.CONST_BUCKET_NAME,
        (res1) => {
          this.setState({
            loading1: "none",
          });
          window.location.href = res1.responseObject.documentUrl;
          var data1 = localStorage.getItem("PersonnelData");
          let mailData = [
            {
              userName: JSON.parse(data1).responseObject.firstName,
              emailId: JSON.parse(data1).responseObject.emailID,
              templateId: "1960560",
              status: "Downloaded Application",
              panNumber: JSON.parse(data1).responseObject.panNumber,
              partyId: JSON.parse(data1).responseObject.learnerPartyID,
              learnerId: JSON.parse(data1).responseObject.learnerID,
            },
          ];
          RegisterUserService.sendMailKYD(mailData, (res2) => {});
        }
      );
    });
  };

  downloadARN = (props) => {
    this.setState({
      loading1: "block",
    });
    var data = JSON.parse(localStorage.getItem("PersonnelData"));

    let birthDate = data.responseObject.dob.split(" ")[0].split("/");

    let tempdata = {
      partyId: data.responseObject.learnerPartyID,
      birthDate: birthDate[2] + "-" + birthDate[1] + "-" + birthDate[0],
      //moment(data.responseObject.dob.split(" ")).format("YYYY-MM-DD")
    };
    ReattemptTestService.getARNpdf(tempdata, (res) => {
      let DocId = res.data.responseObject.documentId;
      RegisterUserService.downloadDocumet(
        "documentId=" + DocId + "&bucketTag=" + Global_var.CONST_BUCKET_NAME,
        (res1) => {
          this.setState({
            loading1: "none",
          });
          window.location.href = res1.responseObject.documentUrl;

          var data1 = localStorage.getItem("PersonnelData");
          let mailData = [
            {
              userName: JSON.parse(data1).responseObject.firstName,
              emailId: JSON.parse(data1).responseObject.emailID,
              templateId: "1960560",
              status: "Downloaded Application",
              panNumber: JSON.parse(data1).responseObject.panNumber,
              partyId: JSON.parse(data1).responseObject.learnerPartyID,
              learnerId: JSON.parse(data1).responseObject.learnerID,
            },
          ];
          RegisterUserService.sendMailKYD(mailData, (res2) => {
            // window.location.reload()
          });
          //window.$('#downloadAdmitCard').modal('hide')
        }
      );
    });
  };

  empanelment1 = (props) => {
    LookUpService.getAmc((res) => {
      this.setState({
        AmcList: res.responseListObject,
      });
      let amcList = [];
      res.responseListObject.map((e) => {
        amcList.push(e.mst_amc_id);
      });
      console.log(amcList);
      var data1 = localStorage.getItem("PersonnelData");
      let data = {
        flag: 0,
        learnerid: JSON.parse(data1).responseObject.learnerID,
        amcIds: amcList,
        mstAmcId: localStorage.getItem("userid"),
        isTermsAccepted: 0,
        isApprovedByAamc: 0,
        partyid: JSON.parse(data1).responseObject.learnerPartyID,
      };

      // let mailData = [
      //     {
      //         "userName": JSON.parse(data1).responseObject.firstName,
      //         "emailId": JSON.parse(data1).responseObject.emailID,
      //         "templateId": "1960650",
      //         "status": "Empanelment for cielOps",
      //         "partyId": JSON.parse(data1).responseObject.learnerPartyID,
      //         "learnerId": JSON.parse(data1).responseObject.learnerID,
      //         "panNumber": JSON.parse(data1).responseObject.panNumber

      //     }
      // ]

      ReattemptTestService.acceptKyd(data, (res) => {
        // success("Successfully initiated for Empanelment. ", successNotification)
        success(
          "Your Empanelment has been initiated with all Partner AMCs. Your empanelment will capture information submitted in your KYD Form ",
          successNotification
        );

        // RegisterUserService.sendMail(mailData, res => {
        //     //   this.setState({ dataChange: [] })
        //     setTimeout(() => {
        //         window.location.reload();
        //                         }, 5000)

        // })

        window.$("#defaultkydpopup1").modal("hide");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
    });
  };
  isValidDate = (fromDate, toDate) => {
    if (fromDate !== "") {
      let fromDate1 = fromDate.split("-");
      if (fromDate1[1] == 2 && fromDate1[2] > 29) {
        warning("Please select valid DOB", warningNotification);
        return false;
      } else if (
        fromDate1[1] == 4 ||
        fromDate1[1] == 6 ||
        fromDate1[1] == 9 ||
        fromDate1[1] == 11
      ) {
        if (fromDate1[2] > 30) {
          warning("Please select valid Date", warningNotification);
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      warning("Please select valid Date", warningNotification);
    }

    if (toDate !== "") {
      let toDate1 = fromDate.split("-");
      if (toDate1[1] == 2 && toDate1[2] > 29) {
        warning("Please select valid Date", warningNotification);
        return false;
      } else if (
        toDate1[1] == 4 ||
        toDate1[1] == 6 ||
        toDate1[1] == 9 ||
        toDate1[1] == 11
      ) {
        if (toDate1[2] > 30) {
          warning("Please select valid Date", warningNotification);
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      warning("Please select valid Date", warningNotification);
    }
  };
  empanelment2 = (props) => {
    let amcList = [];
    this.state.AmcList.map((e) => {
      amcList.push(e.mst_amc_id);
    });

    var data1 = localStorage.getItem("PersonnelData");
    let data = {
      flag: 1,
      learnerid: JSON.parse(data1).responseObject.learnerID,
      amcIds: amcList,
      mstAmcId: localStorage.getItem("userid"),
      isTermsAccepted: 1,
      isApprovedByAamc: 1,
      partyid: JSON.parse(data1).responseObject.learnerPartyID,
    };

    ReattemptTestService.acceptKyd(data, (res) => {
      success(
        "Accepted Terms and Conditions for All AMCs.",
        successNotification
      );

      // let mailData1 = [
      //     {
      //         "userName": JSON.parse(data1).responseObject.firstName,
      //         "emailId": JSON.parse(data1).responseObject.emailID,
      //         "templateId": "1960630",
      //         "status": "Empanelment for Distributer",
      //         "partyId": JSON.parse(data1).responseObject.learnerPartyID,
      //         "learnerId": JSON.parse(data1).responseObject.learnerID,
      //         "panNumber": JSON.parse(data1).responseObject.panNumber

      //     },
      //     {
      //         "userName": JSON.parse(data1).responseObject.firstName,
      //         "emailId": JSON.parse(data1).responseObject.emailID,

      //         "templateId": "1960680",
      //         "status": "Empanelment Accept Request",
      //         "partyId": JSON.parse(data1).responseObject.learnerPartyID,
      //         "learnerId": JSON.parse(data1).responseObject.learnerID,
      //         "panNumber": JSON.parse(data1).responseObject.panNumber

      //     }
      // ]

      //     let sms = [
      //         {
      //         "userName": JSON.parse(data1).responseObject.firstName,
      //         "templateId": "40130",
      //         "mobileNumber": JSON.parse(data1).responseObject.mobileNo,
      //         "status": 'UNDER EMPANELMENT',
      //         "partyId": JSON.parse(data1).responseObject.learnerPartyID,
      //     }

      // ]

      // RegisterUserService.sendMail(mailData1, res => {

      // })
      // RegisterUserService.sendSms(sms, res3 => {
      //     //success("Feat completed successfully.", successNotification);
      //     // if(res3.status === "success"){
      //     //     success("UNDER EMPANELMENT/ EMPANELLED Successfully sms sent", successNotification);
      //     // } else {
      //     //     error("UNDER EMPANELMENT /EMPANELLED Successfully sms failed .", errorNotification);
      //     // }
      // })
    });
    document.getElementById("empanelment2").style.display = "none";
    //   document.getElementById('empanelment1').style.display = "none";
    document.getElementById("empanelment3").style.display = "block";
  };
  empanelment3 = (props) => {};

  KYDSubmit = (props) => {
    var data1 = localStorage.getItem("PersonnelData");
    let workflowtemp = {
      ExcecutionTracker: [
        {
          action: "KYD-ARN APPLY",
          workflowId: JSON.parse(data1).responseObject.learnerID,
          workflowName:
            JSON.parse(data1).responseObject.lookingFor === "1001"
              ? "ARN"
              : "ARN",

          comment: "",
        },
      ],
    };
    updateStatus(workflowtemp, (res1) => {
      //                                    window.location.reload()
      // success("KYD/ARN applied successfully.", successNotification)
      success("KYD / ARN Process initiated successfully.", successNotification);
    });

    let data = [
      {
        userName: JSON.parse(data1).responseObject.firstName,
        emailId: JSON.parse(data1).responseObject.emailID,
        templateId: "1960540",
        status: "Applied for ARN",
        panNumber: JSON.parse(data1).responseObject.panNumber,
        partyId: JSON.parse(data1).responseObject.learnerPartyID,
        learnerId: JSON.parse(data1).responseObject.learnerID,
      },
    ];

    RegisterUserService.sendMailKYD(data, (res2) => {
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    });
    window.$("#defaultkydpopup").modal("hide");
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  switchTab = (evt, inp) => {
    //  var i, tabcontent, tablinks;
    // tabcontent = document.getElementsByClassName("tabcontent");
    if (inp == "signup") {
      this.setState({ display: "none" });
      this.setState({ signuptabName: "tab active" });
      this.setState({ logintabName: "tab" });
      //  document.getElementById('login').style.display = "none";
      document.getElementById("signup").style.display = "none";
      evt.currentTarget.className += " active";
    } else {
      this.setState({ display: "block" });
      this.setState({ signuptabName: "tab" });
      this.setState({ logintabName: "tab active" });
      document.getElementById("signup").style.display = "none";
      document.getElementById("login").style.display = "block";
      evt.currentTarget.className += " active";
    }
  };
  downloadAdmit = (props) => {
    var data = JSON.parse(localStorage.getItem("PersonnelData"));
    let tempdata = {
      workflowId: data.responseObject.learnerID,

      history: true,
    };
    ReattemptTestService.getStatuscompleted(tempdata, (res) => {
      let admitCardDocId =
        res.data.responseListObject[0].additionalDetails.docId;
      RegisterUserService.downloadDocumet(
        "documentId=" +
          admitCardDocId +
          "&bucketTag=" +
          Global_var.CONST_BUCKET_NAME,
        (res1) => {
          window.location.href = res1.responseObject.documentUrl;
          //window.$('#downloadAdmitCard').modal('hide')
        }
      );
    });
  };
  GenerateMyProductDetails() {
    var mymappedProductId = [];
    var listData = [];
    if (
      this.state.productDetailsByPartyId != "" &&
      this.state.productDetailsByPartyId != null
    ) {
      let checkGDPICode = 0;
      let isGDPIAvailaibale = false;
      this.state.productDetailsByPartyId.map((item) => {
        if (parseInt(item.productEntity.productID) !== checkGDPICode) {
          if (!isGDPIAvailaibale) {
            isGDPIAvailaibale = false;
          }
          mymappedProductId.push(item.productEntity.productID);
        } else if (parseInt(item.productEntity.productID) === checkGDPICode) {
          isGDPIAvailaibale = true;
          mymappedProductId.push(item.productEntity.productID);
        }
      });

      if (!isGDPIAvailaibale) {
        mymappedProductId.push(checkGDPICode);
      }

      LoginService.TokenUser((res) => {
        if (res.status === "success") {
          // debugger
          ProductService.fetchProductAllExceptMappedProduct(
            mymappedProductId,
            (res1) => {
              if (
                (this.props.personnelData.isMandateUser === 1 ||
                  this.props.personnelData.isMandateUser === "1") &&
                this.state.mandateId !== null &&
                this.state.mandateId !== "" &&
                this.state.mandateId !== undefined &&
                this.state.mandateId > 0
              ) {
                let assignedUserMandateName = "";
                RegionManagementService.getUserMandateById(
                  "mandateId=" + this.state.mandateId,
                  (mandateres) => {
                    if (mandateres.status === "success") {
                      assignedUserMandateName =
                        mandateres.responseObject.mandateName;
                      this.setState({
                        assignedUserMandateName: assignedUserMandateName,
                      });

                      //alert("hii")
                      // what to learn next call commented
                      console.log(
                        "sneeeeeeeeeeeee:::",
                        res1.responseListObject
                      );
                      //debugger
                      if (res1.responseListObject.length > 0) {
                        // alert(this.state.assignedUserMandateName)
                        //  if (this.state.assignedUserMandateName === "Training and Certificate") {
                        if (this.state.assignedUserMandateName === "NISM") {
                          // alert("here")
                          // for (let i = 0; i < res1.responseListObject.length; i++) {
                          //     if (res1.responseListObject[i].productName !== "NISM VA MFD Training" &&
                          //         //res1.responseListObject[i].productName !== "NISM Certification" &&
                          //         res1.responseListObject[i].productName !== "NISM VA Preparatory Course" &&
                          //         res1.responseListObject[i].productName !== "EUIN Renewal" &&
                          //         res1.responseListObject[i].productName !== "EUIN Application" &&
                          //         res1.responseListObject[i].productName !== "EUIN Mapping"
                          //     ) {
                          //         listData.push(res1.responseListObject[i])
                          //     }

                          // }
                          // alert("asd")
                          listData = [];
                        } else if (
                          assignedUserMandateName === "EUIN" ||
                          assignedUserMandateName === "NISM and EUIN"
                        ) {
                          //alert("sada");
                          listData = [];
                        } else {
                          //alert("blank []")
                          for (
                            let i = 0;
                            i < res1.responseListObject.length;
                            i++
                          ) {
                            if (
                              res1.responseListObject[i].productName !==
                                "NISM VA MFD Training" &&
                              //res1.responseListObject[i].productName !== "NISM Certification" &&
                              //res1.responseListObject[i].productName !== "NISM VA Preparatory Course" &&
                              res1.responseListObject[i].productName !==
                                "EUIN Application" &&
                              res1.responseListObject[i].productName !==
                                "EUIN Renewal" &&
                              res1.responseListObject[i].productName !==
                                "EUIN Mapping" &&
                              res1.responseListObject[i].productName !==
                                "ARN Application"
                            ) {
                              //alert("qqq")
                              listData.push(res1.responseListObject[i]);
                            }
                          }
                        }
                      }

                      console.log("listData:::", listData);
                      this.setState({ AllProductLeavingSelf: listData });
                      //this.setState({ AllProductLeavingSelf: res1.responseListObject });
                      this.setState({ loading: false });
                    }
                  }
                );
              } else {
                // alert("saurabh"+ this.props.personnelData.nismRegistrationNo)
                // console.log("res1.responseListObject:: ", res1.responseListObject)
                for (let i = 0; i < res1.responseListObject.length; i++) {
                  // if (
                  //    res1.responseListObject.productName !== "EUIN Application" &&
                  //    res1.responseListObject.productName !== "EUIN Renewal" &&
                  //    res1.responseListObject.productName !== "EUIN Mapping"
                  // ) {
                  //     listData.push(res1.responseListObject[i])
                  // }
                  //alert("lllllllllllllll")
                  if (
                    this.props.personnelData.nismRegistrationNo !== "" &&
                    this.props.personnelData.nismRegistrationNo !== undefined &&
                    this.props.personnelData.certificateValidUpto !== "" &&
                    this.props.personnelData.certificateValidUpto !==
                      undefined &&
                    res1.responseListObject[i].productName !==
                      "EUIN Application" &&
                    res1.responseListObject[i].productName !==
                      "NISM VA Preparatory Course" &&
                    res1.responseListObject[i].productName !== "EUIN Renewal" &&
                    res1.responseListObject[i].productName !== "EUIN Mapping"
                  ) {
                    console.log("stage1");

                    listData.push(res1.responseListObject[i]);
                  } else if (
                    (this.props.personnelData.nismRegistrationNo === "" ||
                      this.props.personnelData.nismRegistrationNo ===
                        undefined) &&
                    (this.props.personnelData.certificateValidUpto === "" ||
                      this.props.personnelData.certificateValidUpto ===
                        undefined) &&
                    res1.responseListObject[i].productName !==
                      "EUIN Application" &&
                    res1.responseListObject[i].productName !== "EUIN Renewal" &&
                    res1.responseListObject[i].productName !== "EUIN Mapping"
                  ) {
                    listData.push(res1.responseListObject[i]);
                    console.log("stage2");
                  }
                }

                console.log("listData::::::::::::::::", listData);
                setTimeout(() => {
                  //localStorage.setItem("listData", listData)
                  this.setState({ AllProductLeavingSelf: listData });
                }, 4000);
              }
            }
          );
          console.log("listData:::", this.state.AllProductLeavingSelf);
        } else if (res.status === "fail") {
          this.setState({ loading: false });
          if (res.reasonCode === "SM_11") {
            window.location.href = "/";
          } else {
            error(res.reasonText, errorNotification);
          }
        }
      });
    } else {
      // debugger
      // alert("kkkk")
      // alert("hello"+this.props.personnelData.isMandateUser)
      console.log("sneeeeeeeeeeeee:::", this.props.productDetailsAll);
      if (this.props.productDetailsAll.length > 0) {
        // alert("kkkk")
        if (
          (this.props.personnelData.isMandateUser === 1 ||
            this.props.personnelData.isMandateUser === "1") &&
          this.state.mandateId != "" &&
          this.state.mandateId != null &&
          this.state.mandateId != undefined
        ) {
          // alert("nikita")
          RegionManagementService.getUserMandateById(
            "mandateId=" + this.state.mandateId,
            (mandateres) => {
              if (mandateres.status === "success") {
                //alert("wwwwww" + )
                let assignedUserMandateName = "";
                assignedUserMandateName = mandateres.responseObject.mandateName;
                // alert("assignedUserMandateName"+ assignedUserMandateName)
                // if (assignedUserMandateName === "Training and Certificate") {
                if (assignedUserMandateName === "NISM") {
                  // alert("ok")
                  // for (let i = 0; i < this.props.productDetailsAll.length; i++) {
                  //     if (this.props.productDetailsAll[i].productName !== "NISM VA MFD Training" &&
                  //        // this.props.productDetailsAll[i].productName !== "NISM Certification" &&
                  //         this.props.productDetailsAll[i].productName !== "NISM VA Preparatory Course" &&
                  //         this.props.productDetailsAll[i].productName !== "EUIN Renewal" &&
                  //         this.props.productDetailsAll[i].productName !== "EUIN Mapping"
                  //     ) {
                  //         listData.push(this.props.productDetailsAll[i])
                  //     }
                  // }
                  // alert("pi")
                  listData = [];
                } else if (
                  assignedUserMandateName === "EUIN" ||
                  assignedUserMandateName === "NISM and EUIN"
                ) {
                  // alert("pi")
                  listData = [];
                } else {
                  //alert("not ok")
                  for (
                    let i = 0;
                    i < this.props.productDetailsAll.length;
                    i++
                  ) {
                    if (
                      this.props.productDetailsAll[i].productName !==
                        "NISM VA MFD Training" &&
                      //this.props.productDetailsAll[i].productName !== "NISM Certification" &&
                      //this.props.productDetailsAll[i].productName !== "NISM VA Preparatory Course" &&
                      this.props.productDetailsAll[i].productName !==
                        "EUIN Application" &&
                      this.props.productDetailsAll[i].productName !==
                        "EUIN Renewal" &&
                      this.props.productDetailsAll[i].productName !==
                        "EUIN Mapping" &&
                      this.props.productDetailsAll[i].productName !==
                        "ARN Application"
                    ) {
                      listData.push(this.props.productDetailsAll[i]);
                      console.log("stage3");
                    }
                  }
                }

                console.log("listData::::::::::::::::", listData);
                setTimeout(() => {
                  //localStorage.setItem("listData", listData)
                  this.setState({ AllProductLeavingSelf: listData });
                }, 2000);
              }
              //this.setState({ AllProductLeavingSelf: this.props.productDetailsAll });
            }
          );
        } else {
          listData = [];
          //debugger;
          // alert("b2c " + this.props.personnelData.nismRegistrationNo +" "+this.props.personnelData.certificateValidUpto)
          for (let i = 0; i < this.props.productDetailsAll.length; i++) {
            if (
              this.props.personnelData.nismRegistrationNo !== "" &&
              this.props.personnelData.nismRegistrationNo !== undefined &&
              this.props.personnelData.certificateValidUpto !== "" &&
              this.props.personnelData.certificateValidUpto !== undefined &&
              this.props.productDetailsAll[i].productName !==
                "EUIN Application" &&
              this.props.productDetailsAll[i].productName !==
                "NISM VA Preparatory Course" &&
              this.props.productDetailsAll[i].productName !== "EUIN Renewal" &&
              this.props.productDetailsAll[i].productName !== "EUIN Mapping"
            ) {
              console.log("lllll");
              listData.push(this.props.productDetailsAll[i]);
            } else if (
              (this.props.personnelData.nismRegistrationNo === "" ||
                this.props.personnelData.nismRegistrationNo === undefined) &&
              (this.props.personnelData.certificateValidUpto === "" ||
                this.props.personnelData.certificateValidUpto === undefined) &&
              this.props.productDetailsAll[i].productName !==
                "EUIN Application" &&
              this.props.productDetailsAll[i].productName !== "EUIN Renewal" &&
              this.props.productDetailsAll[i].productName !== "EUIN Mapping"
            ) {
              console.log("qqq");
              listData.push(this.props.productDetailsAll[i]);
            }
          }

          console.log("listData::::::::::::::::", listData);
          setTimeout(() => {
            //localStorage.setItem("listData", listData)
            this.setState({ AllProductLeavingSelf: listData });
          }, 2000);
        }
      }
    }
  }

  showMyproductdetail = (product) => {
    // alert( JSON.stringify(product.productEntity.productID))
    //console.log("current product :", JSON.stringify(this.state.currentProd))

    //console.log("Current product key :" + this.state.currentProdkey + "==" + product.productEntity.productID)
    localStorage.setItem("selectedproductprogress", product);

    this.state.currentProd.pop();
    this.state.currentProd.push(product);

    //this.state.currentProd.splice(0, 0, product);

    //this.setState({showprogressbar : true});
    //window.location.reload();

    localStorage.setItem("showAllproductprerequisite", false);
    // LoginService.TokenUser(res => {
    //     localStorage.setItem("jwt-token", res.responseObject);
    // });

    let currData = product;

    if (currData) {
      let productId = currData.productEntity
        ? currData.productEntity.productID
        : currData; //this.props.currentproduct.productEntity ? this.props.currentproduct.productEntity.productID : this.props.currentproduct.productID;
      let productdetails =
        this.props.CurrentKey === "New"
          ? this.props.productDetailsAll
          : this.state.productDetailsByPartyId;
      ProductService.fetchProductPreRequisiteByParentProduct(
        "parentProductID=" + productId,
        (res) => {
          //console.log("My Progress Path landing ", res.responseListObject);

          if (res.status === "success") {
            //this.setState({ preRequisite: res.responseListObject });
            if (res.reasonText === "Record not found") {
              let pojoArray = [];
              let prod = currData.productEntity
                ? currData.productEntity
                : currData;
              productdetails.forEach((element) => {
                let prodid = element.productEntity
                  ? element.productEntity.productID
                  : element.productID;
                if (prodid === prod.productID) {
                  let pojo = {
                    productId: prod.productID,
                    productName: prod.productName,
                    productDescription: prod.productDescription,
                    productType: prod.productType,
                    productLink: prod.productLink,
                    parentProductID: prod.parentProductID,
                    sequenceCount: 0,
                    seqProductID: prod.productID,
                    scorePercent: element.isScoreUpdated >= 1 ? 100 : 0,
                  };
                  pojoArray.push(pojo);
                  this.state.preRequisite.push(pojoArray);
                }
              });
            } else {
              let pojoArray = [];
              let prodid = [];

              for (
                let index = 0;
                index < res.responseListObject.length;
                index++
              ) {
                const element = res.responseListObject[index];
                if (this.state.productDetailsByPartyId != null) {
                  this.state.productDetailsByPartyId.forEach((ele) => {
                    let ispresent = false;

                    if (element.seqProductID === ele.productEntity.productID) {
                      if (prodid.length > 0) {
                        ispresent = prodid.includes(
                          ele.productEntity.productID
                        );
                      }
                      prodid.push(ele.productEntity.productID);

                      if (!ispresent) {
                        let pojo = {
                          productId: element.seqProductID,
                          productName: ele.productEntity.productName,
                          productDescription:
                            ele.productEntity.productDescription,
                          productType: ele.productEntity.productType,
                          productLink: ele.productEntity.productLink,
                          parentProductID: element.parentProductID,
                          sequenceCount: element.sequenceCount,
                          seqProductID: element.seqProductID,
                          scorePercent: ele.isScoreUpdated >= 1 ? 100 : 0,
                        };
                        pojoArray.push(pojo);
                        return;
                      }
                    }
                  });
                } else {
                  this.props.productDetailsAll.forEach((ele) => {
                    let ispresent = false;

                    if (element.seqProductID === ele.productID) {
                      if (prodid.length > 0) {
                        ispresent = prodid.includes(ele.productID);
                      }
                      prodid.push(ele.productID);

                      if (!ispresent) {
                        let pojo = {
                          productId: element.seqProductID,
                          productName: ele.productName,
                          productDescription: ele.productDescription,
                          productType: ele.productType,
                          productLink: ele.productLink,
                          parentProductID: element.parentProductID,
                          sequenceCount: element.sequenceCount,
                          seqProductID: element.seqProductID,
                          scorePercent: ele.isScoreUpdated >= 1 ? 100 : 0,
                        };
                        pojoArray.push(pojo);
                        return;
                      }
                    }
                  });
                }
              }
              let ispresent = false;
              for (
                let index = 0;
                index < res.responseListObject.length;
                index++
              ) {
                const element = res.responseListObject[index];
                pojoArray.forEach((ele) => {
                  if (ele.productId !== element.seqProductID) {
                    if (prodid.length > 0) {
                      ispresent = prodid.includes(element.seqProductID);
                      if (!ispresent) {
                        let pojo = {
                          productId: element.seqProductID,
                          productName: element.seqProductID,
                          productDescription: "",
                          productType: "",
                          productLink: "",
                          parentProductID: element.parentProductID,
                          sequenceCount: element.sequenceCount,
                          seqProductID: element.seqProductID,
                          scorePercent: element.isScoreUpdated >= 1 ? 100 : 0,
                        };
                        prodid.push(element.seqProductID);
                        pojoArray.push(pojo);
                        return;
                      }
                    }
                  }
                });
              }
              let newpojo = [];
              for (
                let index = 0;
                index < res.responseListObject.length;
                index++
              ) {
                const element = res.responseListObject[index];
                pojoArray.forEach((ele) => {
                  if (element.seqProductID === ele.productId) {
                    let ab = {
                      productId: ele.productId,
                      productName: ele.productName,
                      productDescription: ele.productDescription,
                      productType: ele.productType,
                      productLink: ele.productLink,
                      parentProductID: ele.parentProductID,
                      sequenceCount: element.seqProductID,
                      seqProductID: ele.seqProductID,
                      scorePercent: ele.scorePercent,
                    };
                    newpojo.push(ab);
                  }
                });
              }
              let FinalReq = [];

              newpojo.forEach((element) => {
                this.props.productDetailsAll.forEach((ele) => {
                  if (element.productId === ele.productID) {
                    let ab = {
                      productId: element.productId,
                      productName: ele.productName,
                      productDescription: ele.productDescription,
                      productType: ele.productType,
                      productLink: ele.productLink,
                      parentProductID: element.parentProductID,
                      sequenceCount: element.seqProductID,
                      seqProductID: element.seqProductID,
                      scorePercent: element.scorePercent,
                    };
                    FinalReq.push(ab);
                  }
                });
              });
              //console.log("FinalReq", FinalReq);
              // this.state.preRequisite.push(FinalReq);
              this.setState({
                preRequisite: FinalReq,
                showprogressbar: true,
              });
              console.log("state FinalReq", this.state.preRequisite);
            }
          }
        }
      );
    }

    this.setState({
      showprogressbar: true,
      sameproduct: false,
      showAllproductprerequisite: false,
      currentKey: "Old",
      currentProdkey: JSON.stringify(product.productEntity.productID),
    });
  };
  showMypreRequisite = (product) => {
    document.getElementById("progress-status").style.display = "block";
    this.state.currentAllProd.pop();
    this.state.currentAllProd.push(product);
    localStorage.setItem("showAllproductprerequisite", true);

    this.setState({
      showprogressbar: false,
      //    showAllproductprerequisite: true,
      currentKey: "New",
      currentAllProdkey: JSON.stringify(product.productID),
    });
  };
  updateProgressOverview = () => {
    let partyID = localStorage.getItem("userid-Token");
    ProductService.getProgressOverview(
      "learnerPartyId=" + partyID,
      (res) => {
        if (res.status === "success") {
          this.setState({ learnerProgressOverviewData: res.responseObject });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  UpdateMyProductDetails = () => {
    let partyID = localStorage.getItem("userid-Token");
    ProductService.fetchProductDetailsByPartyId(
      "learnerPartyId=" + partyID,
      (res) => {
        if (res.status === "success") {
          this.setState({ productDetailsByPartyId: res.responseListObject });
          this.GenerateMyProductDetails();
          this.updateProgressOverview();
          // fetchProductDetailsByPartyId("learnerPartyId=" + partyID);
        }
      },
      (err) => {
        console.log(err);
      }
    );

    RegionManagementService.getLearnerSubscribedProduct(
      "learnerPartyId=" + this.props.personnelData.learnerPartyID,
      (prodResponse) => {
        if (prodResponse.responseListObject !== []) {
          let learnerSubscribedProduct = [];
          learnerSubscribedProduct = prodResponse.responseListObject;
          let productDetailsByPartyId = [];
          productDetailsByPartyId = this.props.productDetailsByPartyId.concat(
            learnerSubscribedProduct
          );

          this.setState({ productDetailsByPartyId: productDetailsByPartyId });

          // if(this.state.euinFlag && this.state.assignedUserMandateName != "Training and Certificate"){
          if (
            this.state.euinFlag &&
            this.state.assignedUserMandateName != "NISM"
          ) {
            let euinData = {
              assessmentID: 236383,
              buID: 2,
              learnerPartyID: 236337,
              productEntity: {
                productID: 10000,
                // "productName":"EUIN is still active",
                productName: "EUIN",
                parentProductID: null,
                partnerID: 1,
                isParentProduct: null,
                buid: 2,
                productDescription:
                  "Your EUIN is still active and not eligible for renewal. You can subscribe to other courses in the What’s next section.",
                productType: 10502,
                productCode: "NISMVA",
                productPrice: 2000,
                isActive: 1,
                createdBy: 101,
                createdDate: "27/08/2021 11:48:28",
                lastModifiedBy: 101,
                lastModifiedDate: "27/08/2021 11:48:28",
                productLink: null,
                productAnchor: null,
                productSubtitle: null,
                productLanguage: "1",
                providerProductID: null,
                productVideourl: null,
                productMinPassingScore: null,
                isDisplayinCourseCatalog: 1,
                totalScore: null,
                productThumbnailDocId: null,
                productVidoeDocId: null,
                termsAndCondition: "Test",
                displaySequence: 1,
                productReattemptPrice: 0,
                productThumbnail: null,
                hasModules: 0,
              },
              paymentID: "0",
              attemptNumber: 1,
              attemptNumberGrade: "EUIN Number Generated",
              isActive: 1,
              createdDate: "06/10/2021 04:30:26",
              lastModifiedDate: "06/10/2021 04:55:46",
              isScoreUpdated: 0,
            };

            productDetailsByPartyId.push(euinData);
            this.setState({ productDetailsByPartyId: productDetailsByPartyId });

            console.log(
              "productDetailsByPartyId ::::::",
              this.state.productDetailsByPartyId
            );
          }
        }
      }
    );
  };
  updateStatus = () => {
    let data = {
      ExcecutionTracker: [
        {
          action: "NISM VA SUBSCRIBE",

          workflowId: this.props.personnelData.learnerID,
          workflowName:
            this.props.personnelData.lookingFor === "1001" ? "ARN" : "ARN",

          comment: "",
        },
      ],
    };

    updateStatus(data, (res) => {
      console.log(res);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });
  };
  updateBothStatus = () => {
    let data = {
      ExcecutionTracker: [
        {
          action: "FEAT COMPLETE",

          workflowId: this.props.personnelData.learnerID,
          workflowName:
            this.props.personnelData.lookingFor === "1001" ? "ARN" : "ARN",

          comment: "",
        },
      ],
    };

    updateStatus(data, (res) => {
      console.log(res);
      //  if (res.data.status === "success" || res.data.status === "SUCCESS") {
      let data = {
        ExcecutionTracker: [
          {
            action: "NISM VA SUBSCRIBE",

            workflowId: this.props.personnelData.learnerID,
            workflowName:
              this.props.personnelData.lookingFor === "1001" ? "ARN" : "ARN",

            comment: "",
          },
        ],
      };

      updateStatus(data, (res) => {
        console.log(res);
        window.location.reload();
      });
    });
  };

  onChangeParameter(selected, setFieldValue) {
    setFieldValue("location", selected);
    if (selected !== undefined) {
      LoginService.TokenUser((res) => {
        localStorage.setItem("jwt-token", res.responseObject);
        RegionManagementService.getCenter(
          "stateId=" + selected.value,
          (res) => {
            let center = [];
            for (let i = 0; i < res.responseListObject.length; i++) {
              let obj = {};
              obj["value"] = res.responseListObject[i].centerName;
              obj["label"] = res.responseListObject[i].centerName;
              center.push(obj);
            }
            setFieldValue("centerList", center);
          }
        );
      });
    }
  }

  onChangeParameterCenter(selected, setFieldValue) {
    setFieldValue("center", selected);

    //  this.props.getDevices(selected.value)
  }

  //Not impletemented for My Product/ what to learn next
  showMySubscription = (product) => {
    console.log("product");
    console.log(this.props.productDetailsAll);
    let isOptional = 0;
    this.props.productDetailsAll.map((e) => {
      if (e.productID == 187422) {
        isOptional = e.isOptional;
      }
    });

    //alert(isOptional)
    let profilePercent =
      localStorage.getItem("profilecomlitionvalue") === undefined
        ? parseInt(0)
        : parseInt(localStorage.getItem("profilecomlitionvalue"));
    let currentState = localStorage.getItem("currentState");
    //&& currentState==="EMPANELLED"
    if (product.productID === 203403 && currentState === "EMPANELLED") {
      //alert("hello")

      console.log(product);
      let temp_data = [];
      this.state.subscribedProduct.push(product);
      this.setState(
        (state) => (
          (state.showProductprequisite = true), (state.loading = true)
        )
      );

      var CheckoutObj = {
        learnerPartyID: this.props.personnelData.learnerPartyID,
        productID: product.productID,
        productName: product.productName,
        paymentID: 0,
        firstName: this.props.personnelData.firstName,
        middleName: this.props.personnelData.lastName,
        lastName: this.props.personnelData.lastName,
        gender: 1,
        emailID: this.props.personnelData.emailID,
        mobileNo: this.props.personnelData.mobileNo,
        contactNo: "",
        dob: this.props.personnelData.dob,
      };
      //
      temp_data.push(CheckoutObj);
      console.log(temp_data);
      for (let i = 0; i < temp_data.length; i++) {
        if (
          product.productID !== undefined &&
          this.props.personnelData.learnerPartyID !== undefined
        ) {
          RegisterUserService.AHRegisterUser(
            temp_data[i],
            (res) => {
              if (res.status === "success") {
                success("You have subscribed.", successNotification);

                this.UpdateMyProductDetails();
              } else {
                error(res.reasonText, errorNotification);
              }
            },
            (error) => {
              error(error, errorNotification);
            }
          );
        } else {
          warning(
            "Product is not define. Please co-ordinate with the support",
            warningNotification
          );
        }
      }
    } else {
      //alert("gggyyyuuu"+isOptional)
      if (
        profilePercent <= 60 &&
        this.props.personnelData.applicationStatus === "SUBMITTED"
      ) {
        warning(
          "Please complete your profile. Your profile score should be greater than 60% & the compulsory fields & documents should be added in order to proceed. You can undertake the course only after you have submitted your profile.",
          warningNotification
        );
      }
      // else if (currentState !== "FEAT COMPLETED" && isOptional == 0) {
      //     warning(
      //         "Please undertake FEAT first. Only once you undertake FEAT, you can undertake the course.",
      //         warningNotification
      //     );

      // }
      else if (currentState !== "FEAT COMPLETED" && isOptional == 1) {
        let temp_data = [];
        this.state.subscribedProduct.push(product);
        this.setState(
          (state) => (
            (state.showProductprequisite = true), (state.loading = true)
          )
        );
        if (product.productName === "NISM VA Preparatory Course") {
          let obj = {
            learnerPartyID: this.props.personnelData.learnerPartyID,
            productID: 203402,
            productName: "NISM VA Certification- Reattempt",
            paymentID: 0,
            firstName: this.props.personnelData.firstName,
            middleName: this.props.personnelData.lastName,
            lastName: this.props.personnelData.lastName,
            gender: 1,
            emailID: this.props.personnelData.emailID,
            mobileNo: this.props.personnelData.mobileNo,
            contactNo: "",
            dob: this.props.personnelData.dob,
          };
          temp_data.push(obj);
        }
        var CheckoutObj = {
          learnerPartyID: this.props.personnelData.learnerPartyID,
          productID: product.productID,
          productName: product.productName,
          paymentID: 0,
          firstName: this.props.personnelData.firstName,
          middleName: this.props.personnelData.lastName,
          lastName: this.props.personnelData.lastName,
          gender: 1,
          emailID: this.props.personnelData.emailID,
          mobileNo: this.props.personnelData.mobileNo,
          contactNo: "",
          dob: this.props.personnelData.dob,
        };
        //
        temp_data.push(CheckoutObj);
        console.log(temp_data);
        for (let i = 0; i < temp_data.length; i++) {
          if (
            product.productID !== undefined &&
            this.props.personnelData.learnerPartyID !== undefined
          ) {
            RegisterUserService.AHRegisterUser(
              temp_data[i],
              (res) => {
                if (res.status === "success") {
                  if (
                    temp_data[i].productName != "NISM VA Preparatory Course"
                  ) {
                    success(
                      'You have subscribed to the NISM VA Preparatory Course. Please click on "Make Payment" under NISM VA Preparatory Course to proceed.',
                      successNotification
                    );
                  }
                  this.UpdateMyProductDetails();
                  if (
                    temp_data[i].productName == "NISM VA Preparatory Course"
                  ) {
                    this.updateBothStatus();
                  }
                } else {
                  error(res.reasonText, errorNotification);
                }
              },
              (error) => {
                error(error, errorNotification);
              }
            );
          } else {
            warning(
              "Product is not define. Please co-ordinate with the support",
              warningNotification
            );
          }
        }
      } else {
        console.log(product);
        let temp_data = [];
        this.state.subscribedProduct.push(product);
        this.setState(
          (state) => (
            (state.showProductprequisite = true), (state.loading = true)
          )
        );
        if (product.productName === "NISM VA Preparatory Course") {
          let obj = {
            learnerPartyID: this.props.personnelData.learnerPartyID,
            productID: 203402,
            productName: "NISM VA Certification- Reattempt",
            paymentID: 0,
            firstName: this.props.personnelData.firstName,
            middleName: this.props.personnelData.lastName,
            lastName: this.props.personnelData.lastName,
            gender: 1,
            emailID: this.props.personnelData.emailID,
            mobileNo: this.props.personnelData.mobileNo,
            contactNo: "",
            dob: this.props.personnelData.dob,
          };
          temp_data.push(obj);
        }
        var CheckoutObj = {
          learnerPartyID: this.props.personnelData.learnerPartyID,
          productID: product.productID,
          productName: product.productName,
          paymentID: 0,
          firstName: this.props.personnelData.firstName,
          middleName: this.props.personnelData.lastName,
          lastName: this.props.personnelData.lastName,
          gender: 1,
          emailID: this.props.personnelData.emailID,
          mobileNo: this.props.personnelData.mobileNo,
          contactNo: "",
          dob: this.props.personnelData.dob,
        };
        //
        temp_data.push(CheckoutObj);
        console.log(temp_data);
        for (let i = 0; i < temp_data.length; i++) {
          if (
            product.productID !== undefined &&
            this.props.personnelData.learnerPartyID !== undefined
          ) {
            RegisterUserService.AHRegisterUser(
              temp_data[i],
              (res) => {
                if (res.status === "success") {
                  if (
                    temp_data[i].productName != "NISM VA Preparatory Course"
                  ) {
                    success(
                      'You have subscribed to the NISM VA Preparatory Course. Please click on "Make Payment" under NISM VA Preparatory Course to proceed.',
                      successNotification
                    );
                  }
                  this.UpdateMyProductDetails();
                  if (
                    temp_data[i].productName == "NISM VA Preparatory Course"
                  ) {
                    this.updateStatus();
                  }
                } else {
                  error(res.reasonText, errorNotification);
                }
              },
              (error) => {
                error(error, errorNotification);
              }
            );
          } else {
            warning(
              "Product is not define. Please co-ordinate with the support",
              warningNotification
            );
          }
        }
      }
    }
  };

  takeGDPIfromLearnerDashboard = () => {
    let partyID = localStorage.getItem("userid-Token");
    this.setState({ productDetailsByPartyId: [] });
    ProductService.fetchProductDetailsByPartyId(
      "learnerPartyId=" + partyID,
      (res) => {
        if (res.status === "success") {
          this.setState({ productDetailsByPartyId: res.responseListObject });
          this.GenerateMyProductDetails();
        }
      },
      (err) => {
        console.log(err);
      }
    );

    RegionManagementService.getLearnerSubscribedProduct(
      "learnerPartyId=" + this.props.personnelData.learnerPartyID,
      (prodResponse) => {
        if (prodResponse.responseListObject !== []) {
          let learnerSubscribedProduct = [];
          learnerSubscribedProduct = prodResponse.responseListObject;
          let productDetailsByPartyId = [];
          productDetailsByPartyId = this.props.productDetailsByPartyId.concat(
            learnerSubscribedProduct
          );

          this.setState({ productDetailsByPartyId: productDetailsByPartyId });

          // if(this.state.euinFlag && this.state.assignedUserMandateName != "Training and Certificate"){
          if (
            this.state.euinFlag &&
            this.state.assignedUserMandateName != "NISM"
          ) {
            let euinData = {
              assessmentID: 236383,
              buID: 2,
              learnerPartyID: 236337,
              productEntity: {
                productID: 10000,
                //"productName":"EUIN is still active",
                productName: "EUIN",
                parentProductID: null,
                partnerID: 1,
                isParentProduct: null,
                buid: 2,
                productDescription:
                  "Your EUIN is still active and not eligible for renewal. You can subscribe to other courses in the What’s next section.",
                productType: 10502,
                productCode: "NISMVA",
                productPrice: 2000,
                isActive: 1,
                createdBy: 101,
                createdDate: "27/08/2021 11:48:28",
                lastModifiedBy: 101,
                lastModifiedDate: "27/08/2021 11:48:28",
                productLink: null,
                productAnchor: null,
                productSubtitle: null,
                productLanguage: "1",
                providerProductID: null,
                productVideourl: null,
                productMinPassingScore: null,
                isDisplayinCourseCatalog: 1,
                totalScore: null,
                productThumbnailDocId: null,
                productVidoeDocId: null,
                termsAndCondition: "Test",
                displaySequence: 1,
                productReattemptPrice: 0,
                productThumbnail: null,
                hasModules: 0,
              },
              paymentID: "0",
              attemptNumber: 1,
              attemptNumberGrade: "EUIN Number Generated",
              isActive: 1,
              createdDate: "06/10/2021 04:30:26",
              lastModifiedDate: "06/10/2021 04:55:46",
              isScoreUpdated: 0,
            };

            productDetailsByPartyId.push(euinData);
            this.setState({ productDetailsByPartyId: productDetailsByPartyId });

            console.log(
              "productDetailsByPartyId ::::::",
              this.state.productDetailsByPartyId
            );
          }
        }
      }
    );
  };
  redirectToPayment = () => {
    //alert("kkkk")
    let learnerParty = "learnerPartyID=" + localStorage.getItem("userid-Token");
    let checkoutd = "";
    console.log(this.props.personnelData);
    var personaldetails = {
      partyId: this.props.personnelData.learnerPartyID,
      mobileNo: this.props.personnelData.mobileNo,
      firstName: this.props.personnelData.firstName,
      lastName: this.props.personnelData.lastName,
      emailID: this.props.personnelData.emailID,
      entryMode: 1,
      dateOfBirth: this.props.personnelData.dob,
    };
    //
    let data = [];
    //let checkoutd = ''
    let orderItemListArray = [];
    console.log("productdetail:::::::::::", this.state.productdetail);
    console.log(
      "this.state.productDetailsByPartyId:::",
      this.state.productDetailsByPartyId
    );
    this.state.productDetailsByPartyId.map((e) => {
      if (e.productEntity.productName == "NISM VA Certification- Reattempt") {
        let obj = {
          productId: e.productEntity.productID,
          productName: e.productEntity.productName,
          productPrice: e.productEntity.productReattemptPrice,
          partnerID: e.productEntity.partnerID,
        };
        data.push(obj);
        let orderItemList = {
          itemID: 1,
          productID: e.productEntity.productID,
          couponApplied: "ZERO",
          prodctPrice: e.productEntity.productReattemptPrice,
        };
        orderItemListArray.push(orderItemList);
        checkoutd = {
          learnerPartyID: localStorage.getItem("userid-Token"),
          orderPlacedOn: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"), //'15/11/2018 15:18:33',
          totalItemPrice: e.productEntity.productReattemptPrice,
          totalOrderPrice: e.productEntity.productReattemptPrice,
          paymentStatus: "Initiated",
          orderItemList: orderItemListArray,
          currency: "INR", // Field added for RazorPAy
        };
      }
    });

    this.state.checkout.push({ personaldetails, data }); //productdetails

    OrderService.RazorPayInsertOrder(
      checkoutd,
      (res) => {
        if (res.status === "success") {
          localStorage.setItem("reload", "true");
          this.props.history.push("/razorpay", {
            props: this.state.checkout,
            orderDetails: res.responseObject,
          });
        } else if (res.status === "fail") {
          if (res.reasonCode === "SM_11") {
            window.location.href = "/";
          } else {
            error(res.reasonText, errorNotification);
          }
        }
      },
      (error) => {
        console.log("Order Service ", error);
      }
    );
  };
  handleProfilePercentageUpdate1=async ()=>{
    let updatedPercentage=localStorage.getItem("profilecomlitionvalue");
  let peraddress;
      this.props.addressData.forEach(function (addr) {
        if (addr.addressType == "permanent") {
          peraddress = addr;
        }
      });
  let address = [];
// alert("vkd11");
console.log("vkd 12345",this.props.personnelData);
  let perAddress = {
    addressType: "permanent",
    addressTypeMark:this.props.addressData&&this.props.addressData[0]?.addressTypeMark==="permanent"?"permanent":"",
    addressId:  peraddress ? peraddress.addressId : "",
    street: peraddress ? peraddress.street : "",
    street2: peraddress ? peraddress.street2 : "",
    street3: peraddress ? peraddress.street3 : "",
    cityId: peraddress ? peraddress.cityId : "",
    stateId: peraddress ? peraddress.stateId : "",
    countryId: peraddress !== undefined ? peraddress.countryId : 1,
    postalCode: peraddress ? peraddress.postalCode : "",
  };
  address.push(perAddress);
  let submitStatus=this.props.personnelData.applicationStatus?this.props.personnelData.applicationStatus:""
  this.props.personnelData.learnerProfessionalQualification&&this.props.personnelData.learnerProfessionalQualification.map((ele) => {
    if(ele.hasOwnProperty("learneProfessionalrQualificationID")){
      delete ele.learneProfessionalrQualificationID;
    }
  })
  this.props.personnelData.learnerQualification&&this.props.personnelData.learnerQualification.map((ele) => {
    if(ele.hasOwnProperty("learnerQualificationID")){
      delete ele.learnerQualificationID;
    }
  }) 
  let CertificateValidUpto,DOB,EnrollmentDate
  let formattedDob = this.props.personnelData.dob?.split(" ");  
  let formattedDob1 = formattedDob?.[0]?.split("/");
  if(formattedDob1){
  DOB =`${parseInt(formattedDob1?.[0], 10)}/${parseInt(formattedDob1?.[1], 10)}/${parseInt(formattedDob1?.[2], 10)} 00:00:00`
  }
  let formattedCertificateValidUpto = this.props.personnelData.certificateValidUpto?.split(" ");  
  let formattedCertificateValidUpto1 = formattedCertificateValidUpto?.[0]?.split("/");
  if(formattedCertificateValidUpto1){
  CertificateValidUpto =`${parseInt(formattedCertificateValidUpto1?.[0], 10)}/${parseInt(formattedCertificateValidUpto1?.[1], 10)}/${parseInt(formattedCertificateValidUpto1?.[2], 10)} 00:00:00`
  }      
  let formattedEnrollmentDate = this.props.personnelData.enrollmentDate?.split(" ");  
  let formattedEnrollmentDate1 = formattedEnrollmentDate?.[0]?.split("/");
  if(formattedEnrollmentDate1){
   EnrollmentDate =`${parseInt(formattedEnrollmentDate1?.[0], 10)}/${parseInt(formattedEnrollmentDate1?.[1], 10)}/${parseInt(formattedEnrollmentDate1?.[2], 10)} 00:00:00`
  }
        
  let data = {
          learnerEuinOption:this.state?.learnerEuinOption?this.state?.learnerEuinOption:"",
          userID: localStorage.getItem("userid"),
          partyID: localStorage.getItem("userid-Token"),
          learnerPartyID: localStorage.getItem("userid-Token"),
          learnerID: this.props.personnelData?.learnerID,
          roleID: localStorage?.roleId,
          mobileNo: this.props.personnelData?.mobileNo,
          emailID: this.props.personnelData?.emailID,
          gender: this.props.personnelData?.gender,
          maritalStatus: this.props.personnelData?.maritalStatus,
          firstName: this.props.personnelData?.firstName,
          middleName: this.props.personnelData?.middleName,
          lastName: this.props.personnelData?.lastName,
          contactNo:"",
          secondaryEmailID: this.props.personnelData?.secondaryEmailID,
          // dob: this.props.personnelData.dob?moment(this.props.personnelData.dob).format("DD/MM/YYYY HH:mm:ss"):"",
          dob:DOB,
          isMandateUser: this.props.personnelData?.isMandateUser,
          mandateId: this.props.personnelData?.mandateId,
          empNo: this.props.personnelData?.empNo,
          panNumber: this.props.personnelData?.panNumber,
          aadharNumber: this.props.personnelData?.aadharNumber,
          learnerFatherName: this.props.personnelData?.learnerFatherName
            ? this.props.personnelData?.learnerFatherName
            : " ",
          // learnerFatherDOB: this.props.personnelData.learnerFatherDOB
          //   ? moment(this.props.personnelData.learnerFatherDOB).format("DD/MM/YYYY HH:mm:ss")
          //   : " ",
          learnerFatherDOB: this.props.personnelData?.learnerFatherDOB
          ? this.props.personnelData?.learnerFatherDOB
          : " ",
          learnerFatherEmployementType: this.props.personnelData?.learnerFatherEmployementType
            ? this.props.personnelData?.learnerFatherEmployementType
            : 0,
          learnerFatherCompanyName: this.props.personnelData?.learnerFatherCompanyName
            ? this.props.personnelData?.learnerFatherCompanyName
            : " ",
          learnerMotherName: this.props.personnelData?.learnerMotherName
            ? this.props.personnelData?.learnerMotherName
            : " ",
          // learnerMotherDOB: this.props.personnelData.learnerMotherDOB
          //   ? moment(this.props.personnelData.learnerMotherDOB).format("DD/MM/YYYY HH:mm:ss")
          //   : " ",
          learnerMotherDOB: this.props.personnelData?.learnerMotherDOB
          ? this.props.personnelData?.learnerMotherDOB
          : " ",
          learnerMotherEmployementType: this.props.personnelData?.learnerMotherEmployementType
            ? this.props.personnelData?.learnerMotherEmployementType
            : 0,
          learnerMotherCompanyName: this.props.personnelData?.learnerMotherCompanyName
            ? this.props.personnelData?.learnerMotherCompanyName
            : " ",
          learnerQualification:
            this.props.personnelData?.learnerQualification?.length != 0
              ? this.props.personnelData?.learnerQualification?.filter((x) => !!x)
              : [],
          address,
          isActive: 1,
          document: [],
          resumeDocumentURL: this.props.personnelData?.resumeDocumentURL,
          resumeVideoURL: this.props.personnelData?.resumeVideoURL,
          passportNumber:"",
          bankName: "Default Bank",
          branchName: "Default Branch",
          branchAddress: "Default Address",
          accountType: "1",
          accountNumber: "00000000000",
  
          arn: this.props.personnelData.arn||this.props?.arnNo,
          nameOfARNHolder: this.props.personnelData?.nameOfARNHolder,
          nameOfMutualFundEmpanelled:
            this.props.personnelData?.nameOfMutualFundEmpanelled !== undefined
              ? this.props.personnelData?.nameOfMutualFundEmpanelled?.filter((x) => !!x)
              : null,
  
          postalCode: peraddress ? peraddress?.postalCode : "",
          salutation: this.props.personnelData?.salutation,
          learnerProfessionalQualification:
            this.props.personnelData?.learnerProfessionalQualification !== "" &&
            this.props.personnelData?.learnerProfessionalQualification !== 0
              ? this.props.personnelData?.learnerProfessionalQualification?.filter((x) => !!x)
              : null,
          learnerFatherName: this.props.personnelData?.learnerFatherName,
          applicantSpouseFather: "",
          learnerCpeDetails:
            this.props.personnelData?.learnerCpeDetails !== "" && this.props.personnelData?.learnerCpeDetails !== 0
              ? this.props.personnelData?.learnerCpeDetails?.filter((x) => !!x)
              : null,
          proofOfAddress: this.props.personnelData?.proofOfAddress?this.props.personnelData?.proofOfAddress:"",
          applicationStatus: submitStatus,
          learnerType: this.props.personnelData?.learnerType,
          profileCompletion:updatedPercentage,
          isTourCompleted: 1,
          isAddressSame: true,
          learnerProfession: this.props.personnelData?.learnerProfession?this.props.personnelData?.learnerProfession:0,
          workflowName:
            this.state.processType === "1001" ? "EUIN" : "EUIN",
          ifscCode: "Default0000",
          isCertificate: this.props.personnelData?.isCertificate,
          nismRegistrationNo: this.state?.learnerEuinOption && 
          ![null,undefined,"null","undefined"].includes(this.state?.learnerEuinOption) &&
          this.state?.learnerEuinOption !== "NISM" ?
          this.props.personnelData?.nismRegistrationNo?this.props.personnelData?.nismRegistrationNo:"" : "",
          enrollmentNumber: this.state?.learnerEuinOption && 
          ![null,undefined,"null","undefined"].includes(this.state?.learnerEuinOption) &&
          this.state?.learnerEuinOption !== "NISM" ? 
          this.props.personnelData?.enrollmentNumber?this.props.personnelData?.enrollmentNumber:"" : "" ,
          euinNumber: this.state?.learnerEuinOption && 
          ![null,undefined,"null","undefined"].includes(this.state?.learnerEuinOption) &&
          this.state?.learnerEuinOption !== "NISM" ? 
          this.props.personnelData?.euinNumber?this.props.personnelData?.euinNumber:"" : "",
          certificateValidUpto: this.state?.learnerEuinOption && 
          ![null,undefined,"null","undefined"].includes(this.state?.learnerEuinOption) &&
          this.state?.learnerEuinOption !== "NISM" ? 
          CertificateValidUpto?CertificateValidUpto:"": "",
          enrollmentDate: this.state?.learnerEuinOption && 
          ![null,undefined,"null","undefined"].includes(this.state?.learnerEuinOption) &&
          this.state?.learnerEuinOption !== "NISM" ? 
          EnrollmentDate?EnrollmentDate:"": "",
          euinValidUpto: this.props.personnelData?.euinValidUpto?this.props.personnelData?.euinValidUpto:"",
          companyName: this.props.personnelData?.companyName,
          otherCompanyName: this.props.personnelData?.otherCompanyName,
           maskAadharUploadDate:[11024, "11024"].includes(this.props.personnelData?.proofOfAddress)? this.props.personnelData?.maskAadharUploadDate:"",
        };
        console.log("vkd datadash",data);
  
        this.setState({ loading: true });
      await RegisterUserService.updateUserProfile(data, (res) => {
          if (res.status === "success") {
            localStorage.setItem("UpdateUser","No")
            this.setState({ loading: false });
          }
          else {
            this.setState({ loading: false });
            error(res.reasonText, {
              ...errorNotification,
            });
          }
        });
      }
  handleLearnerEuinDetails=async ()=>{

    this.setState({ loading: true });
   await fetchProcessType(
      "learnerPartyID=" + localStorage.getItem("userid-Token"),
      async (res) => {
        if(res.status==="success"){
          await this.setState({
            processType: res.responseObject.lookingFor,
            learnerEuinOption: res.responseObject?.learnerEuinOption,
          },()=>{
              this.handleProfilePercentageUpdate1();
          });
          this.setState({ loading: false });
        }
      }
    );
  }

  handleProfilePercentageUpdate=async ()=>{
      await this.handleLearnerEuinDetails();
    
     }
  render() {
    console.log("props3", this.props);
    console.log("state34", this.state.emailVerificationFlag);

    let currentState = "";
    let personalData = JSON.parse(localStorage.getItem("PersonnelData"));
    // alert(personalData.responseObject.learnerID)
    let tempdata = {
      workflowId: personalData.responseObject.learnerID,
      // "204092",
      history: true,
    };

    ReattemptTestService.getStatuscompleted(tempdata, (res) => {
      console.log(res);
      currentState =
        currentState + res.data.responseListObject[0].state.applicationStatus;
      localStorage.setItem("currentState1", currentState);
    });
    let reaatemptData = "<To be filled by Exp >";
    console.log(this.state.productDetailsByPartyId);
    let termsData = "";
    //
    if (this.state.productDetailsByPartyId !== null) {
      for (let i = 0; i < this.state.productDetailsByPartyId.length; i++) {
        if (
          this.state.productDetailsByPartyId[i].productEntity.productName ==
          "NISM VA Certification- Reattempt"
        ) {
          // [0].productEntity.termsAndCondition
          termsData =
            this.state.productDetailsByPartyId[i].productEntity
              .termsAndCondition != null &&
            this.state.productDetailsByPartyId[
              i
            ].productEntity.termsAndCondition
              .split("linebreak")
              .map((i) => {
                return <p>{i}</p>;
              });
        }
      }
    }

    var roledata = this.props.userRoleAccess;
    return (
      <React.Fragment>
        <PopUpPortal
          HidePopup={this.state.loading}
          IsVisible={this.state.loading}
        />
        <div
          className="modal fade"
          id="nismValidityCheckPopup"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h2
                  className="modal-title text-center text-danger font-weight-bold"
                  id="exampleModalLabel"
                >
                  NISM is about to expire/ expired!
                </h2>
              </div>
              <div className="modal-body">
                <h3 className="text-dark"> You can renew NISM by 2 ways -</h3>
                <Formik
                  initialValues={{
                    Attempt: null,
                  }}
                  onSubmit={(values) => {
                    // debugger;
                    if (values.Attempt === null) {
                      error(
                        "Please select either of the given options.",
                        errorNotification
                        );
                      } else if (values.Attempt === "1") {
                        //Attempt the NISM Certification exam.
                        localStorage.setItem("UpdateUser","Yes")
                      console.log("nism exam", values);
                      var data = {
                        learnerID: this.props.personnelData.learnerID,
                        nismRenewFlag: values.Attempt,
                      };
                      RegionManagementService.setNismRenewalflag(
                        data,
                        (res) => {
                          if (res.status === "success") {
                            //Assign prepratory course;
                            var learnerData = {
                              learnerID: this.props.personnelData.learnerID,
                            };
                            RegionManagementService.updateUserWfJourney(
                              learnerData,
                              (res) => {
                                if (res.status === "success") {
                                  window
                                    .$("#nismValidityCheckPopup")
                                    .modal("hide");
                                  // setTimeout(function(){
                                  //   window.location.reload();
                                  // },1000)
                                  setTimeout(function () {
                                    window
                                      .$("#showStatusPopup")
                                      .modal(
                                        { backdrop: "static", keyboard: false },
                                        "show"
                                      );
                                  }, 1000);
                                }
                              }
                            );
                          }
                        }
                      );
                    } else if (values.Attempt === "2") {
                      //Via CPE
                      console.log("cpe", values);

                      var data = {
                        learnerID: this.props.personnelData.learnerID,
                        nismRenewFlag: values.Attempt,
                      };
                      RegionManagementService.setNismRenewalflag(
                        data,
                        (res) => {
                          if (res.status === "success") {
                            //Redirect to CPE
                            window.$("#nismValidityCheckPopup").modal("hide");
                            setTimeout(function () {
                              window.open(
                                "https://ciel.co.in/Training_calendar_nism.php",
                                "_blank"
                              );
                            }, 1000);
                          }
                        }
                      );
                    }
                  }}
                >
                  <Form className="nismCheck">
                    <div className="form-check">
                      <Field
                        className="form-check-input"
                        type="radio"
                        value="1"
                        id="nismAttempt"
                        name="Attempt"
                      />
                      <label className="form-check-label" htmlFor="nismAttempt">
                        &ensp;Attempt the NISM Certification exam.
                      </label>
                    </div>

                    <div className="form-check">
                      <Field
                        className="form-check-input"
                        type="radio"
                        value="2"
                        id="cpeAttempt"
                        name="Attempt"
                      />
                      <label className="card-text" htmlFor="cpeAttempt">
                        &ensp;Via CPE.
                      </label>
                    </div>

                    <div className="modal-footer">
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
        {/* <button
          type="button"
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#nismValidityCheckPopup" 
        >
          NISM Popup
        </button> */}
        <section className="learner-dashboard mb-50" id="learner-dashboard">
          <div className="container-fluid">
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-50 mb-10">
                {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#MandateModal">
                                Launch demo modal
                                </button> */}

                <span
                  colored
                  onClick={this.handleOpenDialog}
                  raised
                  ripple
                  id="myCheck"
                ></span>
                <span
                  colored
                  onClick={this.handleOpenDialog1}
                  raised
                  ripple
                  id="myCheck1"
                ></span>

                <div className="row">
                  <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 my-learningmargin">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 padding-0">
                      {roledata.map((item, key) =>
                        item.description === "Learner Dashboard" ||
                        item.description === "CIEL Dashboard" ? (
                          item.objectActionId.applicationObjectActionName ===
                            "My Subscription" ||
                          item.objectActionId.applicationObjectActionName ===
                            "MY CIEL OPS" ? (
                            this.state.productDetailsByPartyId !== null &&
                            this.state.productDetailsByPartyId != [] &&
                            this.state.productDetailsByPartyId != "" ? (
                              <div>
                                <h3 id="myLearningSection" className="learner-title">My Learning</h3>
                                <div className="my-learning">
                                  {/* <Carousel autoPlay showThumbs={false} > */}
                                  <Carousel
                                    additionalTransfrom={0}
                                    swipeable={true}
                                    draggable={false}
                                    showDots={false}
                                    responsive={responsive}
                                    ssr={true} // means to render carousel on server-side.
                                    infinite={false}
                                    autoPlay={
                                      this.props.deviceType !== "mobile"
                                        ? false
                                        : false
                                    }
                                    // autoPlaySpeed={1000}
                                    keyBoardControl={true}
                                    customTransition="all .5"
                                    transitionDuration={500}
                                    containerClass="carousel-container"
                                    // removeArrowOnDeviceType={["tablet", "mobile"]}
                                    deviceType={this.props.deviceType}
                                    //dotListClass="custom-dot-list-style"
                                    itemClass="carousel-item-padding-40-px"
                                    slidesToSlide={3}
                                  >
                                    {this.state.productDetailsByPartyId.map(
                                      (item) => {
                                        let ProductLable =
                                          item.productEntity.productName ===
                                            "NISM VA Certification- Reattempt" &&
                                          item.attemptNumberGrade ==
                                            "Reattempt";
                                        if (
                                          item.productEntity
                                            .isDisplayinCourseCatalog === 1 &&
                                          item.productEntity.productName !==
                                            "NISM VA Certification- Reattempt"
                                        ) {
                                          if (
                                            item.productEntity === undefined
                                          ) {
                                            return null;
                                          } else {
                                            if (
                                              item.productEntity.productName ===
                                              "EUIN Application"
                                            ) {
                                              if (
                                                this.props.personnelData
                                                  .euinEnteredBy == 0
                                              ) {
                                                return (
                                                  <MyProductExtract
                                                    myproduct={item}
                                                    key={
                                                      item.productEntity
                                                        .productID
                                                    }
                                                    myproduct1={
                                                      this.state
                                                        .productDetailsByPartyId
                                                    }
                                                    personnelProfileData={
                                                      this.props.personnelData
                                                    }
                                                    showMyproductprogresspath={
                                                      this.showMyproductdetail
                                                    }
                                                    subscription={
                                                      this.showMySubscription
                                                    }
                                                    takeGdpi={
                                                      this
                                                        .takeGDPIfromLearnerDashboard
                                                    }
                                                    showmymappedproducts={
                                                      this.state
                                                        .productDetailsByPartyId
                                                    }
                                                    {...this.props}
                                                  />
                                                );
                                              }
                                            } else {
                                              return (
                                                <MyProductExtract
                                                  myproduct={item}
                                                  key={
                                                    item.productEntity.productID
                                                  }
                                                  myproduct1={
                                                    this.state
                                                      .productDetailsByPartyId
                                                  }
                                                  personnelProfileData={
                                                    this.props.personnelData
                                                  }
                                                  showMyproductprogresspath={
                                                    this.showMyproductdetail
                                                  }
                                                  subscription={
                                                    this.showMySubscription
                                                  }
                                                  takeGdpi={
                                                    this
                                                      .takeGDPIfromLearnerDashboard
                                                  }
                                                  showmymappedproducts={
                                                    this.state
                                                      .productDetailsByPartyId
                                                  }
                                                  {...this.props}
                                                />
                                              );
                                            }
                                          }
                                        } else if (ProductLable) {
                                          if (
                                            item.productEntity
                                              .isDisplayinCourseCatalog === 1
                                          ) {
                                            if (
                                              item.productEntity === undefined
                                            ) {
                                              return null;
                                            } else {
                                              return (
                                                <pre>
                                                  11
                                                  <MyProductExtract
                                                    myproduct={item}
                                                    key={
                                                      item.productEntity
                                                        .productID
                                                    }
                                                    myproduct1={
                                                      this.state
                                                        .productDetailsByPartyId
                                                    }
                                                    personnelProfileData={
                                                      this.props.personnelData
                                                    }
                                                    showMyproductprogresspath={
                                                      this.showMyproductdetail
                                                    }
                                                    subscription={
                                                      this.showMySubscription
                                                    }
                                                    takeGdpi={
                                                      this
                                                        .takeGDPIfromLearnerDashboard
                                                    }
                                                    showmymappedproducts={
                                                      this.state
                                                        .productDetailsByPartyId
                                                    }
                                                    {...this.props}
                                                  />
                                                </pre>
                                              );
                                            }
                                          }
                                        }
                                      }
                                    )}
                                  </Carousel>
                                  <div
                                    className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-10 mb-10 "
                                    id="progress-status"
                                  >
                                    {this.state.showprogressbar === true ? (
                                      <div className="CloseProgresPath">
                                        {/* <span class="close" onClick={this.hideshowMyprod} > X </span>  */}
                                        {/* <button type="reset" class="close prgbarbtn" aria-label="Close" onClick={this.hideshowMyprod}><span aria-hidden="true">×</span>
                                                                                            </button> */}
                                        <MyProgressPath
                                          key={this.state.currentProdkey}
                                          CurrentKey={this.state.currentKey}
                                          currentproduct={
                                            this.state.currentProd[0]
                                          }
                                          {...this.state.selectedprodduct}
                                          mappedproductdetails={
                                            this.state.productDetailsByPartyId
                                          }
                                          emailID = {JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('login'))))?.emailId}
                                          courseProductIDList={this.state.courseProductIDList.length > 0 && this.state.courseProductIDList}
                                          allproductdetails={
                                            this.props.productDetailsAll
                                          }
                                          tempdata={this.state.preRequisite}
                                          {...this.props}
                                        />{" "}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <h3 className="learner-title">My Learning</h3>
                                <div className="my-noreacord">
                                  <MyProductNoRecord CalledFrom="MyLearning" />
                                </div>
                              </div>
                            )
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )
                      )}
                    </div>
                  </div>
                  {/* <MyProfile personnelProfileData={this.props.personnelData} personnelAddressData={this.props.addressData} {...this.props} />
                                <MyProgress learnerProgressOverview={this.state.learnerProgressOverviewData} /> */}

                  <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 mystatuspadding">
                    <MyStatus personnelProfileData={this.props.personnelData} />
                  </div>
                </div>
              </div>
             
                <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 mb-20 ml-notofication">
                    <h3 className="learner-title">Step Wise Process</h3>
                    <div className="my-status">
                      <h4
                        className="text-center"
                        style={{ textTransform: "uppercase" }}
                      >
                        <b>Congratulations !!</b>
                      </h4>
                      <h5>
                        Congratulations!! Following are the steps to be
                        undertaken in your journey. The journey/tabs would
                        become active depending on the mandate we have from your
                        organisation.
                      </h5>

                      <div class="arrow_box">
                        <h2 className="text-center">
                          Step1 – Profile Creation
                        </h2>
                        <p>
                          {" "}
                          <b>
                            Update your profile under the My Profile Section and
                            make the NISM VA payment.
                          </b>
                        </p>
                      </div>
                      <div class="arrow_box1">
                        <h2 className="text-center">Step 2 – NISM Training</h2>
                        <p>
                          {" "}
                          <b>
                            Browse through the NISM Online course and complete
                            it and appear for the exam.
                          </b>
                        </p>
                      </div>
                      {this.props.personnelData.isMandateUser === 1 ||
                      this.props.personnelData.isMandateUser === "1" ? (
                        <div class="arrow_box">
                          <h2 className="text-center">
                            Step 3 – EUIN Application
                          </h2>
                          <p>
                            {" "}
                            <b>
                              In case you already have a valid NISM certificate,
                              please fill your profile and mention your NISM
                              certificate details to activate the EUIN tab.{" "}
                            </b>
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* <div class="arrow_box1">
            <h2 className="text-center">Step 4</h2>
            <p> <b>NISM VA Exam</b></p>
          </div>
          <div class="arrow_box">
            <h2 className="text-center">Step 5</h2>
            <p> <b>EUIN details to be submitted</b></p>
          </div> */}
                      {/* <div class="arrow_box1">
            <h2 className="text-center">Step 6</h2>
            <p> <b>Choose your NISM Exam date</b> after course completion</p>
          </div>
          <div class="arrow_box">
            <h2 className="text-center">Step 7</h2>
            <p> <b>Take & Pass NISM Exam</b></p>
          </div>
          <div class="arrow_box1">
            <h2 className="text-center">Step 8</h2>
            <p> <b>Complete KYD & AMFI Registration Process (ARN)</b> <br />
          <a style={{color: "#fff"}} href="https://www.amfiindia.com/distributor-corner/become-mutual-fund-distributor#:~:text=The%20AMFI%20Registration%20Number%20%28ARN%29%20has%20been%20introduced,other%20intermediaries%20engaged%20in%20selling%20Mutual%20Funds%2C%20having " target="_blank"> Click Here</a></p>
          </div>
          <div class="arrow_box">
            <h2 className="text-center">Step 9</h2>
            <p> <b>Empanelment</b> with Partner Mutual Funds & Onboard NSE MF Transaction Platform</p>
          </div>
          <div class="arrow_box1">
            <h2 className="text-center">Step 10</h2>
            <p> <b>Please refer to your status box to track your progress</b></p>
          </div> */}
                    </div>
                  </div>
                  {roledata.map((item, key) =>
                    item.description === "Learner Dashboard" ||
                    item.description === "CIEL Dashboard" ? (
                      item.objectActionId.applicationObjectActionName ===
                        "My Profile" ||
                      item.objectActionId.applicationObjectActionName ===
                        "MY CIEL OPS" ? (
                        <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 mt-10 pl pr">
                          <MyProfile
                            personnelProfileData={this.props.personnelData}
                            personnelAddressData={this.props.addressData}
                            myproduct={this.state.productDetailsByPartyId}
                            learnerEuinOption={this.state.learnerEuinOption}
                            {...this.props}

                          />
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )
                  )}
              <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 mt-10 pl">
                {roledata.map((item, key) =>
                  item.description === "Learner Dashboard" ? (
                    item.objectActionId.applicationObjectActionName ===
                    "My Events" ? (
                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                        <MyCalander props={this.props.calenderEvents} />
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )
                )}
              </div>
              <div className="row">
                <div className="col-md-8 col-sm-6 col-xs-12">
                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 padding-0 mt-10">
                    {this.state.AllProductLeavingSelf !== null ? (
                      <div>
                        {" "}
                        <h3 className="learner-title">What To Learn Next</h3>
                        <div className="my-learning">
                          <Carousel
                            additionalTransfrom={0}
                            swipeable={true}
                            draggable={false}
                            showDots={false}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            autoPlay={
                              this.props.deviceType !== "mobile"
                                ? false
                                : false
                            }
                            // autoPlaySpeed={1000}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            // removeArrowOnDeviceType={["tablet", "mobile"]}
                            deviceType={this.props.deviceType}
                            //dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                            slidesToSlide={3}
                          >
                            {this.state.AllProductLeavingSelf
                              ? this.state.AllProductLeavingSelf.map(
                                  (item) => {
                                    let currentState = localStorage.getItem(
                                      "currentState1"
                                    );

                                    //  alert(this.state.currentState)
                                    {
                                      /* if (item.isActive === 1 && item.isDisplayinCourseCatalog === 1 && item.productName === "ExpertMFD Induction Course"
                                                                && currentState==="EMPANELLED"  */
                                    }
                                    if (
                                      item.isActive === 1 &&
                                      item.isDisplayinCourseCatalog === 1 &&
                                      item.productName ===
                                        "ExpertMFD: Orientation and Induction" &&
                                      currentState === "EMPANELLED"
                                    ) {
                                      //alert("hi")
                                      return (
                                        <MyProductAllExtract
                                          myproduct={item}
                                          key={item.productID}
                                          personnelProfileData={
                                            this.props.personnelData
                                          }
                                          history={this.props.history}
                                          showMypreRequisite={
                                            this.showMypreRequisite
                                          }
                                          subscription={
                                            this.showMySubscription
                                          }
                                        />
                                      );
                                    } else if (
                                      /* else if (item.isActive === 1 && item.isDisplayinCourseCatalog === 1 &&  (item.productName !== "NISM VA Certification- Reattempt" &&  item.productName !== "ExpertMFD Induction Course")) { */
                                      item.isActive === 1 &&
                                      item.isDisplayinCourseCatalog === 1 &&
                                      item.productName !==
                                        "NISM VA Certification- Reattempt" &&
                                      item.productName !==
                                        "ExpertMFD: Orientation and Induction"
                                    ) {
                                      return (
                                        <MyProductAllExtract
                                          myproduct={item}
                                          key={item.productID}
                                          personnelProfileData={
                                            this.props.personnelData
                                          }
                                          history={this.props.history}
                                          showMypreRequisite={
                                            this.showMypreRequisite
                                          }
                                          subscription={
                                            this.showMySubscription
                                          }
                                        />
                                      );
                                    }
                                  }
                                )
                              : ""}
                          </Carousel>
                          {
                            <div
                              className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-10 mb-10 "
                              id="progress-status1"
                            >
                              {/* {this.state.showAllproductprerequisite === true ?  */}
                              <div className="CloseProgresPathNew">
                                {/* <span class="close" onClick={this.hideshowMyprodNew} > X </span>  */}
                                {/* <button type="reset" class="close prgbarbtn" aria-label="Close" onClick={this.hideshowMyprodNew}><span aria-hidden="true">×</span>
                                                                  </button> */}
                                <MyProgressPath
                                  key={this.state.currentAllProdkey}
                                  CurrentKey={this.state.currentKey}
                                  currentproduct={
                                    this.state.currentAllProd[0]
                                  }
                                  {...this.state.selectedprodduct}
                                  mappedproductdetails={
                                    this.state.productDetailsByPartyId
                                  }
                                  emailID = {JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('login'))))?.emailId}
                                  courseProductIDList={this.state.courseProductIDList.length > 0 && this.state.courseProductIDList}
                                  allproductdetails={
                                    this.props.productDetailsAll
                                  }
                                />{" "}
                              </div>
                              {/* : null */}
                              {/* } */}
                            </div>
                          }
                          {/* New Courses Product Loop Ends  */}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h3 className="learner-title">What To Learn Next</h3>
                        <div className="my-noreacord">
                          <MyProductNoRecord CalledFrom="New Course" />
                        </div>
                      </div>
                    )}
                  </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 ml-notofication">
                  <h3 className="learner-title">My Notification</h3>
                  <div className="notificatio-details">
                    <h3>
                      For message please click on message icon in right top
                      corner
                    </h3>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </section>

        <div
          className="modal fade"
          id="GcubeInfo"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modalLabel"
          data-keyboard="true"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content" id="autoScroll">
              <button
                type="reset"
                class="close"
                aria-label="Close"
                onClick={(e) => {
                  window.$("#GcubeInfo").modal("hide");
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div>
                <section className="">
                  <div className="modal-body">
                    <div classNames="container">
                      <div
                        className="modal-head"
                        style={{
                          padding: "9px 8px",
                        }}
                      >
                        <div
                          className="section-title-black"
                          style={{ textAlign: "center" }}
                        >
                          <h3 style={{ color: "black" }}>GCube</h3>
                        </div>
                      </div>
                      <div className="" style={{ padding: "3px 20px" }}>
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th
                                className="text-center"
                                colspan="2"
                                scope="col"
                              >
                                <b>Minimum Requirements for Usage</b>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <b>Internate Access Speed</b>
                              </td>
                              <td>512 kbps</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Operating System</b>
                              </td>
                              <td>Windows 8/MacOS 10.10</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Web Browser</b>
                              </td>
                              <td>
                                Internate Explore 10 <br />
                                Firefox 53 <br /> Chrome 58 <br /> Safari <br />
                                For Complete Specification Refer to Section
                                "BROWSER COMPATIBILITY"
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Processor</b>
                              </td>
                              <td>Intel Core i5 Processor - 1.20 GHz</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Memory</b>
                              </td>
                              <td>4 GB</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Plug-ins</b>
                              </td>
                              <td>Adobe Flash Player 10 and above</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Bandwidth</b>
                              </td>
                              <td>
                                Minimum DSL/Cable modem for web-based delivery
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Screen Resolution</b>
                              </td>
                              <td>
                                Optimized @ 1024x768, Min Screen size supported
                                7 inches
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Browser Java Script Setting</b>
                              </td>
                              <td>JavaScript Enabled</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Browser Cookies Setting</b>
                              </td>
                              <td>Should accept the cookies</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Other Browser Settings</b>
                              </td>
                              <td>Popup enabled</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="mb-20 text-center">
                        <button
                          type="submit"
                          className="btn-5 mr-20"
                          onClick={(e) => {
                            window.$("#GcubeInfo").modal("hide");
                            //  this.refs.myProduct.InititateCourse()
                            let assheuburl =
                              Global_var.URL_GCUBE_CREDENTIAL_LOGIN1 +
                              localStorage.getItem("f_authPassword");
                            window.open(assheuburl, "_blank");
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="GcubeInfo1"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modalLabel"
          data-keyboard="true"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content" id="autoScroll">
              <button
                type="reset"
                class="close"
                aria-label="Close"
                onClick={(e) => {
                  window.$("#GcubeInfo1").modal("hide");
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div>
                {/* <div class="intro-title">Terms And Conditions</div> */}

                {/* this.state.productDetailsByPartyId */}
                <section className="">
                  <div className="modal-body">
                    <div classNames="container">
                      <div
                        className="modal-head"
                        style={{
                          padding: "9px 8px",
                        }}
                      >
                        <div
                          className="section-title-black"
                          style={{ textAlign: "center" }}
                        >
                          <h3 style={{ color: "black" }}>
                            ExpertMFD: Orientation and Induction
                          </h3>
                        </div>
                      </div>
                      <div className="" style={{ padding: "3px 20px" }}>
                        Congratulations!!! <br />
                        You are now moving to your induction course and training
                        program.
                        <br />
                        For any support, please contact expertmfd@ciel.co.in.
                        <br />
                        Thank You
                      </div>

                      <div className="mb-20 text-center">
                        <button
                          type="submit"
                          className="btn-5 mr-20"
                          onClick={(e) => {
                            window.$("#GcubeInfo1").modal("hide");
                            //  this.refs.myProduct.InititateCourse()
                            let assheuburl =
                              Global_var.URL_GCUBE_CREDENTIAL_LOGIN1 +
                              localStorage.getItem("f_authPassword");
                            window.open(assheuburl, "_blank");
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="termsandcondition"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modalLabel"
          data-keyboard="true"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content" id="autoScroll">
              <button
                type="reset"
                class="close"
                aria-label="Close"
                onClick={(e) => {
                  window.$("#termsandcondition").modal("hide");
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div>
                {/* <div class="intro-title">Terms And Conditions</div> */}

                {/* this.state.productDetailsByPartyId */}
                <section className="registration">
                  <div className="modal-body">
                    <div classNames="container">
                      <div
                        className="modal-head"
                        style={{
                          padding: "9px 8px",
                        }}
                      >
                        <div
                          className="section-title-black"
                          style={{ textAlign: "center" }}
                        >
                          <h3 style={{ color: "black" }}>
                            Terms and Conditions
                          </h3>
                        </div>
                      </div>
                      <div className="" style={{ padding: "3px 20px" }}>
                        <p>
                          {/* {termsData} */}
                          <h4 className="text-center">About</h4> <br />
                          <ul>
                            <li>
                              NISM Series VA - Mutual Fund Distributors
                              Certification Examination is mandatory for
                              independent distributors and advisers and
                              employees involved the dealing of mutual fund
                              products.
                            </li>
                            <li>
                              The training enables all aspiring candidates to
                              prepare for and successfully clear the NISM Series
                              VA - Mutual Fund Distributors Examination. The
                              training is a clear and concise version of the
                              course and aligns to the test objectives as
                              outlined by NISM. It includes summary videos which
                              contain key points from each module and serves as
                              a revision guide.
                            </li>
                            <li>
                              The training also contains practice tests which
                              not only serve as a reinforcement mechanism for
                              the learners, but also provide a mock environment
                              of the actual Examination.
                            </li>
                          </ul>
                          <h4 className="text-center">Instructions</h4>
                          <br />
                          <h4 className="text-center">Scheduling the Exam</h4>
                          <br />
                          <p>
                            The Candidate can choose a date for the NISM VA –
                            Mutual Fund Distributors Certification Examination
                            15 calendar days from today and not beyond 6 months
                            from today
                          </p>
                          <p>
                            Our Operations Team will make all effort to ensure
                            that everyone is enrolled as per the preferred date
                            and at the preferred location
                          </p>
                          <p>
                            In the event that the preferred date is unavailable
                            at the preferred location, our Operations Team will
                            contact the Candidate prior to enrolling them for
                            the next available date at the chosen location. Only
                            upon the confirmation of the Candidate for the
                            revised date, the Candidate will be enrolled
                          </p>
                          <h4 className="text-center">Rescheduling the Exam</h4>
                          <br />
                          <p>
                            No rescheduling of the NISM VA – Mutual Fund
                            Distributors Certification Examination will be
                            entertained, except in cases of Force Majeure
                          </p>
                          <h4 className="text-center">Missing the Exam</h4>
                          <br />
                          <p>
                            The Candidate bears the sole responsibility to
                            attend the NISM VA – Mutual Fund Distributors
                            Certification Examination once enrolled. Any reason
                            for missing the exam, such as Delays, Lack of
                            Connectivity, Absence of Admit Card / ID Proof, etc.
                            will be the responsibility of the Candidate
                            themselves, except in cases of Force Majeure
                          </p>
                          <h4 className="text-center">Failing the Exam</h4>
                          <br />
                          <ul>
                            <li>
                              In case the Candidate fails the NISM VA – Mutual
                              Fund Distributors Certification Examination, the
                              Candidate will be required to re-enroll for the
                              exam by choosing the Re-Exam Course from the
                              Candidate’s Dashboard. It may be noted that the
                              Candidate will be required to undertake the above
                              step(s) every time they fail the NISM VA – Mutual
                              Fund Distributors Certification Examination.
                            </li>

                            <li>
                              The instructions for the Re-Exam will be the same
                              as mentioned above.
                            </li>
                            <li>
                              Failure to comply with the Re-Examination process
                              as mentioned above will lead to disqualification
                              of the Candidate from the RCP Programme, along
                              with forfeiting the refundable fees paid and the
                              benefits therein.
                            </li>
                          </ul>
                        </p>
                      </div>
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="termsandconditions"
                          checked={this.state.checkTermsaandcondition}
                          value={this.state.checkTermsaandcondition}
                          onClick={(e) => {
                            console.log("aaaaa");
                            console.log(e.target.value);
                            if (this.state.checkTermsaandcondition == false) {
                              this.setState({ checkTermsaandcondition: true });
                              this.setState({ disabled: false });
                            } else {
                              this.setState({ checkTermsaandcondition: false });
                              this.setState({ disabled: true });
                            }
                            //  if(e.target.chek\)
                          }}
                          style={{ marginRight: "10px", marginTop: "15px" }}
                        />
                        <span
                          class="form-check-label"
                          for="gridCheck"
                          style={{ marginRight: "15px" }}
                        >
                          <a target="_blank">Terms and Conditions</a>
                          <span className="validation"> *</span>
                        </span>
                      </div>

                      <div className="mb-20 text-center">
                        <button
                          type="submit"
                          onClick={(e) => {
                            console.log(this.props);
                            // MyProductExtract.productSubscribe()
                            if (this.state.disabled == true) {
                              warning(
                                "Please accept terms and conditions",
                                warningNotification
                              );
                            } else {
                              this.redirectToPayment();
                            }
                          }}
                          className="btn-5 mr-20"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>

        <Formik
          initialValues={{
            emailId: "",
          }}
          onSubmit={(fields) => {
            var data1 = localStorage.getItem("login");
            var data2 = localStorage.getItem("PersonnelData");

            let data = [
              {
                userName: JSON.parse(data1).userName,
                userId: JSON.parse(data1).userId,
                emailId: JSON.parse(data1).emailId,
                passward: "",
                templateId: "1960110",
                mobileNumber: JSON.parse(data2).responseObject.mobileNo,
                termsandcondition: this.state.termsCondtions,
                status: "Reattempt request",
                partyId: JSON.parse(data2).responseObject.learnerPartyID,
                learnerId: JSON.parse(data2).responseObject.learnerID,
              },
            ];
            reattemptSendMail(data, this.props.history, (res) => {
              console.log(res);
            });
          }}
          render={({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            values,
          }) => (
            <React.Fragment>
              <div
                className="modal fade"
                id="TestReaatempt"
                style={{ display: this.state.display }}
                tabindex="-1"
                role="dialog"
                aria-labelledby="modalLabel"
                data-keyboard="true"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg" role="document">
                  <Form onSubmit={handleSubmit} className="form_name">
                    <div className="modal-content">
                      <button
                        type="button"
                        class="close"
                        aria-label="Close"
                        onClick={(e) => {
                          //this.props.history.push("/learnerDashboard")
                          //   window.location.reload()
                          window.$("#TestReaatempt").modal("hide");
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>

                      <section className="">
                        <div className="modal-body">
                          <div classNames="container">
                            <div
                              className="modal-head"
                              style={{
                                padding: "9px 8px",
                              }}
                            >
                              <div
                                className="section-title-black"
                                style={{ textAlign: "center" }}
                              >
                                <h3 style={{ color: "black" }}>
                                  Reattempt Feat
                                </h3>
                              </div>
                            </div>
                            <div className="" style={{ padding: "3px 20px" }}>
                              <h4 className="text-center">About</h4> <br />
                              <p>
                                FEAT - Financial Entrepreneurship Assessment
                                Test, is an online assessment which helps us
                                understand you better. Your entrepreneurship
                                quotient and inclination towards BFSI sector are
                                a few factors that will help you succeed in this
                                journey and also help us in building a stronger
                                relationship with you.
                              </p>
                              <p>
                                {" "}
                                FEAT comprises of a Factual Section, which
                                understands your professional experience and
                                approach towards embarking on an entrepreneurial
                                journey; and a Behavioural Section, which
                                analyses your responses towards the various
                                situations you may encounter as a part of your
                                entrepreneurial journey.
                              </p>
                              <p>
                                {" "}
                                It is important to note that FEAT is not a
                                reflection of your entrepreneurial abilities,
                                but only an understanding of your strengths and
                                areas of improvement.
                              </p>
                              <h4 className="text-center"> Instructions</h4>
                              <br />
                              <p>
                                {" "}
                                Please read the below instructions carefully to
                                ensure a seamless experience while taking the
                                FEAT –
                              </p>
                              <ul>
                                <li>
                                  {" "}
                                  Please ensure that the browser is:
                                  <br /> a. Chrome version 55 and above
                                  <br />
                                  b. Firefox version 50 and above
                                </li>
                                <li>
                                  {" "}
                                  Please check your internet connection prior to
                                  initiating the test. Loss of connection may
                                  cause the test to be auto submitted
                                </li>
                                <li>
                                  {" "}
                                  All questions are mandatory. You will have to
                                  answer all questions to move forward
                                </li>
                                <li>
                                  {" "}
                                  All questions are multiple choice. There are
                                  no right or wrong answers for the behavioural
                                  questions. Please answer freely
                                </li>
                                <li>
                                  {" "}
                                  In case the test gets
                                  submitted/stopped/restarted due to any
                                  technical/network issues, please contact us
                                </li>
                                <li>
                                  {" "}
                                  You will get 30 minutes to answer all
                                  questions. Although the average time for
                                  answering all questions is 10 minutes
                                </li>
                                <li>
                                  {" "}
                                  FEAT comprises of a Factual Section and a
                                  Behavioural Section. Please read all questions
                                  carefully
                                </li>
                                <li>
                                  {" "}
                                  You will get only one attempt for the test.
                                  Please answer all questions carefully
                                </li>
                                <li>
                                  {" "}
                                  The test will be submitted only when you click
                                  the submit button or if the allocated time (30
                                  minutes) expires
                                </li>
                              </ul>
                            </div>
                            <div className="text-center">
                              <button type="submit" className="btn-5 mr-20">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </Form>
                </div>
              </div>
            </React.Fragment>
          )}
        />

        <div
          id="defaultkydpopup"
          className="modal fade"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modalLabel"
          data-keyboard="true"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <button
                type="button"
                class="close"
                aria-label="Close"
                onClick={(e) => {
                  window.$("#defaultkydpopup").modal("hide");
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>

              <div className="modal-header">
                <div class="scr-board">
                  <h2>ExpertMFD - KYD and ARN Application</h2>
                  <div class="bottom-line"></div>
                </div>
              </div>
              <div className="modal-body">
                <div className="">
                  <div className="">
                    {/* <div class="reset">                                                                                                    <h3 className="message-text">
                                                                                                Are you sure, do you want CIEL to apply for KYD Process,
                                                                                                on your behalf ?
                </h3>
                                                                                                </div> */}
                    <div className="">
                      <div
                        id="loading-bar-spinner"
                        class="spinner"
                        style={{ display: this.state.loading1 }}
                      >
                        <div class="spinner-icon"></div>
                      </div>
                      <table class="table table-bordered text-center">
                        <thead>
                          <tr>
                            <th scope="col" className="text-center">
                              From Name
                            </th>
                            <th scope="col" className="text-center">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>KYD Form</td>
                            <td>
                              {" "}
                              <button
                                className="btn-12"
                                onClick={this.downloadKYD}
                              >
                                Download
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>ARN Form</td>
                            <td>
                              <button
                                className="btn-12"
                                onClick={this.downloadARN}
                              >
                                Download
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p>
                        Please download & sign the pre-filled ARN & KYD Form &
                        submit it at the nearest CAMS Centre
                      </p>
                      <p>
                        Submit it along with the requisite documents -
                        Photograph, Proof of Address, PAN, DD / PO of ₹1500 to
                        get your AMFI Registration Number (ARN)
                      </p>
                      <p>
                        <a href={CAMSList} target="_blank">
                          CAMS Centres
                        </a>{" "}
                      </p>
                      <p>
                        In case you want to change any pre-filled data, please
                        go back and edit your profile
                      </p>
                      <p className="">
                        <b>Note: </b>Once changed to applied, you cannot change
                        it back. Please click Submit to change or cancel
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div for="agree" className="assess-subtext-kyd">
                <input
                  type="checkbox"
                  onClick={(e) => {
                    if (this.state.termsconditionKYD == false) {
                      this.setState({ termsconditionKYD: true });
                    } else {
                      this.setState({ termsconditionKYD: false });
                    }
                  }}
                  id="agree"
                  name="agree"
                  value="agree"
                />
                <a href={TC} target="_blank">
                  {" "}
                  Terms and Conditions{" "}
                </a>
              </div>
              {/* <div class="modal-footer"> */}
              <div className="text-center">
                {this.state.termsconditionKYD == true ? (
                  <button
                    type="button"
                    className="btn-5 mt-20 mb-20 mr-5"
                    name="btnYes"
                    onClick={this.KYDSubmit}
                    //  onClick={this.getPaymentLink}
                  >
                    Submit
                  </button>
                ) : (
                  ""
                )}

                <button
                  type="button"
                  className="btn-5 mt-20 mb-20"
                  name="btnYes"
                  onClick={(e) => {
                    window.$("#defaultkydpopup").modal("hide");
                  }}
                  //  onClick={this.getPaymentLink}
                >
                  Cancel
                </button>
              </div>
              <div className="mb-20"></div>
            </div>
          </div>
        </div>

        {/* AMC Popup */}
        <div
          id="defaultkydpopup1"
          className="modal fade"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modalLabel"
          data-keyboard="true"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <button
                type="button"
                class="close"
                aria-label="Close"
                onClick={(e) => {
                  window.$("#defaultkydpopup1").modal("hide");
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>

              <div className="modal-header">
                <div class="scr-board">
                  <h2>Empanelment </h2>
                  <div class="bottom-line"></div>
                </div>
              </div>
              {/* First Popup*/}
              <div id="empanelment1">
                <div className="modal-body">
                  <div className="">
                    <div className="">
                      <div className="">
                        <div class="reset">
                          <h3 className="message-text">
                            Ready to get empanelled with ExpertMFD Partners?
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  for="agree"
                  className="assess-subtext-kyd"
                  id="empanelmentTC"
                >
                  <input
                    type="checkbox"
                    onClick={(e) => {
                      if (this.state.termsconditionemp == false) {
                        this.setState({ termsconditionemp: true });
                      } else {
                        this.setState({ termsconditionemp: false });
                      }
                    }}
                    id="agree"
                    name="agree"
                    value="agree"
                  />
                  <span style={{ paddingLeft: "10px" }}>
                    {" "}
                    I agree to provide my explicit consent to CIEL to initiate
                    my empanelment process with all the partner AMCs on the
                    EXpertMFD Platform.
                  </span>
                </div>
                <div className="text-center mt-20">
                  <button
                    type="button"
                    className="btn-5 mt-20 mb-20"
                    name="btnYes"
                    onClick={this.empanelment1}
                  >
                    Submit
                  </button>
                </div>
                <div className="mb-20"></div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="defaultkydpopup2"
          className="modal fade"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modalLabel"
          data-keyboard="true"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <button
                type="button"
                class="close"
                aria-label="Close"
                onClick={(e) => {
                  window.$("#defaultkydpopup2").modal("hide");
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>

              <div className="modal-header">
                <div class="scr-board">
                  <h2>Empanelment </h2>
                  <div class="bottom-line"></div>
                </div>
              </div>

              {/* Second Popup */}

              <div id="empanelment2" style={{ padding: "15px" }}>
                <div className="modal-body">
                  <div className="">
                    <div className="">
                      <div className="mt-10">
                        <table class="table table-bordered text-center">
                          <thead
                            style={{ background: "#085dab", color: "#fff" }}
                          >
                            <tr>
                              <th scope="col" className="text-center">
                                AMC Name
                              </th>
                              <th scope="col" className="text-center">
                                Terms and Conditions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.AmcList.length > 0 ? (
                              this.state.AmcList.map((e) => {
                                return (
                                  <tr>
                                    <td>{e.mst_amc_name}</td>

                                    <td>
                                      {" "}
                                      <a
                                        href={
                                          e.mst_amc_name.replace(/\s/g, "") ===
                                          "AdityaBirlaSunLifeMutualFund"
                                            ? AdityaBirlaSunLifeMutualFund
                                            : e.mst_amc_name.replace(
                                                /\s/g,
                                                ""
                                              ) === "AxisMutualFund"
                                            ? AxisMutualFund
                                            : e.mst_amc_name.replace(
                                                /\s/g,
                                                ""
                                              ) === "ICICIPrudentialMutualFund"
                                            ? ICICIPrudentialMutualFund
                                            : e.mst_amc_name.replace(
                                                /\s/g,
                                                ""
                                              ) === "NipponIndiaMutualFund"
                                            ? NipponIndiaMutualFund
                                            : e.mst_amc_name.replace(
                                                /\s/g,
                                                ""
                                              ) === "SBIMutualFund"
                                            ? SBIMutualFund
                                            : e.mst_amc_name.replace(
                                                /\s/g,
                                                ""
                                              ) === "SundaramMutualFund"
                                            ? SundaramMutualFund
                                            : e.mst_amc_name.replace(
                                                /\s/g,
                                                ""
                                              ) === "HDFCMutualFund"
                                            ? HDFCMutualFund
                                            : e.mst_amc_name.replace(
                                                /\s/g,
                                                ""
                                              ) === "L&TMutualFund"
                                            ? LTMutualFund
                                            : ""
                                        }
                                        target="_blank"
                                      >
                                        Terms and Conditions
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td>L & T</td>
                                <td>
                                  {" "}
                                  <a href="" target="_blank">
                                    Terms and Conditions
                                  </a>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  for="agree"
                  className="assess-subtext-kyd"
                  id="empanelmentTC"
                >
                  <input
                    type="checkbox"
                    onClick={(e) => {
                      if (this.state.termsconditionemp == false) {
                        this.setState({ termsconditionemp: true });
                      } else {
                        this.setState({ termsconditionemp: false });
                      }
                    }}
                    id="agree"
                    name="agree"
                    value="agree"
                  />
                  <span style={{ paddingLeft: "10px" }}>
                    {" "}
                    I agree that I have read and understood all the Terms and
                    Conditions of empanelment of all the partner AMCs on the
                    EXpertMFD Platform
                  </span>
                </div>

                <div className="text-center">
                  {this.state.termsconditionemp == true ? (
                    <button
                      type="button"
                      className="btn-5 mt-20 mb-20 m-5"
                      name="btnYes"
                      onClick={this.empanelment2}
                    >
                      Submit
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mb-20"></div>
              </div>

              {/* Third Popup */}
              <div id="empanelment3">
                <div className="modal-body">
                  <div className="">
                    <div className="">
                      <div className="">
                        {/* banking */}

                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            ifscCode: this.props.personnelData.ifscCode,
                            bankName: this.props.personnelData.bankName,
                            branch: this.props.personnelData.branchName,
                            branchAddress: this.props.personnelData
                              .branchAddress,
                            accountType: this.props.personnelData.accountType,
                            acccountNumber: this.props.personnelData
                              .accountNumber,

                            nomineeNam: "",
                            nomineeAddress: "",
                            relationshipWithTheDistributer: "",
                            //  signature:""
                          }}
                          validationSchema={Yup.object().shape({
                            ifscCode: Yup.string()
                              .matches(
                                /^[A-Z]{4}0[A-Z0-9]{6}$/,
                                "You must enter valid IFSC code with capital letters"
                              )

                              .required("You must specify a IFSC code"),
                            nomineeName: Yup.string().required(
                              "You must specify a nominee name"
                            ),
                            nomineeAddress: Yup.string().required(
                              "You must specify a nominee address"
                            ),
                            relationshipWithTheDistributer: Yup.string().required(
                              "You must specify a relationship with the distributer"
                            ),
                            // signature:Yup.string()
                            // .required("You must specify a signature"),
                          })}
                          onSubmit={(fields) => {
                            //    alert(fields.termsandcondition)
                            // if (fields.termsandcondition === true) {

                            var data1 = localStorage.getItem("PersonnelData");
                            var data2 = JSON.parse(
                              localStorage.getItem("PersonnelData")
                            );

                            let data = {
                              learnerID: data2.responseObject.learnerID,
                              nomineeName: fields.nomineeName,
                              nomineeAddress: fields.nomineeAddress,
                              relationshipWithTheDistributer:
                                fields.relationshipWithTheDistributer,
                              //    signature:fields.signature,
                              ifscCode: fields.ifscCode,
                              bankName: fields.bankName,
                              branchName: fields.branch,
                              branchAddress: fields.branchAddress,
                              accountType: fields.accountType,
                              accountNumber: fields.acccountNumber,
                            };

                            ReattemptTestService.saveNomineeDetails(
                              data,
                              (res) => {
                                if (
                                  res.data.status === "success" ||
                                  res.data.status === "SUCCESS"
                                ) {
                                  success(
                                    "Data Saved Successfully.",
                                    successNotification
                                  );

                                  let dataMail = [
                                    {
                                      userName: JSON.parse(data1).responseObject
                                        .firstName,
                                      emailId: JSON.parse(data1).responseObject
                                        .emailID,
                                      templateId: "1960650",
                                      status: "Empanelment for cielOps",
                                      partyId: JSON.parse(data1).responseObject
                                        .learnerPartyID,
                                      learnerId: JSON.parse(data1)
                                        .responseObject.learnerID,
                                      panNumber: JSON.parse(data1)
                                        .responseObject.panNumber,
                                    },
                                  ];

                                  let sms = [
                                    {
                                      userName: JSON.parse(data1).responseObject
                                        .firstName,
                                      templateId: "40130",
                                      mobileNumber: JSON.parse(data1)
                                        .responseObject.mobileNo,
                                      status: "UNDER EMPANELMENT",
                                      partyId: JSON.parse(data1).responseObject
                                        .learnerPartyID,
                                    },
                                  ];

                                  let mailData1 = [
                                    {
                                      userName: JSON.parse(data1).responseObject
                                        .firstName,
                                      emailId: JSON.parse(data1).responseObject
                                        .emailID,
                                      templateId: "1960630",
                                      status: "Empanelment for Distributer",
                                      partyId: JSON.parse(data1).responseObject
                                        .learnerPartyID,
                                      learnerId: JSON.parse(data1)
                                        .responseObject.learnerID,
                                      panNumber: JSON.parse(data1)
                                        .responseObject.panNumber,
                                    },
                                    {
                                      userName: JSON.parse(data1).responseObject
                                        .firstName,
                                      emailId: JSON.parse(data1).responseObject
                                        .emailID,

                                      templateId: "1960680",
                                      status: "Empanelment Accept Request",
                                      partyId: JSON.parse(data1).responseObject
                                        .learnerPartyID,
                                      learnerId: JSON.parse(data1)
                                        .responseObject.learnerID,
                                      panNumber: JSON.parse(data1)
                                        .responseObject.panNumber,
                                    },
                                  ];

                                  RegisterUserService.sendMailKYD(
                                    dataMail,
                                    (res2) => {}
                                  );

                                  RegisterUserService.sendSms(
                                    sms,
                                    (res3) => {}
                                  );

                                  RegisterUserService.sendMail(
                                    mailData1,
                                    (res) => {
                                      setTimeout(() => {
                                        window.location.reload();
                                      }, 5000);
                                    }
                                  );

                                  window.$("#defaultkydpopup2").modal("hide");

                                  //window.location.reload()
                                }
                              }
                            );
                          }}
                          render={({
                            errors,
                            touched,
                            handleSubmit,
                            setFieldValue,
                            values,
                          }) => (
                            <React.Fragment>
                              <Form
                                onSubmit={handleSubmit}
                                className="form_name"
                              >
                                <div className="modal-body">
                                  <div classNames="container">
                                    {/* banking */}
                                    <section
                                      className="create-user page-section"
                                      id="banking-details"
                                    >
                                      <div className="row">
                                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                          <h2 className="sub-header">
                                            Banking Details
                                          </h2>
                                          <div className="form-group">
                                            <div className="row">
                                              <div className="col-md-6 col-sm-12 col-xs-12">
                                                <label>
                                                  IFSC Code
                                                  <span className="validation">
                                                    {" "}
                                                    *
                                                  </span>
                                                </label>
                                                <Field
                                                  type="text"
                                                  name="ifscCode"
                                                  disabled={
                                                    values.disabledForCielops
                                                  }
                                                  maxlength="11"
                                                  onChange={(event) => {
                                                    let ifscCode =
                                                      event.target.value;
                                                    ifscCode = ifscCode.toUpperCase();
                                                    setFieldValue(
                                                      (values.ifscCode = ifscCode)
                                                    );
                                                  }}
                                                  onBlur={(event) => {
                                                    fetch(
                                                      "https://ifsc.razorpay.com/" +
                                                        event.target.value
                                                    )
                                                      .then((response) =>
                                                        response.json()
                                                      )
                                                      .then((records) => {
                                                        console.log(records);

                                                        // this.updateWeightage("bankName", records.BANK)
                                                        // this.updateWeightage("branch", records.BRANCH.replace(/r/g, ''))
                                                        // this.updateWeightage("branchAddress", records.ADDRESS.replace(/r/g, ''))
                                                        setFieldValue(
                                                          (values.bankName =
                                                            records.BANK)
                                                        );
                                                        setFieldValue(
                                                          (values.branch =
                                                            records.BRANCH)
                                                        );
                                                        setFieldValue(
                                                          (values.branchAddress =
                                                            records.ADDRESS)
                                                        );
                                                      })
                                                      .catch((error) => {
                                                        // this.updateWeightage("bankName", "")
                                                        // this.updateWeightage("branch", "")
                                                        // this.updateWeightage("branchAddress", "")
                                                        // this.updateWeightage("accountType", "")
                                                        // this.updateWeightage("acccountNumber", "")
                                                        setFieldValue(
                                                          (values.bankName = "")
                                                        );
                                                        setFieldValue(
                                                          (values.branch = "")
                                                        );
                                                        setFieldValue(
                                                          (values.branchAddress =
                                                            "")
                                                        );
                                                        setFieldValue(
                                                          (values.accountType =
                                                            "")
                                                        );
                                                        setFieldValue(
                                                          (values.acccountNumber =
                                                            "")
                                                        );
                                                      });
                                                  }}
                                                  className="form-control"
                                                />
                                                {errors.ifscCode && (
                                                  <div className="validation">
                                                    {errors.ifscCode}
                                                  </div>
                                                )}
                                              </div>
                                              <div className="col-md-6 col-sm-12 col-xs-12">
                                                <label>
                                                  Name Of The Bank
                                                  <span className="validation">
                                                    {" "}
                                                    *
                                                  </span>
                                                </label>
                                                <Field
                                                  type="text"
                                                  name="bankName"
                                                  disabled={true}
                                                  onBlur={(event) => {}}
                                                  className="form-control"
                                                />
                                                {errors.bankName && (
                                                  <div className="validation">
                                                    {errors.bankName}
                                                  </div>
                                                )}
                                              </div>
                                              <div className="col-md-12 col-sm-12 col-xs-12"></div>
                                              <div className="col-md-6 col-sm-12 col-xs-12">
                                                <label>
                                                  Branch
                                                  <span className="validation">
                                                    {" "}
                                                    *
                                                  </span>
                                                </label>

                                                <Field
                                                  type="text"
                                                  name="branch"
                                                  disabled={true}
                                                  onBlur={(event) => {}}
                                                  className="form-control"
                                                />
                                                {errors.branch && (
                                                  <div className="validation">
                                                    {errors.branch}
                                                  </div>
                                                )}
                                              </div>

                                              <div className="col-md-6 col-sm-12 col-xs-12">
                                                <label>
                                                  Branch Address
                                                  <span className="validation">
                                                    {" "}
                                                    *
                                                  </span>
                                                </label>
                                                <Field
                                                  type="text"
                                                  name="branchAddress"
                                                  disabled={true}
                                                  onBlur={(event) => {}}
                                                  className="form-control"
                                                />
                                                {errors.branchAddress && (
                                                  <div className="validation">
                                                    {errors.branchAddress}
                                                  </div>
                                                )}
                                              </div>
                                              <div className="col-md-12 col-sm-12 col-xs-12"></div>
                                              <div className="col-md-6 col-sm-12 col-xs-12">
                                                <label>
                                                  Account Type
                                                  <span className="validation">
                                                    {" "}
                                                    *
                                                  </span>
                                                </label>
                                                <Field
                                                  as="select"
                                                  name="accountType"
                                                  disabled={
                                                    values.disabledForCielops
                                                  }
                                                  className="form-control"
                                                >
                                                  <option value="">
                                                    Select Account Type
                                                  </option>
                                                  <option value="1">
                                                    Saving
                                                  </option>
                                                  <option value="2">
                                                    Current
                                                  </option>
                                                </Field>
                                                {errors.accountType && (
                                                  <div className="validation">
                                                    {errors.accountType}
                                                  </div>
                                                )}
                                              </div>
                                              <div className="col-md-6 col-sm-12 col-xs-12">
                                                <label>
                                                  Account Number
                                                  <span className="validation">
                                                    {" "}
                                                    *
                                                  </span>
                                                </label>
                                                <Field
                                                  type="text"
                                                  name="acccountNumber"
                                                  minLength="9"
                                                  maxlength="18"
                                                  disabled={
                                                    values.disabledForCielops
                                                  }
                                                  className="form-control"
                                                />
                                                {errors.acccountNumber && (
                                                  <div className="validation">
                                                    {errors.acccountNumber}
                                                  </div>
                                                )}
                                              </div>
                                              <div className="col-md-12 col-sm-12 col-xs-12">
                                                <br />
                                                <p className="">
                                                  <b>Note: </b>These are the
                                                  banking details where you will
                                                  receive your Distribution
                                                  Commission
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>

                                    {/* Nominee Details */}
                                    <section
                                      className="create-user page-section"
                                      id="banking-details"
                                    >
                                      <div className="row">
                                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                          <h2 className="sub-header">
                                            Nominee Details
                                          </h2>
                                          <div className="form-group">
                                            <div className="row">
                                              <div className="col-md-6 col-sm-12 col-xs-12">
                                                <label>
                                                  Full Name
                                                  <span className="validation">
                                                    {" "}
                                                    *
                                                  </span>
                                                </label>
                                                <Field
                                                  type="text"
                                                  name="nomineeName"
                                                  className="form-control"
                                                />
                                                {errors.nomineeName && (
                                                  <div className="validation">
                                                    {errors.nomineeName}
                                                  </div>
                                                )}
                                              </div>
                                              <div className="col-md-6 col-sm-12 col-xs-12">
                                                <label>
                                                  Address
                                                  <span className="validation">
                                                    {" "}
                                                    *
                                                  </span>
                                                </label>
                                                <Field
                                                  type="text"
                                                  name="nomineeAddress"
                                                  className="form-control"
                                                />
                                                {errors.nomineeAddress && (
                                                  <div className="validation">
                                                    {errors.nomineeAddress}
                                                  </div>
                                                )}
                                              </div>
                                              <div className="col-md-6 col-sm-12 col-xs-12">
                                                <label>
                                                  Relationship with the
                                                  Distributor
                                                  <span className="validation">
                                                    {" "}
                                                    *
                                                  </span>
                                                </label>
                                                <Field
                                                  type="text"
                                                  name="relationshipWithTheDistributer"
                                                  className="form-control"
                                                />
                                                {errors.relationshipWithTheDistributer && (
                                                  <div className="validation">
                                                    {
                                                      errors.relationshipWithTheDistributer
                                                    }
                                                  </div>
                                                )}
                                              </div>
                                              {/* <div className="col-md-6 col-sm-12 col-xs-12">
                                                                                                <label>Signature
                                          <span className="validation"> *</span>

                                                                                                </label>
                                                                                                <Field
                                                                                                    type="text"
                                                                                                    name="signature"
                                                                                                    className="form-control"
                                                                                                />
                                                                                                {errors.signature && <div className="validation">{errors.signature}</div>}

                                                                                            </div> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>

                                    {/* ARN Details */}
                                    {/* <section
                                                                            className="create-user page-section"
                                                                            id="banking-details"
                                                                        >
                                                                            <div className="row">
                                                                                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                                                    <h2 className="sub-header">
                                                                                    ARN Details
                                  </h2>
                                                                                    <div className="form-group">
                                                                                        <div className="row">
                                                                                            <div className="col-md-6 col-sm-12 col-xs-12">
                                                                                                <label>ARN Number
                                          <span className="validation"> *</span>

                                                                                                </label>
                                                                                                <Field
                                                                                                    type="text"
                                                                                                    name="nomineeName"
                                                                                                    className="form-control"
                                                                                                />
                                                                                                {errors.nomineeName && <div className="validation">{errors.nomineeName}</div>}

                                                                                            </div>
                                                                                           
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </section> */}
                                  </div>
                                </div>
                                <div className="text-center">
                                  <button
                                    type="submit"
                                    className="btn-5 mt-20 mb-20 m-5"
                                    name="btnYes"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </Form>
                            </React.Fragment>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-20"></div>
              </div>
            </div>
          </div>
        </div>

        <Formik
          enableReinitialize={true}
          initialValues={{
            picked:
              this.state.getDateLocation.length > 0
                ? this.state.getDateLocation[0].additionalDetails.flag == true
                  ? "online"
                  : "offline"
                : "offline",
            corstateId: "",
            center: {
              label:
                this.state.getDateLocation.length > 0
                  ? this.state.getDateLocation[0].additionalDetails.center
                  : "--select--",
              value:
                this.state.getDateLocation.length > 0
                  ? this.state.getDateLocation[0].additionalDetails.center
                  : "",
            },
            testDate:
              this.state.getDateLocation.length > 0
                ? new Date(
                    this.state.getDateLocation[0].additionalDetails.requestedDate
                  )
                : "",
            //this.state.getDateLocation.length > 0 ? new Date(this.state.getDateLocation[0].additionalDetails.requestedDate) : '',
            // this.state.getDateLocation.length > 0 ? moment(this.state.getDateLocation[0].additionalDetails.requestedDate).format("YYYY-MM-DD") : '',
            location: {
              label:
                this.state.getDateLocation.length > 0
                  ? this.state.getDateLocation[0].additionalDetails.stateName
                  : "--select--",
              value:
                this.state.getDateLocation.length > 0
                  ? this.state.getDateLocation[0].additionalDetails.statId
                  : "",
            },
            // this.state.getDateLocation.length > 0 ? this.state.getDateLocation[0].responseObject.additionalDetails.stateId : '',
            centerList: this.state.centerList,

            termsandcondition: "",
            disabled:
              this.state.getDateLocation.length > 0
                ? this.state.getDateLocation[0].additionalDetails.flag == true
                  ? "true"
                  : "false"
                : "false",
            listOfMonth: this.state.listOfMonth,
            listOfDay: this.state.listOfDay,
          }}
          onSubmit={(fields) => {
            // this.handleProfilePercentageUpdate()
            // alert("fields.termsandcondition"+ fields.termsandcondition)
            let reqDate =
              fields["year"] + "-" + fields["month"] + "-" + fields["day"];

            console.log("fields");
            console.log(fields);

            if (this.state.disabledAll == true) {
              warning(
                "Your request has already been processed ,so you can't change the data",
                warningNotification
              );
            } else {
              if (
                fields.picked == "offline" &&
                (fields.location.label === "--select--" ||
                  fields.center.label === "--select--" ||
                  fields.termsandcondition === false ||
                  fields.termsandcondition === undefined ||
                  fields.termsandcondition === "false")
              ) {
                warning(
                  "Please select all mandatory fields",
                  warningNotification
                );
              } else {
                //    alert(fields.termsandcondition)
                if (fields.termsandcondition === true) {
                  var timestamp =
                    new Date().getTime() + 30 * 24 * 60 * 60 * 1000;

                  let selDate =
                    fields["year"] +
                    "-" +
                    fields["month"] +
                    "-" +
                    fields["day"];
                  selDate = new Date(selDate);
                  var date = new Date();
                  date.setDate(date.getDate() + 15);

                  var date2 = new Date();
                  var date2 = date2.setDate(date2.getDate() + 181);
                  if (selDate < date || selDate > date2) {
                    warning(
                      "Date Should greater than 15 day from current date and less than 180 days from current days.",
                      warningNotification
                    );
                  } else {
                    console.log(fields);
                    var data1 = localStorage.getItem("login");
                    var data2 = localStorage.getItem("PersonnelData");
                    let data = [
                      {
                        // "action": "NISM PROFILE CREATE",   //"REGISTER",   //CurrentState
                        action: "PROFILE COMPLETE",
                        // action: this.state.applicationStatusStage,
                        workflowId: JSON.parse(data2).responseObject.learnerID,
                        //JSON.parse(data2).responseObject.learnerID,
                        additionalDetails: {
                          stateId:
                            fields.location.value == undefined
                              ? ""
                              : fields.location.value,
                          stateName:
                            fields.location.label == undefined
                              ? ""
                              : fields.location.label,
                          center:
                            fields.center.value == undefined
                              ? ""
                              : fields.center.value,
                          centerName:
                            fields.center.label == undefined
                              ? ""
                              : fields.center.label,

                          //"requestedDate":fields['year']+'-'+fields['month']+'-'+fields['day'],
                          requestedDate: "2000-1-1",

                          //fields.testDate1,
                          flag: fields.picked == "online" ? true : false,
                          isconfirmbycilopps: false,
                        },
                      },
                    ];
                    
                    ReattemptTestService.UpdateWorkflow(data, (res) => {
                      //console.log(res)
                      if (
                        res.data.status === "success" ||
                        res.data.status === "SUCCESS"
                      ) {
                        success(
                          "Team CIEL will contact you for the date of exam shortly",
                          successNotification
                        );
                        var data1 = localStorage.getItem("PersonnelData");
                        let data_temp = [
                          {
                            userName: JSON.parse(data1).responseObject
                              .firstName,
                            //"selectedDate":moment(selDate).format("YYYY-MM-DD"),
                            selectedDate: "2000-01-01",
                            // fields['year']+'-'+fields['month']+'-'+fields['day'],
                            //fields.testDate1,
                            location: fields.center.value,
                            emailId: JSON.parse(data1).responseObject.emailID,
                            templateId: "1960250",
                            status: "Location_for_the_NISM_Exam",
                          },
                        ];

                        // RegisterUserService.sendMail(data_temp, res => {
                        //     window.location.reload()
                        // })
                        window.$("#showStatusPopup").modal("hide");
                        setTimeout(() => {
                          window.location.reload();
                          let profilePercent1 =
                            localStorage.getItem("profilecomlitionvalue") ===
                            undefined
                              ? parseInt(0)
                              : parseInt(
                                  localStorage.getItem("profilecomlitionvalue")
                                );
                          if (
                            localStorage.getItem("MandateModalPopup") ===
                              "hide" &&
                            profilePercent1 >= 60 &&
                            this.props.personnelData.applicationStatus ===
                              "SUBMITTED"
                          ) {
                            RegionManagementService.checkNismValidity(
                              "learnerID=" + this.props.personnelData.learnerID,
                              (res) => {
                                if (res.status === "success") {
                                  // debugger;
                                  if (
                                    res.responseListObject[0]
                                      .isNismDateValid === true ||
                                    res.reasonText ===
                                      "NISM DATE IS NOT PRESENT"
                                  ) {
                                    window
                                      .$("#nismValidityCheckPopup")
                                      .modal("hide");
                                    //alert("7");
                                  } else if (
                                    res.responseListObject[0]
                                      .isNismDateValid === false
                                  ) {
                                    window
                                      .$("#nismValidityCheckPopup")
                                      .modal("show");
                                  }
                                }
                              }
                            );
                          }
                        }, 2000);
                      }
                    });  
         }
                } else {
                  warning(
                    "Please select terms and condition",
                    warningNotification
                  );
                }
              }
            }
          }}
          //  }

          render={({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            values,
          }) => (
            <React.Fragment>
              <div
                className="modal fade"
                id="showStatusPopup"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modalLabel"
                data-keyboard="true"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-md" role="document">
                  <Form onSubmit={handleSubmit} className="form_name">
                    <div className="modal-content">
                      {/* <button
                                                type="button"
                                                class="close"
                                                  aria-label="Close"
                                                onClick={
                                                    e => {
                                                        window.$('#showStatusPopup').modal('hide')
                                                    }
                                                }
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button> */}

                      <section className="registration">
                        <div className="modal-body">
                          <div classNames="container">
                            <div
                              className="modal-head"
                              style={{
                                padding: "9px 8px",
                              }}
                            >
                              <div
                                className="section-title-black"
                                style={{ textAlign: "center" }}
                              >
                                <h3 style={{ color: "black" }}>
                                  Preferred NISM Exam Mode
                                </h3>
                              </div>
                            </div>
                            <div className="">
                              <div>
                                <center style={{ color: "red" }}>
                                  {" "}
                                  {this.state.getDateLocation.length > 0
                                    ? this.state.getDateLocation[0]
                                        .additionalDetails.action === "CANCEL"
                                      ? "Your NISM Exam has been Cancelled"
                                      : ""
                                    : ""}{" "}
                                </center>
                              </div>

                              <div className="col-md-12">
                                {/* <p> Exam date selected should be greater than 15 days from today and less than 180 days</p>
                                                            <p> This is a preferred date and subject to availability and profile approval with NISM </p> */}
                                <p>
                                  {" "}
                                  Once the date and time is confirmed by NISM,
                                  it cannot be changed{" "}
                                </p>
                                <p>
                                  {" "}
                                  If you fail to appear for the examination on
                                  the enrolled date and time, you will have to
                                  pay the exam fees again & apply for new date{" "}
                                </p>
                                <p>
                                  {" "}
                                  If you are opting for Online Examination,
                                  please refer to the system requirements &
                                  prerequisites by NISM{" "}
                                  <a href={NISMList} target="_blank">
                                    Click Here{" "}
                                  </a>
                                </p>
                                <p>
                                  Final date will be communicated by the
                                  operation team via a call.
                                </p>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label
                                    class="form-check-label"
                                    htmlFor="gridCheck"
                                    style={{ marginRight: "15px" }}
                                  >
                                    Mode
                                    <span className="validation"> *</span>
                                    {/* <br /> */}
                                    <span className="validation">
                                      {" "}
                                      With effect from January 1, 2023 NISM will
                                      stop conducting remote proctored mode of
                                      exam (Online mode). Please select an
                                      Offline mode only to appear for the test
                                      center based examinations.
                                    </span>
                                  </label>
                                  <div>
                                    <Field
                                      type="radio"
                                      onClick={(e) => {
                                        setFieldValue(
                                          (values.disabled = "false")
                                        );
                                        setFieldValue(
                                          (values.picked = "offline")
                                        );
                                      }}
                                      name="picked"
                                      // checked={this.state.getDateLocation.length > 0 ?
                                      //     this.state.getDateLocation[0].additionalDetails.flag == true ? "" : "checked"
                                      //     : "checked"
                                      // }

                                      disabled={this.state.disabledAll}
                                      value="offline"
                                      style={{ marginRight: "10px" }}
                                      className="form-check-input"
                                    />
                                    <label
                                      class="form-check-label"
                                      htmlFor="gridCheck"
                                      style={{ marginRight: "15px" }}
                                    >
                                      Offline
                                    </label>

                                    <Field
                                      type="radio"
                                      name="picked"
                                      disabled
                                      // disabled={this.state.disabledAll}
                                      // checked={this.state.getDateLocation.length > 0 ?
                                      //     this.state.getDateLocation[0].additionalDetails.flag == true ? "checked" : ""
                                      //     : ""
                                      // }
                                      onClick={(e) => {
                                        setFieldValue(
                                          (values.picked = "online")
                                        );
                                        setFieldValue(
                                          (values.disabled = "true")
                                        );

                                        setFieldValue((values.testDate = ""));
                                        setFieldValue((values.location = ""));
                                        setFieldValue((values.center = ""));

                                        //
                                      }}
                                      className="form-check-input"
                                      style={{ marginRight: "10px" }}
                                      value="online"
                                    />
                                    <label
                                      class="form-check-label"
                                      htmlFor="gridCheck"
                                      style={{ marginRight: "15px" }}
                                    >
                                      Online
                                    </label>
                                    <ErrorMessage
                                      name="picked"
                                      className="validation"
                                      component="div"
                                    />
                                  </div>
                                  <label

                                  // style={values.disabled == "true" ? { display: "none" } : { display: "block" }}
                                  >
                                    Exam Date
                                    <span className="validation">
                                      {" "}
                                      Final date will be communicated by the
                                      operation team via a call.
                                    </span>
                                  </label>

                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                    <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                      <Field
                                        as="select"
                                        name="day"
                                        id="day"
                                        // onChange={handleChange}
                                        //  disabled={this.state.isDisabled}
                                        disabled
                                        onChange={(e) => {
                                          var listOfMonth = [];
                                          setFieldValue(
                                            (values.day = e.target.value)
                                          );
                                          if (e.target.value == 31) {
                                            listOfMonth = [
                                              { key: 1, value: "Jan" },

                                              { key: 3, value: "Mar" },

                                              { key: 5, value: "May" },

                                              { key: 7, value: "Jul" },
                                              { key: 8, value: "Aug" },

                                              { key: 10, value: "Oct" },

                                              { key: 12, value: "Dec" },
                                            ];
                                          } else if (e.target.value == 30) {
                                            listOfMonth = [
                                              { key: 1, value: "Jan" },

                                              { key: 3, value: "Mar" },
                                              { key: 4, value: "Apr" },
                                              { key: 5, value: "May" },
                                              { key: 6, value: "Jun" },
                                              { key: 7, value: "Jul" },
                                              { key: 8, value: "Aug" },
                                              { key: 9, value: "Sep" },
                                              { key: 10, value: "Oct" },
                                              { key: 11, value: "Nov" },
                                              { key: 12, value: "Dec" },
                                            ];
                                          } else if (
                                            e.target.value == 28 ||
                                            e.target.value == 29
                                          ) {
                                            listOfMonth = [
                                              { key: 1, value: "Jan" },
                                              { key: 2, value: "Feb" },
                                              { key: 3, value: "Mar" },
                                              { key: 4, value: "Apr" },
                                              { key: 5, value: "May" },
                                              { key: 6, value: "Jun" },
                                              { key: 7, value: "Jul" },
                                              { key: 8, value: "Aug" },
                                              { key: 9, value: "Sep" },
                                              { key: 10, value: "Oct" },
                                              { key: 11, value: "Nov" },
                                              { key: 12, value: "Dec" },
                                            ];
                                          } else {
                                            listOfMonth = [
                                              { key: 1, value: "Jan" },
                                              { key: 2, value: "Feb" },
                                              { key: 3, value: "Mar" },
                                              { key: 4, value: "Apr" },
                                              { key: 5, value: "May" },
                                              { key: 6, value: "Jun" },
                                              { key: 7, value: "Jul" },
                                              { key: 8, value: "Aug" },
                                              { key: 9, value: "Sep" },
                                              { key: 10, value: "Oct" },
                                              { key: 11, value: "Nov" },
                                              { key: 12, value: "Dec" },
                                            ];
                                          }
                                          setFieldValue(
                                            (values.listOfMonth = listOfMonth)
                                          );
                                          // this.setState({
                                          //   listOfMonth: listOfMonth
                                          // })
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.day && touched.day
                                            ? "is-invalid"
                                            : "")
                                        }
                                        className="form-control"
                                      >
                                        <option value="0">DD</option>
                                        {(values.listOfDay || []).map((d) => (
                                          <option value={d}>{d}</option>
                                        ))}
                                      </Field>
                                    </div>
                                    <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                      <Field
                                        as="select"
                                        name="month"
                                        id="month"
                                        //   onChange={handleChange}
                                        //     disabled={this.state.isDisabled}
                                        disabled
                                        onChange={(e) => {
                                          setFieldValue(
                                            (values.month = e.target.value)
                                          );
                                          if (e.target.value == 2) {
                                            var listOfDay = [];
                                            for (var i = 1; i <= 29; i++) {
                                              //    var current = year - i;
                                              listOfDay.push(i);
                                              // this.setState({ listOfDay: listOfDay });
                                            }
                                          } else if (
                                            e.target.value == 1 ||
                                            e.target.value == 3 ||
                                            e.target.value == 5 ||
                                            e.target.value == 7 ||
                                            e.target.value == 8 ||
                                            e.target.value == 10 ||
                                            e.target.value == 12
                                          ) {
                                            var listOfDay = [];
                                            for (var i = 1; i <= 31; i++) {
                                              //    var current = year - i;
                                              listOfDay.push(i);
                                              //  this.setState({ listOfDay: listOfDay });
                                            }
                                          } else {
                                            var listOfDay = [];
                                            for (var i = 1; i <= 30; i++) {
                                              //    var current = year - i;
                                              listOfDay.push(i);
                                              //  this.setState({ listOfDay: listOfDay });
                                            }
                                          }
                                          setFieldValue(
                                            (values.listOfDay = listOfDay)
                                          );
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.month && touched.month
                                            ? "is-invalid"
                                            : "")
                                        }
                                        className="form-control"
                                      >
                                        <option value="0">MM</option>
                                        {(values.listOfMonth || []).map((m) => (
                                          <option value={m.key}>
                                            {m.value}
                                          </option>
                                        ))}
                                      </Field>
                                    </div>
                                    <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                      <Field
                                        as="select"
                                        name="year"
                                        id="year"
                                        // onChange={handleChange}
                                        //    disabled={this.state.isDisabled}
                                        disabled
                                        className={
                                          "form-control" +
                                          (errors.year && touched.year
                                            ? "is-invalid"
                                            : "")
                                        }
                                        className="form-control"
                                      >
                                        <option value="0000">YYYY</option>
                                        {(this.state.listOfYear2 || []).map(
                                          (year) => (
                                            <option value={year}>{year}</option>
                                          )
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                  <p className="validation">
                                    {" "}
                                    {values.errmsg1}
                                  </p>
                                  <br />
                                  {/* <Field component={TimePicker} name="time" label="Time" /> */}
                                  <label
                                    style={
                                      values.disabled == "true"
                                        ? { display: "none" }
                                        : { display: "block" }
                                    }
                                  >
                                    Select Location
                                    <span className="validation"> *</span>
                                  </label>
                                  <span
                                    style={
                                      values.disabled == "true"
                                        ? { display: "none" }
                                        : { display: "block" }
                                    }
                                  >
                                    <Select
                                      onChange={(value) => {
                                        // let testDate = values.testDate

                                        this.onChangeParameter(
                                          value,
                                          setFieldValue
                                        );
                                        //   setFieldValue(values.testDate = testDate)
                                      }}
                                      isDisabled={
                                        values.disabled == "true"
                                          ? true
                                          : this.state.disabledAll
                                      }
                                      style={
                                        values.disabled == "true"
                                          ? { display: "none" }
                                          : { display: "block" }
                                      }
                                      id="location"
                                      name="location"
                                      options={this.state.cityList}
                                      filterOption={createFilter({
                                        matchFrom: "start",
                                      })}
                                      value={values.location}
                                    />
                                  </span>
                                  <label
                                    style={
                                      values.disabled == "true"
                                        ? { display: "none" }
                                        : { display: "block" }
                                    }
                                  >
                                    Select Center
                                    <span className="validation"> *</span>
                                  </label>
                                  <span
                                    style={
                                      values.disabled == "true"
                                        ? { display: "none" }
                                        : { display: "block" }
                                    }
                                  >
                                    <Select
                                      onChange={(value) => {
                                        this.onChangeParameterCenter(
                                          value,
                                          setFieldValue
                                        );
                                      }}
                                      isDisabled={
                                        values.disabled == "true"
                                          ? true
                                          : this.state.disabledAll
                                      }
                                      name="center"
                                      options={values.centerList}
                                      filterOption={createFilter({
                                        matchFrom: "start",
                                      })}
                                      value={values.center}
                                    />
                                  </span>

                                  <ErrorMessage
                                    name="center"
                                    className="validation"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="col-md-12 col-sm-12 col-xs-12">
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  name="termsandconditions"
                                  // checked={

                                  //     values.termsandcondition =
                                  //     true ? true : false

                                  // }
                                  disabled={this.state.disabledAll}
                                  onClick={(e) => {
                                    setFieldValue(
                                      (values.termsandcondition = true)
                                    );
                                  }}
                                  style={{ marginRight: "10px" }}
                                />
                                <span
                                  class="form-check-label"
                                  for="gridCheck"
                                  style={{ marginRight: "15px" }}
                                >
                                  <a href={ExamPdf} target="_blank">
                                    Terms and Conditions
                                    <span className="validation"> *</span>{" "}
                                    {this.state.termsCondtions === true
                                      ? "(Accepted)"
                                      : ""}
                                  </a>
                                </span>
                              </div>
                            </div>
                            <br />
                            {/* {values.picked === "online" && values.day!=undefined && values.month!=undefined&& values.year!=undefined ? < div className="mb-20 text-center"> */}
                            {values.picked === "online" ? (
                              <div className="mb-20 text-center">
                                <button type="submit" className="btn-5 mr-20">
                                  Submit
                                </button>
                                {/* </div> : values.picked !== "" && values.day!=undefined && values.month!=undefined && values.year!=undefined && values.location !== "" && values.center !== "" && values.termsandcondition !== false ? */}
                              </div>
                            ) : values.picked !== "" &&
                              values.location !== "" &&
                              values.center !== "" &&
                              values.termsandcondition !== false ? (
                              <div className="mb-20 text-center">
                                <button type="submit" className="btn-5 mr-20">
                                  Submit
                                </button>
                              </div>
                            ) : values.termsandcondition !== false ? (
                              <div className="mb-20 text-center">
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    warning(
                                      "Please fill all mandatory fields",
                                      warningNotification
                                    );
                                  }}
                                  className="btn-5 mr-20"
                                >
                                  Submit
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </section>
                    </div>
                  </Form>
                </div>
              </div>
            </React.Fragment>
          )}
        />

        <Formik
          enableReinitialize={true}
          initialValues={{
            companyId: this.state.companyId,
            companyNameOther: this.state.companyNameOther,
            termsandcondition: "",
          }}
          validationSchema={Yup.object().shape({
            // companyId: Yup.string()
            //     .required('You must select a company'),
          })}
          onSubmit={(fields) => {
            debugger;
            this.setState({
              loading1: "block",
            });

            if (
              this.state.termsandconditionmandate === false ||
              this.state.termsandconditionmandate === undefined
            ) {
              error("Please select Terms and Conditions", errorNotification);
              this.setState({
                loading1: "none",
              });
            } else if (
              (this.state.learnerEuinOption === "" ||
                this.state.learnerEuinOption === undefined) &&
              this.state.mandateName !== "NISM"
            ) {
              error("Please Select preferred EUIN option", errorNotification);
              this.setState({
                loading1: "none",
              });
            } else {
              if (
                this.state.companyId === null ||
                this.state.companyId === "" ||
                this.state.companyId === undefined
              ) {
                // if (fields.companyId === null || fields.companyId === "" || fields.companyId === undefined) {
                error("Please select your organization", errorNotification);
                this.setState({
                  loading1: "none",
                });
              } else {
                var data1 = localStorage.getItem("PersonnelData");
                let data = {
                  mandateId: this.state.mandateId,
                  relatedTo: this.state.relatedTo,
                  learnerID: JSON.parse(data1).responseObject.learnerID,
                  learnerPartyID: JSON.parse(data1).responseObject
                    .learnerPartyID,
                  mandateUserId: this.state.mandateUserId,
                  companyName: this.state.companyId,
                  otherCompanyName: this.state.otherCompId,
                  componentList: this.state.productNames,
                  isTearmsAccepted: "1",
                  learnerEuinOption:
                    this.state.learnerEuinOption === "NISM"
                      ? null
                      : this.state.learnerEuinOption,
                };
                localStorage.setItem("learnerEuinOption1",this.state.learnerEuinOption === "NISM" ? "" : this.state.learnerEuinOption)
                this.setState({ mandateProceedBtnDisabled: true });
                RegionManagementService.saveMandateDetails(data, (response) => {
                  // alert(response.status)
                  if (response.status === "success") {
                    localStorage.setItem("UpdateUser","Yes")

                    this.handleProfilePercentageUpdate()
                    let saveRcpLearnerAssessmentData = {
                      learnerID: JSON.parse(data1).responseObject.learnerID,
                      learnerPartyID: JSON.parse(data1).responseObject
                        .learnerPartyID,
                      mandateName: this.state.mandateName,
                      component1: "",
                      component2: "",
                      component3: "",
                      component4: "",
                      componentList: this.state.productNames,
                    };
                    console.log(
                      "saveRcpLearnerAssessmentData::",
                      saveRcpLearnerAssessmentData
                    );
                    RegionManagementService.saveRcpLearnerAssessment(
                      saveRcpLearnerAssessmentData,
                      (response1) => {
                        if(response1.status==="success"){
                      
                        // }
                        var data2 = localStorage.getItem("PersonnelData");
                        let data22 = [
                          {
                            userName: JSON.parse(data2).responseObject
                              .firstName,
                            emailId: JSON.parse(data2).responseObject.emailID,
                            templateId: "70220",
                            mobileNumber: JSON.parse(data2).responseObject
                              .mobileNo,
                            status: "completed the first step in your mandate",
                            partyId: JSON.parse(data2).responseObject
                              .learnerPartyID,
                          },
                        ];
                        console.log("data :::", data22);
                        // RegisterUserService.sendSuccessMandateMail(
                        //   data22,
                        //   (res3) => {}
                        // );

                        this.setState({
                          loading1: "none",
                        });

                        setTimeout(() => {
                          this.setState({ loading: false });
                          window.$("#MandateModal").modal("hide");
                          localStorage.setItem("MandateModalPopup", "hide");
                          localStorage.setItem("MandateModalSubmitted","Yes")
                          document.getElementById("myCheck").click();
                          // var txt = "Congratulations on joining the RCP platform. Please fill the mandatory fields of the profile to proceed with your journey"
                          // var r = window.confirm(txt);
                          // if (r == true) {
                          //    // this.userprofile();
                          // }
                        }, 1000);
                      }else{
                        this.setState({ mandateProceedBtnDisabled: false });
                        warning(response1.reasonText,warningNotification);    
                      }
                      }
                    );
                  }else{
                    this.setState({ mandateProceedBtnDisabled: false });
                    warning(response.reasonText,warningNotification);
                  }
                });
              }
            }
          }}
          render={({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            values,
          }) => (
            <React.Fragment>
              <div
                class="modal fade"
                id="MandateModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg" role="document">
                  <Form className="form_name">
                    <div className="modal-content">
                      {/* <button
                                                type="button"
                                                class="close"
                                                aria-label="Close"
                                                onClick={
                                                    e => {
                                                        window.$('#MandateModal').modal('hide')
                                                    }
                                                }
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button> */}

                      <section className="registration">
                        <div className="modal-body">
                          <div classNames="container">
                            <div
                              className="modal-head"
                              style={{
                                padding: "9px 8px",
                              }}
                            >
                              <div
                                className="section-title-black"
                                style={{ textAlign: "center" }}
                              >
                                <h3 style={{ color: "black" }}>
                                  Mandate Check Pop-up
                                </h3>
                              </div>

                              {this.state.popupErrorHideShow == true ? (
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                  <p
                                    style={{ color: "red", textAlign: "left" }}
                                  >
                                    You have not been nominated by your
                                    organization for this mandate. Contact your
                                    Business/HR team for further assistance.
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}

                              {this.state.assignedUserMandateName == "EUIN" ? (
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                  <p
                                    style={{ color: "red", textAlign: "left" }}
                                  >
                                    Your Organization has nominated you for the
                                    mandate selected. CIEL will help and support
                                    you with EUIN generation provided you have
                                    completed NISM certification.{" "}
                                  </p>
                                </div>
                              ) : this.state.assignedUserMandateName ==
                                "NISM" ? (
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                  <p
                                    style={{ color: "red", textAlign: "left" }}
                                  >
                                    Your Organization has nominated you for the
                                    mandate selected. CIEL will help and support
                                    you with NISM Registration and exam
                                    enrollment.{" "}
                                  </p>
                                </div>
                              ) : this.state.assignedUserMandateName ==
                                "NISM and EUIN" ? (
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                  <p
                                    style={{ color: "red", textAlign: "left" }}
                                  >
                                    Your Organization has nominated you for the
                                    mandate selected. CIEL will help and support
                                    you with NISM Registration, exam enrollment
                                    and EUIN generation post clearing of NISM VA
                                    exam{" "}
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="row">
                              <div
                                id="loading-bar-spinner"
                                class="spinner"
                                style={{ display: this.state.loading1 }}
                              >
                                <div class="spinner-icon"></div>
                              </div>
                              <div className="col-md-12 col-sm-12 col-xs-12 faq">
                                <Accordion allowZeroExpanded>
                                  <AccordionItem>
                                    <AccordionItemHeading>
                                      <AccordionItemButton>
                                        1. My organisation
                                        <span className="mandateIcon">
                                          {" "}
                                          {this.state.companyNameOther == "" ? (
                                            ""
                                          ) : this.state.popupErrorHideShow ==
                                            false ? (
                                            <i
                                              class="fa fa-check-circle green"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              class="fa fa-times-circle red"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </span>
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      <Form>
                                        <div className="form-group">
                                          <div className="row">
                                            <div className="col-md-6 col-sm-6 col-xs-12">
                                              <label>
                                                Company Name
                                                <span className="validation">
                                                  {" "}
                                                  *
                                                </span>
                                              </label>
                                              <Field
                                                type="text"
                                                name="companyNameOther"
                                                disabled
                                                id="companyNameOther"
                                                className={"form-control"}
                                                placeholder=""
                                              />
                                              <Field
                                                type="hidden"
                                                name="companyId"
                                                disabled
                                                id="companyId"
                                                className={"form-control"}
                                                placeholder=""
                                              />
                                              {/* <Select
                                                                                                onChange={(value) => this.onChangeCompanyParameter(value, setFieldValue)}
                                                                                                name="companyId"
                                                                                                id="companyId"
                                                                                                onClick={e => {
                                                                                                    setFieldValue(
                                                                                                        (values.company =
                                                                                                            e.target.value)
                                                                                                    );
                                                                                                }}
                                                                                                options={this.state.companyList}
                                                                                                filterOption={createFilter({
                                                                                                    matchFrom: 'start'
                                                                                                })}
                                                                                                value={values.company}
                                                                                            />
                                                                                            <ErrorMessage
                                                                                                name="companyId"
                                                                                                component="div"
                                                                                                className="validation"
                                                                                            /> */}
                                            </div>
                                          </div>
                                        </div>
                                      </Form>
                                    </AccordionItemPanel>
                                  </AccordionItem>

                                  {this.state.popupHideShow == true ? (
                                    <AccordionItem>
                                      <AccordionItemHeading>
                                        <AccordionItemButton>
                                          2. Your assigned mandate
                                          <span className="mandateIcon">
                                            {" "}
                                            {this.state.companyNameOther ==
                                            "" ? (
                                              ""
                                            ) : (
                                              <i
                                                class="fa fa-check-circle green"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </span>
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel>
                                        <div className="row MandateTable">
                                          <div className="col-md-3 col-sm-3 col-xs-12">
                                            <p className="MandateLabel">
                                              Company Name:
                                            </p>
                                          </div>
                                          <div className="col-md-3 col-sm-3 col-xs-12">
                                            <p className="MandateData">
                                              {this.state.companyName}
                                            </p>
                                          </div>
                                          <div className="col-md-3 col-sm-3 col-xs-12">
                                            <p className="MandateLabel">
                                              Mandate Name:
                                            </p>
                                          </div>
                                          <div className="col-md-3 col-sm-3 col-xs-12">
                                            <p className="MandateData">
                                              {this.state.mandateName === "NISM"
                                                ? "NISM Exam Registration & Training"
                                                : this.state.mandateName ===
                                                  "EUIN"
                                                ? "EUIN Processing"
                                                : this.state.mandateName ===
                                                  "NISM and EUIN"
                                                ? "NISM Registration and EUIN Processing"
                                                : ""}
                                            </p>
                                          </div>
                                        </div>
                                      </AccordionItemPanel>
                                    </AccordionItem>
                                  ) : (
                                    <AccordionItem>
                                      <AccordionItemHeading>
                                        <AccordionItemButton>
                                          2. Your assigned mandate
                                          <span className="mandateIcon">
                                            {this.state.companyNameOther ==
                                            "" ? (
                                              ""
                                            ) : (
                                              <i
                                                class="fa fa-times-circle red"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </span>
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel
                                        style={{ display: "none" }}
                                      >
                                        <div className="row MandateTable">
                                          <div className="col-md-3 col-sm-3 col-xs-12">
                                            <p className="MandateLabel">
                                              Company Name:
                                            </p>
                                          </div>
                                          <div className="col-md-3 col-sm-3 col-xs-12">
                                            <p className="MandateData">
                                              {this.state.companyName}
                                            </p>
                                          </div>
                                          <div className="col-md-3 col-sm-3 col-xs-12">
                                            <p className="MandateLabel">
                                              Mandate Name:
                                            </p>
                                          </div>
                                          <div className="col-md-3 col-sm-3 col-xs-12">
                                            <p className="MandateData">
                                              {this.state.mandateName === "NISM"
                                                ? "NISM Exam Registration & Training"
                                                : this.state.mandateName ===
                                                  "EUIN"
                                                ? "EUIN Processing"
                                                : this.state.mandateName ===
                                                  "NISM and EUIN"
                                                ? "NISM Registration and EUIN Processing"
                                                : ""}
                                            </p>
                                          </div>
                                        </div>
                                      </AccordionItemPanel>
                                    </AccordionItem>
                                  )}
                                  {this.state.popupHideShow == true ? (
                                    <AccordionItem>
                                      <AccordionItemHeading>
                                        <AccordionItemButton>
                                          3. Components of the mandate
                                          <span className="mandateIcon">
                                            {this.state.companyNameOther ==
                                            "" ? (
                                              ""
                                            ) : (
                                              <i
                                                class="fa fa-check-circle green"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </span>
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel>
                                        <div className="row MandateTable">
                                          {/* {this.state.productNames.map(function (productName, index) {
                                                                                    return <li key={index}>{productName}</li>;
                                                                                })} */}
                                          {/* 
                                          {this.state.productNames.map( */}
                                          {this.state.productThumbnailList.map(
                                            function (productName, index) {
                                              return (
                                                <div className="col-md-3 col-sm-3 col-xs-12">
                                                  <p
                                                    className="MandateLabel"
                                                    key={index}
                                                  >
                                                    {productName}
                                                  </p>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </AccordionItemPanel>
                                    </AccordionItem>
                                  ) : (
                                    <AccordionItem>
                                      <AccordionItemHeading>
                                        <AccordionItemButton>
                                          3. Components of the mandate
                                          <span className="mandateIcon">
                                            {this.state.companyNameOther ==
                                            "" ? (
                                              ""
                                            ) : (
                                              <i
                                                class="fa fa-times-circle red"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </span>
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel
                                        style={{ display: "none" }}
                                      >
                                        <div className="row MandateTable">
                                          {/* {this.state.productNames.map(function (productName, index) {
                                                                                    return <li key={index}>{productName}</li>;
                                                                                })} */}
                                          {/* {this.state.productNames.map( */}
                                          {this.state.productThumbnailList.map(
                                            function (productName, index) {
                                              return (
                                                <div className="col-md-3 col-sm-3 col-xs-12">
                                                  <p
                                                    className="MandateLabel"
                                                    key={index}
                                                  >
                                                    {productName}
                                                  </p>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </AccordionItemPanel>
                                    </AccordionItem>
                                  )}
                                </Accordion>
                                {this.state.mandateName === "NISM" ? (
                                  ""
                                ) : (
                                  <div className="col-md-12 col-sm-12 col-xs-12">
                                    <label>
                                      Learner Options{" "}
                                      <span className="validation"> *</span>
                                    </label>
                                    <Field
                                      as="select"
                                      name="learnerEuinOption"
                                      id="learnerEuinOption"
                                      autoComplete="learnerEuinOption"
                                      onChange={async (e) => {
                                        await this.setState({
                                          learnerEuinOption: e.target.value,
                                        });
                                        console.log(
                                          this.state.learnerEuinOption
                                        );
                                      }}
                                      className={
                                        "form-control" +
                                        (errors.learnerEuinOption &&
                                        touched.learnerEuinOption
                                          ? " is-invalid"
                                          : "")
                                      }
                                      // placeholder="Email ID"
                                    >
                                      <option value="">
                                        -----------Please Select------------
                                      </option>
                                      {this.state.mandateName ===
                                        "NISM and EUIN" && (
                                        <option value="NISM">NISM</option>
                                      )}
                                      <option value="Fresh EUIN">
                                        Fresh EUIN
                                      </option>
                                      <option value="Only EUIN Renewal">
                                        Only EUIN Renewal
                                      </option>
                                      <option value="EUIN Renewal and mapping">
                                        EUIN Renewal and mapping
                                      </option>
                                      <option value="Only Mapping">
                                        Only Mapping
                                      </option>
                                    </Field>
                                  </div>
                                )}
                              </div>
                              <div className="col-md-12 col-sm-12 col-xs-12">
                                {this.state.learnerEuinOption === "NISM" && (
                                  <div className="col-md-12 col-sm-12 col-xs-12">
                                    <span
                                      style={{
                                        color: "red",
                                        textAlign: "left",
                                      }}
                                    >
                                      By selecting this process, you are wanting
                                      to appear for the NISM VA exam
                                    </span>
                                  </div>
                                )}
                                {this.state.learnerEuinOption ===
                                  "Fresh EUIN" && (
                                  <div className="col-md-12 col-sm-12 col-xs-12">
                                    <p
                                      style={{
                                        color: "red",
                                        textAlign: "left",
                                      }}
                                    >
                                      By selecting this process, you are wanting
                                      to apply for EUIN for the first time.
                                      Kindly note that to apply for Fresh EUIN
                                      you need to have a valid NISM certificate
                                    </p>
                                  </div>
                                )}

                                {this.state.learnerEuinOption ===
                                  "Only EUIN Renewal" && (
                                  <div className="col-md-12 col-sm-12 col-xs-12">
                                    <p
                                      style={{
                                        color: "red",
                                        textAlign: "left",
                                      }}
                                    >
                                      By selecting this process you are wanting
                                      to renew an existing EUIN. The EUIN should
                                      be mapped to your current organisation.
                                      You should have a valid NISM Certificate.
                                    </p>
                                  </div>
                                )}
                                {this.state.learnerEuinOption ===
                                  "EUIN Renewal and mapping" && (
                                  <div className="col-md-12 col-sm-12 col-xs-12">
                                    <p
                                      style={{
                                        color: "red",
                                        textAlign: "left",
                                      }}
                                    >
                                      By selecting this process you are wanting
                                      to transfer your EUIN from your previous
                                      organisation as well as renew it. You
                                      should have a valid NISM Certificate.
                                    </p>
                                  </div>
                                )}
                                {this.state.learnerEuinOption ===
                                  "Only Mapping" && (
                                  <div className="col-md-12 col-sm-12 col-xs-12">
                                    <p
                                      style={{
                                        color: "red",
                                        textAlign: "left",
                                      }}
                                    >
                                      By selecting this process you are wanting
                                      to transfer the EUIN from your previous
                                      organisation.
                                    </p>
                                  </div>
                                )}
                              </div>
                              <div className="col-md-12 col-sm-12 col-xs-12">
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  name="termsandconditionmandate"
                                  style={{ marginRight: "10px" }}
                                  onClick={(e) => {
                                    if (
                                      this.state.termsandconditionmandate ==
                                      false
                                    ) {
                                      this.setState({
                                        termsandconditionmandate: true,
                                      });
                                    } else {
                                      this.setState({
                                        termsandconditionmandate: false,
                                      });
                                    }
                                    // setFieldValue(
                                    //     (values.termsandconditionmandate =
                                    //         true)
                                    // );
                                  }}
                                ></Field>
                                <span
                                  class="form-check-label"
                                  for="gridCheck"
                                  style={{ marginRight: "15px" }}
                                >
                                  <a href={MandateTerms} target="_blank">
                                    Terms and Conditions
                                    <span className="validation"> *</span>
                                  </a>
                                </span>
                              </div>
                            </div>
                            <br />

                            <div className="mb-20 text-center">
                              {this.state.termsandconditionmandate == true ? (
                                <button
                                  disabled={this.state.mandateProceedBtnDisabled}
                                  type="submit"
                                  onClick={handleSubmit}
                                  className="btn-5 mr-20"
                                >
                                  Proceed
                                </button>
                              ) : (
                                ""
                              )}
                            </div>

                            {/* <div className="mb-20 text-center">
                                                            <button type="submit" onClick={handleSubmit} className="btn-5 mr-20">Proceed</button>
                                                        </div> */}
                          </div>
                        </div>
                      </section>
                    </div>
                  </Form>
                </div>
              </div>
              {this.state.loading1 && (
                <div id="loading-bar-spinner" class="spinner">
                  <div class="spinner-icon"></div>
                </div>
              )}
            </React.Fragment>
          )}
        />

        <div>
          {/* <Button colored onClick={this.handleOpenDialog} raised ripple>
          Show Dialog
        </Button> */}
          <Dialog
            open={this.state.openDialog}
            onCancel={this.handleCloseDialog}
          >
            {/* <h3>Congratulations!</h3> */}
            {/* <DialogTitle>Congratulations!</DialogTitle> */}
            <DialogContent>
              <div className="mb-20">
                <h3>Congratulations!</h3>
              </div>
              <p>
                Congratulations! To continue your journey, you must complete
                your profile with all mandatory fields.
              </p>
            </DialogContent>
            <DialogActions>
              <button
                className="btn-4"
                type="button"
                onClick={this.userprofile}
              >
                OK
              </button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Dialog
            open={this.state.openDialog1}
            onCancel={this.handleCloseDialog1}
          >
            <DialogContent>
              <div className="mb-20">
                <h3>Congratulations!</h3>
              </div>
              <p>
               To complete your Fresh EUIN Application process, scroll below and click on 'FRESH EUIN Application' tab under “My learning”. You will be directed to the CAMS website and all the fields will be prepopulated. Please complete the process on CAMS.
              </p>
            </DialogContent>
            <DialogActions>
              <button
                className="btn-4"
                type="button"
                onClick={this.handleCloseDialog1}
              >
                OK
              </button>
            </DialogActions>
          </Dialog>
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={{
            companyId: "",
            termsandconditionEuinMapping: "",
          }}
          validationSchema={Yup.object().shape({})}
          onSubmit={(fields) => {
            debugger;
            this.setState({
              loading1: "block",
            });
            let profilePercent1 =
              localStorage.getItem("profilecomlitionvalue") === undefined
                ? parseInt(0)
                : parseInt(localStorage.getItem("profilecomlitionvalue"));

            // if ((this.props.personnelData.euinNumber !== "" || this.props.personnelData.euinNumber !== undefined || this.props.personnelData.euinNumber !== null) &&
            //     (this.props.personnelData.euinValidUpto !== "" || this.props.personnelData.euinValidUpto !== undefined || this.props.personnelData.euinValidUpto !== null)
            //     && profilePercent1 >= 60 && this.props.personnelData.applicationStatus === "SUBMITTED") {
            if (
              this.props.personnelData.euinNumber !== "" &&
              this.props.personnelData.euinNumber !== undefined &&
              this.props.personnelData.euinNumber !== null &&
              this.props.personnelData.euinValidUpto !== "" &&
              this.props.personnelData.euinValidUpto !== undefined &&
              this.props.personnelData.euinValidUpto !== null &&
              profilePercent1 >= 60 &&
              this.props.personnelData.applicationStatus === "SUBMITTED"
            ) {
              error(
                "EUIN Fresh is not applicable as EUIN Number is provided",
                errorNotification
              );
              this.setState({
                loading1: "none",
              });
              setTimeout(() => {
                window.location.reload();
              }, 5000);
            } else {
              if (
                (this.props.personnelData.nismRegistrationNo === "" ||
                  this.props.personnelData.nismRegistrationNo === undefined ||
                  this.props.personnelData.nismRegistrationNo === null) &&
                (this.props.personnelData.certificateValidUpto === "" ||
                  this.props.personnelData.certificateValidUpto === undefined ||
                  this.props.personnelData.certificateValidUpto === null)
              ) {
                error(
                  'NISM Certificate and Validity is not filled. Please enter details in the "NISM Cerification Details" present in the profile page',
                  errorNotification
                );
                this.setState({
                  loading1: "none",
                });
              } else if (this.props.personnelData.certificateValidUpto !== "") {
                let certidate = this.props.personnelData.certificateValidUpto;
                //let certidate = "18/06/2026 12:00:00"
                let dateSplit = certidate.split(" ");
                let nismdate = dateSplit[0];
                let nismDate1 = nismdate.split("/");
                let nismDate2 =
                  nismDate1[2] + "-" + nismDate1[1] + "-" + nismDate1[0];
                let mydate = new Date(nismDate2);
                var today = new Date();
                var diff = mydate.getTime() - today.getTime();
                var daydiff = diff / (1000 * 60 * 60 * 24);
                if (daydiff > 1461) {
                  this.setState({
                    loading1: "none",
                  });
                  error(
                    'NISM Certificate expiry date entered is incorrect. Please enter correct details in the "NISM Cerification Details" present in the profile page',
                    errorNotification
                  );
                } else {
                  // debugger
                  let profilePercent =
                    localStorage.getItem("profilecomlitionvalue") === undefined
                      ? parseInt(0)
                      : parseInt(localStorage.getItem("profilecomlitionvalue"));
                  if (
                    profilePercent >= 60 &&
                    this.props.personnelData.applicationStatus === "SUBMITTED"
                  ) {
                    if (
                      this.state.termsandconditionEuinMapping === false ||
                      this.state.termsandconditionEuinMapping === undefined
                    ) {
                      error(
                        "Please select Terms and Conditions",
                        errorNotification
                      );
                      this.setState({
                        loading1: "none",
                      });
                    } else {
                      let productName = "productName=EUIN Application";
                      ProductService.ProductByName(productName, (res) => {
                        if (res.status === "success") {
                          var data1 = localStorage.getItem("PersonnelData");
                          let subscribedData = {
                            learnerPartyID: JSON.parse(data1).responseObject
                              .learnerPartyID,
                            attemptNumberGrade: "EUIN Number Applied",
                            isActive: "1",
                            productEntity: {
                              productID: res.responseObject.productID,
                            },
                          };
                          LearnerAssessmentService.subscribeProduct(
                            subscribedData,
                            (response) => {
                              if (response.status === "success") {
                                let acceptData = [];
                                let obj = {
                                  action: "EUIN NUMBER APPLY",
                                  workflowId: JSON.parse(data1).responseObject
                                    .learnerID,
                                  workflowName: "EUIN",
                                  comment:
                                    localStorage.getItem("loginBy") !==
                                    undefined
                                      ? localStorage.getItem("loginBy") == "Ops"
                                        ? "DONE BY OPS"
                                        : ""
                                      : "",
                                };
                                acceptData.push(obj);
                                if (acceptData.length > 0) {
                                  let workflowtemp = {
                                    ExcecutionTracker: acceptData,
                                  };
                                  updateStatus(workflowtemp, (res1) => {});
                                }
                              }
                            }
                          );
                        }
                      });

                      // As per latest discussion
                      var data1 = localStorage.getItem("PersonnelData");

                      RegionManagementService.getEncryptedString(
                        "partyID=" +
                          JSON.parse(data1).responseObject.learnerPartyID,
                        (encryptedString) => {
                          if (encryptedString.status === "success") {
                            var kyd_data = {
                              //Client_ID: "AMFI_CIEL",
                              Client_ID:
                                encryptedString.responseObject.amfi_request
                                  .client_id,
                              Client_Secret:
                                encryptedString.responseObject.amfi_request
                                  .client_secret,
                              Session_id:
                                encryptedString.responseObject.amfi_request
                                  .session_id,
                              return_url:
                                encryptedString.responseObject.amfi_request
                                  .return_url,
                              //"return_url": "https://uat.rcp.ciel.co.in/rcp-fc-admin/api/v1/amfiResponse",
                              request_data: {
                                key:
                                  encryptedString.responseObject.amfi_request
                                    .request_data.key,
                                data:
                                  encryptedString.responseObject.amfi_request
                                    .request_data.data,
                                hash:
                                  encryptedString.responseObject.amfi_request
                                    .request_data.hash,
                              },
                            };

                            this.setState({
                              kyd_data: JSON.stringify(kyd_data),
                              url:
                                encryptedString.responseObject.amfi_request
                                  .return_url,
                              session_id:
                                encryptedString.responseObject.amfi_request
                                  .session_id,
                            });

                            setTimeout(() => {
                              if (
                                this.state.kyd_data != "" &&
                                this.state.url != "" &&
                                this.state.session_id != ""
                              ) {
                                this.setState({
                                  loading1: "none",
                                });
                                document.getElementById(
                                  "proceed"
                                ).style.visibility = "hidden";
                                this.handleClick();

                                // setTimeout(() => {
                                //     window.location.reload();
                                // }, 5000)
                              } else {
                                this.setState({
                                  loading1: "none",
                                });
                                error(
                                  "We cannot process this request.",
                                  errorNotification
                                );
                              }
                            }, 3000);

                            let emaildata = [
                              {
                                userName: JSON.parse(data1).responseObject
                                  .firstName,
                                emailId: JSON.parse(data1).responseObject
                                  .emailID,
                                templateId: "70140",
                                partyId: JSON.parse(data1).responseObject
                                  .learnerPartyID,
                                status: "Applied for EUIN",
                              },
                            ];
                            RegisterUserService.userMail(
                              emaildata,
                              (emailResponse) => {}
                            );

                            let smsdata = [
                              {
                                userName: JSON.parse(data1).responseObject
                                  .firstName,
                                emailId: "",
                                templateId: "70150",
                                partyId: JSON.parse(data1).responseObject
                                  .learnerPartyID,
                                mobileNumber: JSON.parse(data1).responseObject
                                  .mobileNo,
                                status: "Applied for EUIN",
                              },
                            ];
                            RegisterUserService.userSMS(
                              smsdata,
                              (smsResponse) => {}
                            );
                          } else {
                            this.setState({
                              loading1: "none",
                            });
                            error("No data found.", errorNotification);
                            //error(encryptedString.reasonText, errorNotification)
                          }
                        }
                      );

                      //uncomment this code

                      // let productName = "productName=EUIN Mapping";
                      // ProductService.ProductByName(productName, (res) => {
                      //     if (res.status === "success") {

                      //     var data1 = localStorage.getItem("PersonnelData")
                      //     let data =
                      //     {
                      //         "productEntity": {
                      //         "productID": res.responseObject.productID
                      //         },
                      //         "learnerPartyID": JSON.parse(data1).responseObject.learnerPartyID,
                      //         "attemptNumberGrade": "Confirm Submission"
                      //     }
                      //     OrderService.confirmSubmission(data,
                      //         (response) => {
                      //             if (response.status === 'success') {
                      //                this.setState({loading1:"none" })
                      //             }
                      //         })
                      //     }
                      // });
                    }
                  } else {
                    this.setState({
                      loading1: "none",
                    });
                    warning(
                      "Please submit your profile first. Your profile score should be greater than 60% to proceed.",
                      warningNotification
                    );
                  }
                }
              }
            }
          }}
          render={({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            values,
            handleChange,
          }) => (
            <React.Fragment>
              <div
                class="modal fade"
                id="euinMappingModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg" role="document">
                  <Form className="form_name">
                    <div className="modal-content">
                      <button
                        type="button"
                        class="close"
                        aria-label="Close"
                        onClick={(e) => {
                          window.$("#euinMappingModal").modal("hide");
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>

                      <section className="registration">
                        <div className="modal-body">
                          <div classNames="container">
                            <div
                              className="modal-head"
                              style={{
                                padding: "9px 8px",
                              }}
                            >
                              <div
                                className="section-title-black"
                                style={{ textAlign: "center" }}
                              >
                                <h3 style={{ color: "black" }}>EUIN Pop-up</h3>
                              </div>
                            </div>
                            <div className="row">
                              <div
                                id="loading-bar-spinner"
                                class="spinner"
                                style={{ display: this.state.loading1 }}
                              >
                                <div class="spinner-icon"></div>
                              </div>
                              <div className="col-md-12 col-sm-12 col-xs-12">
                                <p>
                                  You will be redirected to CAMs EUIN
                                  registration page. On click of Proceed button,
                                  complete your EUIN registration process.
                                </p>
                              </div>
                              <div className="col-md-12 col-sm-12 col-xs-12">
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  name="termsandconditionEuinMapping"
                                  style={{ marginRight: "10px" }}
                                  onClick={(e) => {
                                    if (
                                      this.state.termsandconditionEuinMapping ==
                                      false
                                    ) {
                                      this.setState({
                                        termsandconditionEuinMapping: true,
                                      });
                                    } else {
                                      this.setState({
                                        termsandconditionEuinMapping: false,
                                      });
                                    }
                                  }}
                                />
                                <span
                                  class="form-check-label"
                                  for="gridCheck"
                                  style={{ marginRight: "15px" }}
                                >
                                  <a href={EuinTerms} target="_blank">
                                    Terms and Conditions
                                    <span className="validation"> *</span>
                                  </a>
                                </span>
                              </div>
                            </div>
                            <br />

                            <div className="mb-20 text-center">
                              {this.state.termsandconditionEuinMapping ==
                              true ? (
                                <button
                                  type="submit"
                                  onClick={handleSubmit}
                                  className="btn-5 mr-20"
                                  id="proceed"
                                >
                                  Proceed
                                </button>
                              ) : (
                                ""
                              )}
                            </div>

                            {/* <div className="mb-20 text-center">
                                                            <button type="submit" onClick={handleSubmit} className="btn-5 mr-20">Proceed</button>
                                                        </div> */}
                          </div>
                        </div>
                      </section>
                    </div>
                  </Form>
                </div>
              </div>
              {this.state.loading1 && (
                <div id="loading-bar-spinner" class="spinner">
                  <div class="spinner-icon"></div>
                </div>
              )}
            </React.Fragment>
          )}
        />

        {/* *************************** */}
        <Formik
          enableReinitialize={true}
          initialValues={{
            //emailId: this.props.personnelData.emailID ? this.props.personnelData.emailID : '',
            newEmail: this.state.newEmail ? this.state.newEmail : "",
            OTP: this.state.emailOtp ? this.state.emailOtp : "",
          }}
          render={({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            values,
            handleChange,
          }) => (
            <React.Fragment>
              <div
                class="modal fade"
                id="emailValidateModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg" role="document">
                  <Form className="form_name">
                    <div className="modal-content">
                      {/* <button
                                                type="button"
                                                class="close"
                                                aria-label="Close"
                                                onClick={
                                                    e => {
                                                        window.$('#emailValidateModal').modal('hide')
                                                    }
                                                }
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button> */}

                      <section className="registration">
                        <div className="modal-body">
                          <div classNames="container">
                            <div
                              className="modal-head"
                              style={{
                                padding: "9px 8px",
                              }}
                            >
                              <div
                                className="section-title-black"
                                style={{ textAlign: "center" }}
                              >
                                <h3 style={{ color: "black" }}>
                                  {" "}
                                  Email Authentication Pop-up
                                </h3>
                              </div>
                            </div>

                            <div className="modal-body">
                              <div className="">
                                <div className="">
                                  <div
                                    style={{
                                      textAlign: "center",
                                      margin: "0 auto",
                                    }}
                                  >
                                    <Form>
                                      <div className="form-group">
                                        <div className="row">
                                          <div className="col-md-6 col-sm-6 col-xs-12">
                                            <label>
                                              Email Id
                                              <span className="validation">
                                                {" "}
                                                *
                                              </span>
                                            </label>

                                            {/* <Field
                                                                                            type="text"
                                                                                            name="emailId"
                                                                                            id="emailId"
                                                                                           // value={this.state.newEmail}
                                                                                            onBlur={e => {
                                                                                                if (e.target.value !== "") {
                                                                                                    let email = e.target.value
                                                                                                    RegisterUserService.emailAuthntication("emailID=" + email, (emailvalidateresponse) => {
                                                                                                        if (emailvalidateresponse.status === "success") {
                                                                                                            RegisterUserService.validateFalsePositiveEmail("emailID=" + email, (positiveresponse) => {
                                                                                                                if (positiveresponse.status === "fail") {
                                                                                                                    error(positiveresponse.reasonText, {
                                                                                                                        ...errorNotification
                                                                                                                    });
                                                                                                                    this.setState({
                                                                                                                        isEmailValid: false
                                                                                                                    });
                                                                                                                    setFieldValue (
                                                                                                                        (values.emailId = email)
                                                                                                                    );
                                                                                                                }
                                                                                                                else {
                                                                                                                    this.setState({
                                                                                                                        isEmailValid: true
                                                                                                                    });
                                                                                                                }
                                                                                                            })
                                                                                                        } else {
                                                                                                            error(emailvalidateresponse.reasonText, {
                                                                                                                ...errorNotification
                                                                                                            });
                                                                                                            this.setState({
                                                                                                                isEmailValid: true
                                                                                                            });
                                                                                                        }
                                                                                                    })
                                                                                                    this.setState({
                                                                                                        newEmail: email
                                                                                                    });
                                                                                                } else {
                                                                                                    this.setState({
                                                                                                        newEmail: ""
                                                                                                    });
                                                                                                }


                                                                                            }}
                                                                                            validate={this.validateEmail}
                                                                                            className={"form-control"}
                                                                                            placeholder=''
                                                                                        /> */}
                                            <Field
                                              type="text"
                                              name="newEmail"
                                              id="newEmail"
                                              // value={this.state.newEmail}
                                              onBlur={(e) => {
                                                if (e.target.value !== "") {
                                                  let email = e.target.value;
                                                  RegisterUserService.emailAuthntication(
                                                    "emailID=" + email,
                                                    (emailvalidateresponse) => {
                                                      if (
                                                        emailvalidateresponse.status ===
                                                        "success"
                                                      ) {
                                                        RegisterUserService.validateFalsePositiveEmail(
                                                          "emailID=" + email,
                                                          (
                                                            positiveresponse
                                                          ) => {
                                                            if (
                                                              positiveresponse.status ===
                                                              "fail"
                                                            ) {
                                                              error(
                                                                positiveresponse.reasonText,
                                                                {
                                                                  ...errorNotification,
                                                                }
                                                              );
                                                              this.setState({
                                                                isEmailValid: false,
                                                              });
                                                              setFieldValue(
                                                                (values.emailId = email)
                                                              );
                                                            } else {
                                                              this.setState({
                                                                isEmailValid: true,
                                                              });
                                                            }
                                                          }
                                                        );
                                                      } else {
                                                        error(
                                                          emailvalidateresponse.reasonText,
                                                          {
                                                            ...errorNotification,
                                                          }
                                                        );
                                                        this.setState({
                                                          isEmailValid: true,
                                                        });
                                                      }
                                                    }
                                                  );
                                                  this.setState({
                                                    newEmail: email,
                                                  });
                                                } else {
                                                  this.setState({
                                                    newEmail: "",
                                                  });
                                                }
                                              }}
                                              validate={this.validateEmail}
                                              className={"form-control"}
                                              placeholder=""
                                            />
                                          </div>
                                          {/* {this.state.isEmailValid ? true ( */}
                                          <div className="col-md-6 col-sm-6 col-xs-12">
                                            <button
                                              type="button"
                                              className="btn-1 mt-40"
                                              onClick={this.generateOTPMail}
                                              value="Send Email OTP"
                                            >
                                              Send Email OTP{" "}
                                            </button>
                                          </div>
                                          {/* ) :
                                                                                    ""
                                                                                } */}
                                        </div>

                                        {this.state.submitClicked === true ? (
                                          <div className="row">
                                            <div className="col-md-6 col-sm-6 col-xs-12">
                                              <label>
                                                Enter OTP
                                                <span className="validation">
                                                  {" "}
                                                  *
                                                </span>
                                              </label>

                                              <Field
                                                type="text"
                                                name="OTP"
                                                id="OTP"
                                                maxlength="6"
                                                className={
                                                  "form-control enter-otp"
                                                }
                                                placeholder=""
                                                style={{ width: "100%" }}
                                                autocomplete="off"
                                                onBlur={(e) => {
                                                  if (e.target.value !== "") {
                                                    let otp = e.target.value;
                                                    this.setState({
                                                      emailOtp: otp,
                                                    });
                                                  }
                                                }}
                                              />
                                            </div>

                                            <div className="col-md-6 col-sm-6 col-xs-12">
                                              <button
                                                type="button"
                                                className="btn-1 mt-40"
                                                onClick={this.validateOTPMail}
                                                value="Generate OTP"
                                                disabled={this.state.submitOtpEmailDisabled}
                                              >
                                                Submit{" "}
                                              </button>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        {this.state.submitClicked === true ? (
                                          <div className="row">
                                            <div className="otp-resend">
                                              <a
                                                onClick={(e) => {
                                                  this.generateOTPMail();
                                                  this.setState({
                                                    emailOtp: "",
                                                  });
                                                }}
                                              >
                                                Resend Email OTP
                                              </a>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </Form>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <br />
                          </div>
                        </div>
                      </section>
                    </div>
                  </Form>
                </div>
              </div>
            </React.Fragment>
          )}
        />

        {/* ***************** */}
        <Formik
          enableReinitialize={true}
          initialValues={{
            newEmail: this.state.newEmail ? this.state.newEmail : "",
          }}
          render={({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            values,
            handleChange,
          }) => (
            <React.Fragment>
              <div
                class="modal fade"
                id="makePaymentModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-backdrop="static"
                data-keyboard="false"
              >
                <div className="modal-dialog modal-lg" role="document">
                  <Form className="form_name">
                    <div className="modal-content">
                      {/* <button
                        type="button"
                        class="close"
                        aria-label="Close"
                        onClick={(e) => {
                          window.$("#makePaymentModal").modal("hide");
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button> */}

                      <section className="registration">
                        <div className="modal-body">
                          <div classNames="container">
                            <div
                              className="modal-head"
                              style={{
                                padding: "9px 8px",
                              }}
                            >
                              <div
                                className="section-title-black"
                                style={{ textAlign: "center" }}
                              >
                                <h3 style={{ color: "black" }}>
                                  {" "}
                                  Make Payment
                                </h3>
                              </div>
                            </div>

                            <div className="modal-body">
                              <div className="">
                                <div className="">
                                  <div
                                    style={{
                                      textAlign: "center",
                                      margin: "0 auto",
                                    }}
                                  >
                                    <Form>
                                      <div className="form-group">
                                        <div className="row">
                                          <div className="col-md-12 col-sm-12 col-xs-12">
                                            <label>
                                              <p>
                                                Once you have completed your
                                                profile and submitted the same,
                                                you need to make a payment
                                                towards NISM VA Exam fees to
                                                complete registration.
                                              </p>
                                            </label>
                                          </div>
                                          <div className="col-md-12 col-sm-12 col-xs-12">
                                            <button
                                              type="button"
                                              className="btn-1 mt-40"
                                              onClick={
                                                this.redirectToPaymentProducts
                                              }
                                              value="Make Payment"
                                            >
                                              Make Payment{" "}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </Form>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <br />
                          </div>
                        </div>
                      </section>
                    </div>
                  </Form>
                </div>
              </div>
            </React.Fragment>
          )}
        />

        {/* ************ */}

        <Formik
          enableReinitialize={true}
          initialValues={{
            // arnName: this.state.arnName ? this.state.arnName : "",
            // arnNumber: this.state.arnNumber ? this.state.arnName : "",
            // euinName: this.state.euinName ? this.state.euinName : "",
            euinNumber: this.state.euinNumberGen
              ? this.state.euinNumberGen
              : "",
            // id: this.state.id ? this.state.id : "",
            panNumber: this.state.panNumberGen ? this.state.panNumberGen : "",
            enrollmentNo: this.state.enrollmentNo
              ? this.state.enrollmentNo
              : "",
            // registeredType: this.state.registeredType
            //   ? this.state.registeredType
            //   : "",
            // Kyc_type: this.state.Kyc_type ? this.state.arnName : "",
            // status: this.state.status ? this.state.status : "",
            // registrationDate: this.state.registrationDate
            //   ? this.state.registrationDate
            //   : "",
          }}
          render={({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            values,
            handleChange,
          }) => (
            <React.Fragment>
              <div
                class="modal fade"
                id="euinNumberGeneratedModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-sm" role="document">
                  <Form className="form_name">
                    <div className="modal-content">
                      <button
                        type="button"
                        class="close"
                        aria-label="Close"
                        onClick={(e) => {
                          window.$("#euinNumberGeneratedModal").modal("hide");
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>

                      <section className="registration">
                        <div className="modal-body">
                          <div classNames="container">
                            <div
                              className="modal-head"
                              style={{
                                padding: "9px 8px",
                              }}
                            >
                              <div
                                className="section-title-black"
                                style={{ textAlign: "center" }}
                              >
                                <h3 style={{ color: "black" }}>
                                  EUIN Number Generated
                                </h3>
                              </div>
                            </div>

                            <div className="modal-body">
                              <div className="">
                                <div className="">
                                  <div
                                    style={{
                                      textAlign: "center",
                                      margin: "0 auto",
                                    }}
                                  >
                                    <table style={{ textAlign: "center" }}>
                                      {/* <tr>
                                                                                <td><b>ID </b></td>
                                                                                <td>:</td>
                                                                                <td>{this.state.id}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><b>ARN Number </b></td>
                                                                                <td>:</td>
                                                                                <td>{this.state.arnNumber}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><b>ARN Name </b></td>
                                                                                <td>:</td>
                                                                                <td>{this.state.arnName}</td>
                                                                            </tr> */}
                                      <tr>
                                        <td>
                                          <b>PAN Number </b>
                                        </td>
                                        <td>:</td>
                                        <td>{this.state.panNumberGen}</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <b>EUIN Number </b>
                                        </td>
                                        <td>:</td>
                                        <td>{this.state.euinNumberGen}</td>
                                      </tr>
                                      {/* <tr>
                                                                                <td><b> EUIN Name </b></td>
                                                                                <td>:</td>
                                                                                <td>{this.state.euinName}</td>
                                                                            </tr> 
                                                                            <tr>
                                                                                <td><b>Registered Type </b></td>
                                                                                <td>:</td>
                                                                                <td>{this.state.registeredType}</td>
                                                                            </tr> */}
                                      <tr>
                                        <td>
                                          <b>EUIN Registration Date </b>
                                        </td>
                                        <td>:</td>
                                        <td>{this.state.registrationDate}</td>
                                      </tr>
                                      {/* <tr>
                                                                                <td><b>Status </b></td>
                                                                                <td>:</td>
                                                                                <td>{this.state.status}</td>
                                                                            </tr> */}
                                      {/* <tr>
                                        <td>
                                          <b>NISM Certificate Number </b>
                                        </td>
                                        <td>:</td>
                                        <td>
                                          {
                                            this.props.personnelData
                                              .nismRegistrationNo
                                          }
                                        </td>
                                      </tr> */}
                                      <tr>
                                        <td>
                                          <b>Enrollment Number </b>
                                        </td>
                                        <td>:</td>
                                        <td>{this.state.enrollmentNo}</td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="mb-20 text-center">
                                <button
                                  type="button"
                                  className="btn-5 mr-20"
                                  onClick={(e) => {
                                    window
                                      .$("#euinNumberGeneratedModal")
                                      .modal("hide");
                                  }}
                                  value="Close"
                                >
                                  OK
                                </button>
                              </div>
                            </div>
                            <br />
                          </div>
                        </div>
                      </section>
                    </div>
                  </Form>
                </div>
              </div>
            </React.Fragment>
          )}
        />

        <Formik
          enableReinitialize={true}
          initialValues={{
            renewalarnName: this.state.arnName ? this.state.arnName : "",
            renewalarnNumber: this.state.arnNumber ? this.state.arnName : "",
            renewaleuinName: this.state.euinName ? this.state.euinName : "",
            renewaleuinNumber: this.state.euinNumber
              ? this.state.euinNumber
              : "",
            renewalid: this.state.id ? this.state.id : "",
            renewalpanNumber: this.state.panNumber ? this.state.panNumber : "",
            renewalregisteredType: this.state.registeredType
              ? this.state.registeredType
              : "",
            renewalKyc_type: this.state.Kyc_type ? this.state.arnName : "",
            renewalstatus: this.state.status ? this.state.status : "",
            renewalregistrationDate: this.state.registrationDate
              ? this.state.registrationDate
              : "",
          }}
          render={({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            values,
            handleChange,
          }) => (
            <React.Fragment>
              <div
                class="modal fade"
                id="euinNumberRenewalGeneratedModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-sm" role="document">
                  <Form className="form_name">
                    <div className="modal-content">
                      <button
                        type="button"
                        class="close"
                        aria-label="Close"
                        onClick={(e) => {
                          window
                            .$("#euinNumberRenewalGeneratedModal")
                            .modal("hide");
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>

                      <section className="registration">
                        <div className="modal-body">
                          <div classNames="container">
                            <div
                              className="modal-head"
                              style={{
                                padding: "9px 8px",
                              }}
                            >
                              <div
                                className="section-title-black"
                                style={{ textAlign: "center" }}
                              >
                                <h3 style={{ color: "black" }}>
                                  EUIN Number Renewed
                                </h3>
                              </div>
                            </div>

                            <div className="modal-body">
                              <div className="">
                                <div className="">
                                  <div
                                    style={{
                                      textAlign: "center",
                                      margin: "0 auto",
                                    }}
                                  >
                                    <table style={{ textAlign: "center" }}>
                                      {/* <tr>
                                                                                <td><b>ID </b></td>
                                                                                <td>:</td>
                                                                                <td>{this.state.id}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><b>ARN Number </b></td>
                                                                                <td>:</td>
                                                                                <td>{this.state.arnNumber}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><b>ARN Name </b></td>
                                                                                <td>:</td>
                                                                                <td>{this.state.arnName}</td>
                                                                            </tr> */}
                                      <tr>
                                        <td>
                                          <b>PAN Number </b>
                                        </td>
                                        <td>:</td>
                                        <td>{this.state.renewalpanNumber}</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <b>EUIN Number </b>
                                        </td>
                                        <td>:</td>
                                        <td>{this.state.renewaleuinNumber}</td>
                                      </tr>
                                      {/* <tr>
                                                                                <td><b> EUIN Name </b></td>
                                                                                <td>:</td>
                                                                                <td>{this.state.euinName}</td>
                                                                            </tr> 
                                                                            <tr>
                                                                                <td><b>Registered Type </b></td>
                                                                                <td>:</td>
                                                                                <td>{this.state.registeredType}</td>
                                                                            </tr> */}
                                      <tr>
                                        <td>
                                          <b>EUIN Registration Date </b>
                                        </td>
                                        <td>:</td>
                                        <td>
                                          {this.state.renewalregistrationDate}
                                        </td>
                                      </tr>
                                      {/* <tr>
                                                                                <td><b>Status </b></td>
                                                                                <td>:</td>
                                                                                <td>{this.state.status}</td>
                                                                            </tr> */}
                                      <tr>
                                        <td>
                                          <b>NISM Certificate Number </b>
                                        </td>
                                        <td>:</td>
                                        <td>
                                          {
                                            this.props.personnelData
                                              .renewalcertificateNumber
                                          }
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="mb-20 text-center">
                                <button
                                  type="button"
                                  className="btn-5 mr-20"
                                  onClick={(e) => {
                                    window
                                      .$("#euinNumberRenewalGeneratedModal")
                                      .modal("hide");
                                  }}
                                  value="Close"
                                >
                                  OK
                                </button>
                              </div>
                            </div>
                            <br />
                          </div>
                        </div>
                      </section>
                    </div>
                  </Form>
                </div>
              </div>
            </React.Fragment>
          )}
        />

        {/***************************** */}

        <Formik
          enableReinitialize={true}
          initialValues={{
            euinNumber: "",
          }}
          validationSchema={Yup.object().shape({
            euinNumber: Yup.string()
              .matches(
                /^[a-zA-Z0-9-]{4,20}$/,
                "Please enter the valid EUIN Number"
              )
              .required("You must specify EUIN Number"),
          })}
          onSubmit={(fields) => {
            //    debugger;
            let profilePercent =
              localStorage.getItem("profilecomlitionvalue") === undefined
                ? parseInt(0)
                : parseInt(localStorage.getItem("profilecomlitionvalue"));
            if (
              profilePercent >= 60 &&
              this.props.personnelData.applicationStatus === "SUBMITTED"
            ) {
              // if (
              //   fields["euinyear"] === undefined ||
              //   fields["euinyear"] === "" ||
              //   fields["euinmonth"] === undefined ||
              //   fields["euinmonth"] === "" ||
              //   fields["euinday"] === undefined ||
              //   fields["euinday"] === ""
              // ) {
              //   warning(
              //     "If you entered a EUIN Number, you must also submit a EUIN Validity Date",
              //     warningNotification
              //   );
              // } else {
              let euinDt =
                fields["euinday"] +
                "/" +
                fields["euinmonth"] +
                "/" +
                fields["euinyear"] +
                " " +
                "00:00:00";

              this.setState({ loading1: "block" });
              if (
                fields.termsandconditions === false ||
                fields.termsandconditions === undefined
              ) {
                this.setState({
                  loading1: "none",
                });
                warning(
                  "Please select Terms and Conditions",
                  warningNotification
                );
              } else if (
                fields["euinyear"] !== undefined ||
                fields["euinyear"] !== "" ||
                fields["euinmonth"] !== undefined ||
                fields["euinmonth"] !== "" ||
                fields["euinday"] !== undefined ||
                fields["euinday"] !== ""
              ) {
                let euinDate2 =
                  fields["euinyear"] +
                  "-" +
                  fields["euinmonth"] +
                  "-" +
                  fields["euinday"];
                let mydate = new Date(euinDate2);
                var today = new Date();
                var diff = mydate.getTime() - today.getTime();
                var daydiff = diff / (1000 * 60 * 60 * 24);
                // if (daydiff > 1095.75) {
                //   this.setState({
                //     loading1: "none",
                //   });
                //   error(
                //     "EUIN Validity Expiry Date entered is incorrect. Please enter correct details.",
                //     errorNotification
                //   );
                // } else
                // if (daydiff > 365) {
                //   this.setState({
                //     loading1: "none",
                //   });
                //   error(
                //     "Your EUIN is still active and not eligible for renewal.",
                //     errorNotification
                //   );
                // } else {
                this.setState({ loading1: "block" });

                let productName = "productName=EUIN Renewal";
                ProductService.ProductByName(productName, (res) => {
                  if (res.status === "success") {
                    var data1 = localStorage.getItem("PersonnelData");
                    let subscribedData = {
                      learnerPartyID: JSON.parse(data1).responseObject
                        .learnerPartyID,
                      attemptNumberGrade: "EUIN Renewal Applied",
                      isActive: "1",
                      productEntity: {
                        productID: res.responseObject.productID,
                      },
                    };
                    LearnerAssessmentService.subscribeProduct(
                      subscribedData,
                      (response) => {
                        if (response.status === "success") {
                          let acceptData = [];
                          let obj = {
                            action: "EUIN RENEWAL APPLY",
                            workflowId: JSON.parse(data1).responseObject
                              .learnerID,
                            workflowName: "EUIN",
                            comment: "",
                          };
                          acceptData.push(obj);
                          if (acceptData.length > 0) {
                            let workflowtemp = {
                              ExcecutionTracker: acceptData,
                            };
                            updateStatus(workflowtemp, (res1) => {});
                          }
                        }
                      }
                    );
                  }
                });

                let data = {
                  learnerID: this.props.personnelData.learnerID,
                  learnerPartyID: this.props.personnelData.learnerPartyID,
                  euinNumber: fields.euinNumber,
                  // euinValidUpto: euinDt,
                };
                console.log("data:: " + JSON.stringify(data));

                RegisterUserService.saveEuinDetais(data, (euinResponse) => {
                  if (euinResponse.status === "success") {
                    var data1 = localStorage.getItem("PersonnelData");
                    RegionManagementService.getEncryptedString(
                      "partyID=" +
                        JSON.parse(data1).responseObject.learnerPartyID,
                      (encryptedString) => {
                        if (encryptedString.status === "success") {
                          var kyd_data = {
                            //Client_ID: "AMFI_CIEL",
                            Client_ID:
                              encryptedString.responseObject.amfi_request
                                .client_id,
                            Client_Secret:
                              encryptedString.responseObject.amfi_request
                                .client_secret,
                            Session_id:
                              encryptedString.responseObject.amfi_request
                                .session_id,
                            return_url:
                              encryptedString.responseObject.amfi_request
                                .return_url,
                            request_data: {
                              key:
                                encryptedString.responseObject.amfi_request
                                  .request_data.key,
                              data:
                                encryptedString.responseObject.amfi_request
                                  .request_data.data,
                              hash:
                                encryptedString.responseObject.amfi_request
                                  .request_data.hash,
                            },
                          };

                          this.setState({
                            kyd_data: JSON.stringify(kyd_data),
                            url:
                              encryptedString.responseObject.amfi_request
                                .return_url,
                            session_id:
                              encryptedString.responseObject.amfi_request
                                .session_id,
                          });

                          setTimeout(() => {
                            if (
                              this.state.kyd_data != "" &&
                              this.state.url != "" &&
                              this.state.session_id != ""
                            ) {
                              this.setState({
                                loading1: "none",
                              });
                              this.handleClick();
                            } else {
                              this.setState({
                                loading1: "none",
                              });
                              error(
                                "We cannot process this request.",
                                errorNotification
                              );
                            }
                          }, 3000);

                          let emaildata = [
                            {
                              userName: JSON.parse(data1).responseObject
                                .firstName,
                              emailId: JSON.parse(data1).responseObject.emailID,
                              templateId: "70240",
                              partyId: JSON.parse(data1).responseObject
                                .learnerPartyID,
                              status: "Applied for EUIN",
                            },
                          ];
                          RegisterUserService.userMail(
                            emaildata,
                            (emailResponse) => {}
                          );

                          let smsdata = [
                            {
                              userName: JSON.parse(data1).responseObject
                                .firstName,
                              emailId: "",
                              templateId: "70280",
                              partyId: JSON.parse(data1).responseObject
                                .learnerPartyID,
                              mobileNumber: JSON.parse(data1).responseObject
                                .mobileNo,
                              status: "Applied for EUIN",
                            },
                          ];
                          RegisterUserService.userSMS(
                            smsdata,
                            (smsResponse) => {}
                          );
                        } else {
                          this.setState({
                            loading1: "none",
                          });
                          error("No data found.", errorNotification);
                        }
                      }
                    );
                  }
                });
                // }
              }
              // }
            } else {
              warning(
                "Please submit your profile first. Your profile score should be greater than 60% to proceed.",
                warningNotification
              );
              setTimeout(() => {
                window.location.reload();
              }, 5000);
            }
          }}
          render={({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            values,
            handleChange,
          }) => (
            <React.Fragment>
              <div
                class="modal fade"
                id="euinRenewalModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg" role="document">
                  <Form className="form_name">
                    <div className="modal-content">
                      <button
                        type="button"
                        class="close"
                        aria-label="Close"
                        onClick={(e) => {
                          window.$("#euinRenewalModal").modal("hide");
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>

                      <section className="registration">
                        <div className="modal-body">
                          <div classNames="container">
                            <div
                              className="modal-head"
                              style={{
                                padding: "9px 8px",
                              }}
                            >
                              <div
                                className="section-title-black"
                                style={{ textAlign: "center" }}
                              >
                                <h3 style={{ color: "black" }}>
                                  {" "}
                                  EUIN Renewal{" "}
                                </h3>
                              </div>
                            </div>

                            <div className="modal-body">
                              <div className="">
                                <div className="">
                                  <div
                                    style={{
                                      textAlign: "center",
                                      margin: "0 auto",
                                    }}
                                  >
                                    <Form>
                                      <div className="form-group">
                                        <div className="row">
                                          <div className="col-md-6 col-sm-6 col-xs-12">
                                            <label>
                                              EUIN Number{" "}
                                              <span className="validation">
                                                {" "}
                                                *
                                              </span>
                                            </label>
                                            <Field
                                              type="text"
                                              name="euinNumber"
                                              id="euinNumber"
                                              maxlength="20"
                                              onChange={handleChange}
                                              className={
                                                "form-control" +
                                                (errors.euinNumber &&
                                                touched.euinNumber
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="EUIN Number"
                                            />
                                            {errors.euinNumber && (
                                              <div className="validation">
                                                {errors.euinNumber}
                                              </div>
                                            )}
                                          </div>
                                          <div
                                            className="col-md-6 col-sm-6 col-xs-12"
                                            style={{ visibility: "hidden" }}
                                          >
                                            <label>
                                              Valid Upto{" "}
                                              <span className="validation">
                                                {" "}
                                                *
                                              </span>
                                            </label>

                                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                              <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                                <Field
                                                  as="select"
                                                  name="euinday"
                                                  id="euinday"
                                                  onChange={(e) => {
                                                    var listOfMonth = [];
                                                    setFieldValue(
                                                      (values.euinday =
                                                        e.target.value)
                                                    );
                                                    if (e.target.value == 31) {
                                                      listOfMonth = [
                                                        {
                                                          key: 1,
                                                          value: "Jan",
                                                        },

                                                        {
                                                          key: 3,
                                                          value: "Mar",
                                                        },

                                                        {
                                                          key: 5,
                                                          value: "May",
                                                        },

                                                        {
                                                          key: 7,
                                                          value: "Jul",
                                                        },
                                                        {
                                                          key: 8,
                                                          value: "Aug",
                                                        },

                                                        {
                                                          key: 10,
                                                          value: "Oct",
                                                        },

                                                        {
                                                          key: 12,
                                                          value: "Dec",
                                                        },
                                                      ];
                                                    } else if (
                                                      e.target.value == 30
                                                    ) {
                                                      listOfMonth = [
                                                        {
                                                          key: 1,
                                                          value: "Jan",
                                                        },

                                                        {
                                                          key: 3,
                                                          value: "Mar",
                                                        },
                                                        {
                                                          key: 4,
                                                          value: "Apr",
                                                        },
                                                        {
                                                          key: 5,
                                                          value: "May",
                                                        },
                                                        {
                                                          key: 6,
                                                          value: "Jun",
                                                        },
                                                        {
                                                          key: 7,
                                                          value: "Jul",
                                                        },
                                                        {
                                                          key: 8,
                                                          value: "Aug",
                                                        },
                                                        {
                                                          key: 9,
                                                          value: "Sep",
                                                        },
                                                        {
                                                          key: 10,
                                                          value: "Oct",
                                                        },
                                                        {
                                                          key: 11,
                                                          value: "Nov",
                                                        },
                                                        {
                                                          key: 12,
                                                          value: "Dec",
                                                        },
                                                      ];
                                                    } else if (
                                                      e.target.value == 28 ||
                                                      e.target.value == 29
                                                    ) {
                                                      listOfMonth = [
                                                        {
                                                          key: 1,
                                                          value: "Jan",
                                                        },
                                                        {
                                                          key: 2,
                                                          value: "Feb",
                                                        },
                                                        {
                                                          key: 3,
                                                          value: "Mar",
                                                        },
                                                        {
                                                          key: 4,
                                                          value: "Apr",
                                                        },
                                                        {
                                                          key: 5,
                                                          value: "May",
                                                        },
                                                        {
                                                          key: 6,
                                                          value: "Jun",
                                                        },
                                                        {
                                                          key: 7,
                                                          value: "Jul",
                                                        },
                                                        {
                                                          key: 8,
                                                          value: "Aug",
                                                        },
                                                        {
                                                          key: 9,
                                                          value: "Sep",
                                                        },
                                                        {
                                                          key: 10,
                                                          value: "Oct",
                                                        },
                                                        {
                                                          key: 11,
                                                          value: "Nov",
                                                        },
                                                        {
                                                          key: 12,
                                                          value: "Dec",
                                                        },
                                                      ];
                                                    } else {
                                                      listOfMonth = [
                                                        {
                                                          key: 1,
                                                          value: "Jan",
                                                        },
                                                        {
                                                          key: 2,
                                                          value: "Feb",
                                                        },
                                                        {
                                                          key: 3,
                                                          value: "Mar",
                                                        },
                                                        {
                                                          key: 4,
                                                          value: "Apr",
                                                        },
                                                        {
                                                          key: 5,
                                                          value: "May",
                                                        },
                                                        {
                                                          key: 6,
                                                          value: "Jun",
                                                        },
                                                        {
                                                          key: 7,
                                                          value: "Jul",
                                                        },
                                                        {
                                                          key: 8,
                                                          value: "Aug",
                                                        },
                                                        {
                                                          key: 9,
                                                          value: "Sep",
                                                        },
                                                        {
                                                          key: 10,
                                                          value: "Oct",
                                                        },
                                                        {
                                                          key: 11,
                                                          value: "Nov",
                                                        },
                                                        {
                                                          key: 12,
                                                          value: "Dec",
                                                        },
                                                      ];
                                                    }
                                                    setFieldValue(
                                                      (values.listOfMonth = listOfMonth)
                                                    );
                                                  }}
                                                  className={
                                                    "form-control" +
                                                    (errors.euinday &&
                                                    touched.euinday
                                                      ? "is-invalid"
                                                      : "")
                                                  }
                                                  className="form-control"
                                                >
                                                  <option value="">DD</option>
                                                  {(
                                                    this.state.listOfDay || []
                                                  ).map((d) => (
                                                    <option value={d}>
                                                      {d}
                                                    </option>
                                                  ))}
                                                </Field>
                                              </div>
                                              <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                                <Field
                                                  as="select"
                                                  name="euinmonth"
                                                  id="euinmonth"
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      (values.euinmonth =
                                                        e.target.value)
                                                    );
                                                    if (e.target.value == 2) {
                                                      var listOfDay = [];
                                                      for (
                                                        var i = 1;
                                                        i <= 29;
                                                        i++
                                                      ) {
                                                        listOfDay.push(i);
                                                      }
                                                    } else if (
                                                      e.target.value == 1 ||
                                                      e.target.value == 3 ||
                                                      e.target.value == 5 ||
                                                      e.target.value == 7 ||
                                                      e.target.value == 8 ||
                                                      e.target.value == 10 ||
                                                      e.target.value == 12
                                                    ) {
                                                      var listOfDay = [];
                                                      for (
                                                        var i = 1;
                                                        i <= 31;
                                                        i++
                                                      ) {
                                                        listOfDay.push(i);
                                                      }
                                                    } else {
                                                      var listOfDay = [];
                                                      for (
                                                        var i = 1;
                                                        i <= 30;
                                                        i++
                                                      ) {
                                                        listOfDay.push(i);
                                                      }
                                                    }
                                                    setFieldValue(
                                                      (values.listOfDay = listOfDay)
                                                    );
                                                  }}
                                                  className={
                                                    "form-control" +
                                                    (errors.euinmonth &&
                                                    touched.euinmonth
                                                      ? "is-invalid"
                                                      : "")
                                                  }
                                                  className="form-control"
                                                >
                                                  <option value="">MM</option>
                                                  {(
                                                    this.state.listOfMonth || []
                                                  ).map((m) => (
                                                    <option value={m.key}>
                                                      {m.value}
                                                    </option>
                                                  ))}
                                                </Field>
                                              </div>
                                              <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                                <Field
                                                  as="select"
                                                  name="euinyear"
                                                  id="euinyear"
                                                  className={
                                                    "form-control" +
                                                    (errors.euinyear &&
                                                    touched.euinyear
                                                      ? "is-invalid"
                                                      : "")
                                                  }
                                                  className="form-control"
                                                >
                                                  <option value="">YYYY</option>
                                                  {(
                                                    this.state
                                                      .certificateYear || []
                                                  ).map((euinyear) => (
                                                    <option value={euinyear}>
                                                      {euinyear}
                                                    </option>
                                                  ))}
                                                </Field>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div
                                            id="loading-bar-spinner"
                                            class="spinner"
                                            style={{
                                              display: this.state.loading1,
                                            }}
                                          >
                                            <div class="spinner-icon"></div>
                                          </div>
                                          <div className="col-md-6 col-sm-6 col-xs-12">
                                            <Field
                                              className="form-check-input"
                                              type="checkbox"
                                              name="termsandconditions"
                                              disabled={this.state.isDisabled}
                                              style={{
                                                marginRight: "10px",
                                                marginTop: "15px",
                                              }}
                                            />
                                            <span
                                              class="form-check-label"
                                              for="gridCheck"
                                              style={{ marginRight: "15px" }}
                                            >
                                              <a
                                                href={RENEWALTC}
                                                target="_blank"
                                              >
                                                Terms and Conditions
                                              </a>
                                              <span className="validation">
                                                {" "}
                                                *
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-md-6 col-sm-6 col-xs-12">
                                            {/* {this.state.termsandconditions==true? */}
                                            <button
                                              type="submit"
                                              onClick={handleSubmit}
                                              // className="btn-4 mt-30 pull-right"
                                              className="btn-4 mt-30"
                                            >
                                              Submit
                                            </button>
                                            {/* :''} */}
                                          </div>
                                        </div>
                                      </div>
                                    </Form>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <br />
                          </div>
                        </div>
                      </section>
                    </div>
                  </Form>
                </div>
              </div>
            </React.Fragment>
          )}
        />

        {/* ***************** */}

        <Formik
          enableReinitialize={true}
          initialValues={{
            rejectionReason: this.state.rejectionReason
              ? this.state.rejectionReason
              : "",
          }}
          render={({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            values,
            handleChange,
          }) => (
            <React.Fragment>
              <div
                class="modal fade"
                id="euinNumberRejectedModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-sm" role="document">
                  <Form className="form_name">
                    <div className="modal-content">
                      <button
                        type="button"
                        class="close"
                        aria-label="Close"
                        onClick={(e) => {
                          window.$("#euinNumberRejectedModal").modal("hide");
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>

                      <section className="registration">
                        <div className="modal-body">
                          <div classNames="container">
                            <div
                              className="modal-head"
                              style={{
                                padding: "9px 8px",
                              }}
                            >
                              <div
                                className="section-title-black"
                                style={{ textAlign: "center" }}
                              >
                                <h3 style={{ color: "black" }}>
                                  EUIN Number Rejected
                                </h3>
                              </div>
                            </div>

                            <div className="modal-body">
                              <div className="">
                                <div className="">
                                  <div
                                    style={{
                                      textAlign: "center",
                                      margin: "0 auto",
                                    }}
                                  >
                                    <table style={{ textAlign: "center" }}>
                                      <tr>
                                        <td>
                                          <b>Reason </b>
                                        </td>
                                        <td>:</td>
                                        <td>{this.state.rejectionReason}</td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="mb-20 text-center">
                                <button
                                  type="button"
                                  className="btn-5 mr-20"
                                  onClick={(e) => {
                                    window
                                      .$("#euinNumberRejectedModal")
                                      .modal("hide");
                                  }}
                                  value="Close"
                                >
                                  OK
                                </button>
                              </div>
                            </div>
                            <br />
                          </div>
                        </div>
                      </section>
                    </div>
                  </Form>
                </div>
              </div>
            </React.Fragment>
          )}
        />

        <Formik
          enableReinitialize={true}
          initialValues={{
            renewalrejectionReason: this.state.renewalrejectionReason,
          }}
          render={({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            values,
            handleChange,
          }) => (
            <React.Fragment>
              <div
                class="modal fade"
                id="euinNumberRenewalRejectedModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-sm" role="document">
                  <Form className="form_name">
                    <div className="modal-content">
                      <button
                        type="button"
                        class="close"
                        aria-label="Close"
                        onClick={(e) => {
                          window
                            .$("#euinNumberRenewalRejectedModal")
                            .modal("hide");
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>

                      <section className="registration">
                        <div className="modal-body">
                          <div classNames="container">
                            <div
                              className="modal-head"
                              style={{
                                padding: "9px 8px",
                              }}
                            >
                              <div
                                className="section-title-black"
                                style={{ textAlign: "center" }}
                              >
                                <h3 style={{ color: "black" }}>
                                  EUIN Renewal Rejected
                                </h3>
                              </div>
                            </div>

                            <div className="modal-body">
                              <div className="">
                                <div className="">
                                  <div
                                    style={{
                                      textAlign: "center",
                                      margin: "0 auto",
                                    }}
                                  >
                                    <table style={{ textAlign: "center" }}>
                                      <tr>
                                        <td>
                                          <b>Reason </b>
                                        </td>
                                        <td>:</td>
                                        <td>
                                          {this.state.renewalrejectionReason}
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="mb-20 text-center">
                                <button
                                  type="button"
                                  className="btn-5 mr-20"
                                  onClick={(e) => {
                                    window
                                      .$("#euinNumberRenewalRejectedModal")
                                      .modal("hide");
                                  }}
                                  value="Close"
                                >
                                  OK
                                </button>
                              </div>
                            </div>
                            <br />
                          </div>
                        </div>
                      </section>
                    </div>
                  </Form>
                </div>
              </div>
            </React.Fragment>
          )}
        />

        {/* *************************** */}
        <Formik
          enableReinitialize={true}
          initialValues={{
            rejectionReason: this.state.rejectionReason
              ? this.state.rejectionReason
              : "",
          }}
          render={({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            values,
            handleChange,
          }) => (
            <React.Fragment>
              <div
                class="modal fade"
                id="euinNumberFailedModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-sm" role="document">
                  <Form className="form_name">
                    <div className="modal-content">
                      <button
                        type="button"
                        class="close"
                        aria-label="Close"
                        onClick={(e) => {
                          window.$("#euinNumberFailedModal").modal("hide");
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>

                      <section className="registration">
                        <div className="modal-body">
                          <div classNames="container">
                            <div
                              className="modal-head"
                              style={{
                                padding: "9px 8px",
                              }}
                            >
                              <div
                                className="section-title-black"
                                style={{ textAlign: "center" }}
                              >
                                <h3 style={{ color: "black" }}>
                                  EUIN Number Failed
                                </h3>
                              </div>
                            </div>

                            <div className="modal-body">
                              <div className="">
                                <div className="">
                                  <div
                                    style={{
                                      textAlign: "center",
                                      margin: "0 auto",
                                    }}
                                  >
                                    <table style={{ textAlign: "center" }}>
                                      <tr>
                                        <td>
                                          <b>Reason </b>
                                        </td>
                                        <td>:</td>
                                        <td>{this.state.rejectionReason}</td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="mb-20 text-center">
                                <button
                                  type="button"
                                  className="btn-5 mr-20"
                                  onClick={(e) => {
                                    window
                                      .$("#euinNumberFailedModal")
                                      .modal("hide");
                                  }}
                                  value="Close"
                                >
                                  OK
                                </button>
                              </div>
                            </div>
                            <br />
                          </div>
                        </div>
                      </section>
                    </div>
                  </Form>
                </div>
              </div>
            </React.Fragment>
          )}
        />

        <Formik
          enableReinitialize={true}
          initialValues={{
            renewalrejectionReason: this.state.renewalrejectionReason,
          }}
          render={({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            values,
            handleChange,
          }) => (
            <React.Fragment>
              <div
                class="modal fade"
                id="euinNumberRenewalFailedModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-sm" role="document">
                  <Form className="form_name">
                    <div className="modal-content">
                      <button
                        type="button"
                        class="close"
                        aria-label="Close"
                        onClick={(e) => {
                          window
                            .$("#euinNumberRenewalFailedModal")
                            .modal("hide");
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>

                      <section className="registration">
                        <div className="modal-body">
                          <div classNames="container">
                            <div
                              className="modal-head"
                              style={{
                                padding: "9px 8px",
                              }}
                            >
                              <div
                                className="section-title-black"
                                style={{ textAlign: "center" }}
                              >
                                <h3 style={{ color: "black" }}>
                                  EUIN Renewal Failed
                                </h3>
                              </div>
                            </div>

                            <div className="modal-body">
                              <div className="">
                                <div className="">
                                  <div
                                    style={{
                                      textAlign: "center",
                                      margin: "0 auto",
                                    }}
                                  >
                                    <table style={{ textAlign: "center" }}>
                                      <tr>
                                        <td>
                                          <b>Reason </b>
                                        </td>
                                        <td>:</td>
                                        <td>
                                          {this.state.renewalrejectionReason}
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="mb-20 text-center">
                                <button
                                  type="button"
                                  className="btn-5 mr-20"
                                  onClick={(e) => {
                                    window
                                      .$("#euinNumberRenewalFailedModal")
                                      .modal("hide");
                                  }}
                                  value="Close"
                                >
                                  OK
                                </button>
                              </div>
                            </div>
                            <br />
                          </div>
                        </div>
                      </section>
                    </div>
                  </Form>
                </div>
              </div>
            </React.Fragment>
          )}
        />

        {/***************************** */}

        {/* uat url */}
      {/* <form
          action="https://eiscuat1.camsonline.com/Amfi/AMFI_Integration.aspx"
          method="POST"
        >
         */}
          {/* prod URL */}
            <form
          action="https://web.camsonline.com/Amfi/AMFI_Integration.aspx"
          method="POST"
        >

          <input type="hidden" name="kyd_data" value={this.state.kyd_data} />
          <input
            type="hidden"
            name="session_id"
            value={this.state.session_id}
          />
          <input type="hidden" name="url" value={this.state.url} />
          <button type="submit" ref={(input) => (this.inputElement = input)}>
            Submit
          </button>
        </form>
        {/* ); */}

        {/******************************** */}
        <Formik
          enableReinitialize={true}
          initialValues={{
            picked: "",
            corstateId: "",
            center: {
              label:
                this.state.getDateLocation.length > 0
                  ? this.state.getDateLocation[0].additionalDetails.center
                  : "--select--",
              value:
                this.state.getDateLocation.length > 0
                  ? this.state.getDateLocation[0].additionalDetails.center
                  : "",
            },
            testDate:
              this.state.getDateLocation.length > 0
                ? new Date(
                    this.state.getDateLocation[0].additionalDetails.requestedDate
                  )
                : "",
            location: {
              label:
                this.state.getDateLocation.length > 0
                  ? this.state.getDateLocation[0].additionalDetails.stateName
                  : "--select--",
              value:
                this.state.getDateLocation.length > 0
                  ? this.state.getDateLocation[0].additionalDetails.statId
                  : "",
            },
            // this.state.getDateLocation.length > 0 ? this.state.getDateLocation[0].responseObject.additionalDetails.stateId : '',
            centerList: this.state.centerList,

            termsandcondition: "",
            disabled:
              this.state.getDateLocation.length > 0
                ? this.state.getDateLocation[0].additionalDetails.flag == true
                  ? "true"
                  : "false"
                : "false",
          }}
          validationSchema={Yup.object().shape({
            picked: Yup.string().required("You must select a mode"),
            // corstateId: Yup.string()
            //   .required('You must select a state'),
            location: Yup.string().required("You must select a location"),
            center: Yup.string().required("You must select a center"),
            testDate: Yup.string().required("You must select a Test date"),
            // fromTime:
            //     Yup.string()
            //         .required('You must select from time'),
            // toTime: Yup.string()
            //     .required('You must select to time'),
          })}
          onSubmit={(fields) => {
            console.log("fields");
            console.log(fields);

            if (this.state.disabledAll == true) {
              warning(
                "Your request has already been processed ,so you can't change the data",
                warningNotification
              );
            } else {
              if (fields.termsandcondition === true) {
                console.log(fields);
                var data1 = localStorage.getItem("login");
                var data2 = localStorage.getItem("PersonnelData");

                let data = [
                  {
                    action: "NISM PROFILE CREATE", //"REGISTER",   //CurrentState
                    workflowId: JSON.parse(data2).responseObject.learnerID,
                    //JSON.parse(data2).responseObject.learnerID,
                    additionalDetails: {
                      stateId: fields.location.value,
                      stateName: fields.location.label,
                      center: fields.center.value,
                      requestedDate: fields.testDate1,

                      flag: fields.picked == "online" ? true : false,
                      isconfirmbycilopps: false,
                    },
                  },
                ];

                ReattemptTestService.UpdateWorkflow(data, (res) => {
                  //console.log(res)
                  if (
                    res.data.status === "success" ||
                    res.data.status === "SUCCESS"
                  ) {
                    success(
                      "Your exam date and slot have been confirmed",
                      successNotification
                    );
                    var data1 = localStorage.getItem("PersonnelData");
                    let data_temp = [
                      {
                        userName: JSON.parse(data1).responseObject.firstName,
                        selectedDate: fields.testDate1,
                        location: fields.center.value,
                        emailId: JSON.parse(data1).responseObject.emailID,
                        templateId: "1960250",
                        status: "Location_for_the_NISM_Exam",
                        partyId: JSON.parse(data1).responseObject
                          .learnerPartyID,
                      },
                    ];

                    RegisterUserService.sendMail(data_temp, (res) => {});
                    window.$("#statusPopup").modal("hide");

                    //window.location.reload()
                  }
                });
              } else {
                warning(
                  "Please select terms and condition",
                  warningNotification
                );
              }
            }
          }}
          render={({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            values,
          }) => (
            <React.Fragment>
              <div
                className="modal fade"
                id="statusPopup"
                tabindex="-1"
                role="dialog"
                aria-labelledby="statusPopupLabel"
                data-keyboard="true"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg" role="document">
                  <Form id="myForm">
                    <div className="modal-content">
                      <button
                        type="reset"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        // onClick={this.handleClose}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                      {/* <button type="button" className="close" onClick={this.hidePopup} aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button> */}
                      <div className="col-md-12"></div>
                      <div
                        className="modal-head"
                        style={{
                          padding: "9px 8px",
                        }}
                      >
                        <div
                          className="section-title-black"
                          style={{
                            textAlign: "center",
                            paddingBottom: "0px !important",
                          }}
                        >
                          <h3 style={{ color: "black" }}>Audit Trail</h3>
                        </div>
                      </div>
                      <div className="tab-form">
                        {/* <ul class="tab-group" id="login-reg-tab"> */}

                        {/* <li class={this.state.signuptabName}><a href="#signup" onClick={(e) => {
                                                        this.switchTab(e, 'signup')
                                                    }} >My Status</a></li> */}
                        {/* <li class={this.state.logintabName}><a href="#login" onClick={(e) => { */}
                        {/* // this.switchTab(e, 'login') */}
                        {/* // }} >Audit Trail</a></li> */}
                        {/* onClick={this.switchTab('login')} */}

                        {/* </ul> */}
                        <div class="tab-content">
                          <div id="signup1" style={{ display: "none" }}>
                            <section className="registration">
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-md-5 col-sm-5 col-xs-12">
                                    <div className="modal-head">
                                      <ShowVerticalLinearStepper
                                        personnelProfileData={
                                          this.props.personnelData
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-7 col-sm-7 col-xs-12">
                                    <div id="dateLocationPopup">
                                      <div className="form-group">
                                        <div className="row">
                                          <div className="">
                                            <center style={{ color: "red" }}>
                                              {" "}
                                              {this.state.getDateLocation
                                                .length > 0
                                                ? this.state.getDateLocation[0]
                                                    .additionalDetails
                                                    .action === "CANCEL"
                                                  ? "Your NISM Exam has been Cancelled"
                                                  : ""
                                                : ""}{" "}
                                            </center>
                                            <div className="modal-head">
                                              <div
                                                className="section-title-black"
                                                style={{ textAlign: "center" }}
                                              >
                                                <h3 style={{ color: "black" }}>
                                                  Preferred NISM Exam Date
                                                </h3>
                                              </div>
                                            </div>
                                            <div className="status-box">
                                              <div className="col-md-12">
                                                {/* <p>
                                                                                            Exam date selected should be greater than 15 days from today and less than 180 days
                                                                                            </p>
                                                                                            <p>
                                                                                            This is a preferred date and subject to availability and profile approval with NISM
                                                                                            </p> */}
                                                <p>
                                                  Once the date and time is
                                                  confirmed by NISM, it cannot
                                                  be changed
                                                </p>
                                                <p>
                                                  If you fail to appear for the
                                                  examination on the enrolled
                                                  date and time, you will have
                                                  to pay the exam fees again &
                                                  apply for new date
                                                </p>
                                                <p>
                                                  If are opting for Online
                                                  Examination, please refer to
                                                  the system requirements &
                                                  prerequisites by NISM -
                                                  LinkExam date selected should
                                                  be greater than 15 days from
                                                  today and less than 180 days
                                                </p>
                                                <p>
                                                  This is a preferred date and
                                                  subject to availability and
                                                  profile approval with NISM
                                                </p>

                                                <p>
                                                  Once the date and time is
                                                  confirmed by NISM, it cannot
                                                  be changed
                                                </p>

                                                <p>
                                                  If you fail to appear for the
                                                  examination on the enrolled
                                                  date and time, you will have
                                                  to pay the exam fees again &
                                                  apply for new date
                                                </p>

                                                <p>
                                                  If are opting for Online
                                                  Examination, please refer to
                                                  the system requirements &
                                                  prerequisites by NISM - Link
                                                </p>
                                              </div>
                                              <div className="col-md-12">
                                                <div className="form-group">
                                                  <label
                                                    class="form-check-label"
                                                    htmlFor="gridCheck"
                                                    style={{
                                                      marginRight: "15px",
                                                    }}
                                                  >
                                                    Exam Mode
                                                    <span className="validation">
                                                      {" "}
                                                      *
                                                    </span>
                                                    <br />
                                                    <span className="validation">
                                                      {" "}
                                                      With effect from January
                                                      1, 2023 NISM will stop
                                                      conducting remote
                                                      proctored mode of exam
                                                      (Online mode). Please
                                                      select an Offline mode
                                                      only to appear for the
                                                      test center based
                                                      examinations.
                                                    </span>
                                                  </label>
                                                  <div>
                                                    <Field
                                                      type="radio"
                                                      onClick={(e) => {
                                                        setFieldValue(
                                                          (values.disabled =
                                                            "false")
                                                        );
                                                      }}
                                                      name="picked"
                                                      checked={
                                                        this.state
                                                          .getDateLocation
                                                          .length > 0
                                                          ? this.state
                                                              .getDateLocation[0]
                                                              .additionalDetails
                                                              .flag == true
                                                            ? ""
                                                            : "checked"
                                                          : "offline"
                                                      }
                                                      disabled={
                                                        this.state.disabledAll
                                                      }
                                                      value="offline"
                                                      style={{
                                                        marginRight: "10px",
                                                      }}
                                                      className="form-check-input"
                                                    />
                                                    <label
                                                      class="form-check-label"
                                                      htmlFor="gridCheck"
                                                      style={{
                                                        marginRight: "15px",
                                                      }}
                                                    >
                                                      Offline
                                                    </label>

                                                    <Field
                                                      type="radio"
                                                      name="picked"
                                                      disabled
                                                      // disabled={
                                                      //   this.state.disabledAll
                                                      // }
                                                      checked={
                                                        this.state
                                                          .getDateLocation
                                                          .length > 0
                                                          ? this.state
                                                              .getDateLocation[0]
                                                              .additionalDetails
                                                              .flag == true
                                                            ? "checked"
                                                            : ""
                                                          : "offline"
                                                      }
                                                      onClick={(e) => {
                                                        // warning(

                                                        //     "Coming Soon",
                                                        //     warningNotification
                                                        // );
                                                        setFieldValue(
                                                          (values.testDate = "")
                                                        );
                                                        setFieldValue(
                                                          (values.location = "")
                                                        );
                                                        setFieldValue(
                                                          (values.center = "")
                                                        );

                                                        // setFieldValue(values.disabled = "true")
                                                        setFieldValue(
                                                          (values.disabled =
                                                            "true")
                                                        );
                                                      }}
                                                      className="form-check-input"
                                                      style={{
                                                        marginRight: "10px",
                                                      }}
                                                      value="online"
                                                    />
                                                    <label
                                                      class="form-check-label"
                                                      htmlFor="gridCheck"
                                                      style={{
                                                        marginRight: "15px",
                                                      }}
                                                    >
                                                      Online
                                                    </label>
                                                    <ErrorMessage
                                                      name="picked"
                                                      className="validation"
                                                      component="div"
                                                    />
                                                  </div>
                                                  <label

                                                  // style={values.disabled == "true" ? { display: "none" } : { display: "block" }}
                                                  >
                                                    Exam Date
                                                    <span className="validation">
                                                      {" "}
                                                      *
                                                    </span>
                                                  </label>
                                                  <DatePicker
                                                    selected={values.testDate}
                                                    autoComplete="off"
                                                    placeholderText="dd-mm-yyyy"
                                                    dateFormat="dd-MM-yyyy"
                                                    maxLength="50"
                                                    minDate={new Date()}
                                                    disabled={
                                                      this.state.disabledAll
                                                    }
                                                    onChange={(date) => {
                                                      //setFieldValue(values.testDate = date)

                                                      var timestamp =
                                                        new Date().getTime() +
                                                        30 *
                                                          24 *
                                                          60 *
                                                          60 *
                                                          1000;
                                                      // alert(timestamp)
                                                      let selDate = new Date(
                                                        moment(date).format(
                                                          "YYYY-MM-DD"
                                                        )
                                                      );
                                                      var date = new Date();
                                                      date.setDate(
                                                        date.getDate() + 15
                                                      );

                                                      var date2 = new Date();
                                                      var date2 = date2.setDate(
                                                        date2.getDate() + 181
                                                      );
                                                      if (
                                                        selDate < date ||
                                                        selDate > date2
                                                      ) {
                                                        warning(
                                                          "Date Should greater than 15 day from current date and less than 180 days from current days.",
                                                          warningNotification
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          (values.testDate = date)
                                                        );
                                                        setFieldValue(
                                                          (values.testDate1 = moment(
                                                            date
                                                          ).format(
                                                            "YYYY-MM-DD"
                                                          ))
                                                        );
                                                        //  alert("corrrect")
                                                      }
                                                      // setFieldValue(values.dateOfBirth = date)
                                                    }}
                                                    className={
                                                      "form-control" +
                                                      (errors.testDate &&
                                                      touched.testDate
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                  />
                                                  {/* <Field type="date"
                                                                                                        name="testDate"
                                                                                                        id="testDate"
                                                                                                        //  disabled={this.state.disabledAll}
                                                                                                        autoComplete="testDate"
                                                                                                        min={moment(new Date()).format("YYYY-MM-DD")}
                                                                                                        onChange={e => {
                                                                                                            var timestamp = new Date().getTime() + (30 * 24 * 60 * 60 * 1000)
                                                                                                            // alert(timestamp)
                                                                                                            let selDate = new Date(e.target.value)
                                                                                                            var date = new Date();
                                                                                                            date.setDate(date.getDate() + 15);


                                                                                                            var date2 = new Date();
                                                                                                            var date2 = date2.setDate(date2.getDate() + 61);
                                                                                                            if (selDate < date || selDate > date2) {
                                                                                                                warning(

                                                                                                                    "Date Should greater than 15 day from current date and less than 60 days from current days.",
                                                                                                                    warningNotification
                                                                                                                );
                                                                                                            }
                                                                                                            else {
                                                                                                                setFieldValue(values.testDate = e.target.value)
                                                                                                                //  alert("corrrect")
                                                                                                            }
                                                                                                        }}
                                                                                                        // style={values.disabled == "true" ? { display: "none" } : { display: "block" }}
                                                                                                        disabled={this.state.disabledAll}
                                                                                                        className={
                                                                                                            "form-control" +
                                                                                                            (errors.testDate && touched.testDate
                                                                                                                ? " is-invalid"
                                                                                                                : "")
                                                                                                        }

                                                                                                    />
                                                                                                    <ErrorMessage
                                                                                                        name="testDate"
                                                                                                        className="validation"
                                                                                                        component="div"
                                                                                                    /> */}

                                                  {/* <Field component={TimePicker} name="time" label="Time" /> */}
                                                  <label
                                                    style={
                                                      values.disabled == "true"
                                                        ? { display: "none" }
                                                        : { display: "block" }
                                                    }
                                                  >
                                                    Select Location
                                                    <span className="validation">
                                                      {" "}
                                                      *
                                                    </span>
                                                  </label>
                                                  <span
                                                    style={
                                                      values.disabled == "true"
                                                        ? { display: "none" }
                                                        : { display: "block" }
                                                    }
                                                  >
                                                    <Select
                                                      onChange={(value) => {
                                                        let testDate =
                                                          values.testDate;

                                                        this.onChangeParameter(
                                                          value,
                                                          setFieldValue
                                                        );
                                                        //   setFieldValue(values.testDate = testDate)
                                                      }}
                                                      isDisabled={
                                                        values.disabled ==
                                                        "true"
                                                          ? true
                                                          : this.state
                                                              .disabledAll
                                                      }
                                                      style={
                                                        values.disabled ==
                                                        "true"
                                                          ? { display: "none" }
                                                          : { display: "block" }
                                                      }
                                                      id="location"
                                                      name="location"
                                                      options={
                                                        this.state.cityList
                                                      }
                                                      filterOption={createFilter(
                                                        {
                                                          matchFrom: "start",
                                                        }
                                                      )}
                                                      value={values.location}
                                                    />
                                                  </span>

                                                  <label
                                                    style={
                                                      values.disabled == "true"
                                                        ? { display: "none" }
                                                        : { display: "block" }
                                                    }
                                                  >
                                                    Select Center
                                                    <span className="validation">
                                                      {" "}
                                                      *
                                                    </span>
                                                  </label>
                                                  <span
                                                    style={
                                                      values.disabled == "true"
                                                        ? { display: "none" }
                                                        : { display: "block" }
                                                    }
                                                  >
                                                    <Select
                                                      onChange={(value) => {
                                                        this.onChangeParameterCenter(
                                                          value,
                                                          setFieldValue
                                                        );
                                                      }}
                                                      isDisabled={
                                                        values.disabled ==
                                                        "true"
                                                          ? true
                                                          : this.state
                                                              .disabledAll
                                                      }
                                                      name="center"
                                                      options={
                                                        values.centerList
                                                      }
                                                      filterOption={createFilter(
                                                        {
                                                          matchFrom: "start",
                                                        }
                                                      )}
                                                      value={values.center}
                                                    />
                                                  </span>

                                                  <ErrorMessage
                                                    name="center"
                                                    className="validation"
                                                    component="div"
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-md-12 col-sm-12 col-xs-12">
                                                <Field
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  name="termsandconditions"
                                                  // checked={

                                                  //     values.termsandcondition =
                                                  //     true ? true : false

                                                  // }
                                                  disabled={
                                                    this.state.disabledAll
                                                  }
                                                  onClick={(e) => {
                                                    setFieldValue(
                                                      (values.termsandcondition = true)
                                                    );
                                                  }}
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                />
                                                <span
                                                  class="form-check-label"
                                                  for="gridCheck"
                                                  style={{
                                                    marginRight: "15px",
                                                  }}
                                                >
                                                  <a href={TC} target="_blank">
                                                    Terms and Conditions
                                                    <span className="validation">
                                                      {" "}
                                                      *
                                                    </span>{" "}
                                                    {this.state
                                                      .termsCondtions === true
                                                      ? "(Accepted)"
                                                      : ""}
                                                  </a>
                                                </span>
                                              </div>
                                            </div>

                                            <br />
                                            {values.picked !== "" &&
                                            values.testDate !== "" &&
                                            values.location !== "" &&
                                            values.center !== "" &&
                                            values.termsandcondition !==
                                              false ? (
                                              <div className="mb-20 text-center">
                                                <button
                                                  type="submit"
                                                  className="btn-5 mr-20"
                                                >
                                                  Submit
                                                </button>
                                              </div>
                                            ) : (
                                              <div className="mb-20 text-center">
                                                <button
                                                  type="button"
                                                  onClick={(e) => {
                                                    warning(
                                                      "Please fill all mandatory fields",
                                                      warningNotification
                                                    );
                                                  }}
                                                  className="btn-5 mr-20"
                                                >
                                                  Submit
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      id="
                                                                        "
                                    >
                                      <React.Fragment>
                                        <div>
                                          <Form
                                            onSubmit={handleSubmit}
                                            className="form_name"
                                          >
                                            <div>
                                              <div>
                                                <section className="">
                                                  <div className="">
                                                    <div classNames="">
                                                      <div
                                                        className="modal-head"
                                                        style={{
                                                          padding: "9px 8px",
                                                        }}
                                                      >
                                                        <div
                                                          className="section-title-black"
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          <h3
                                                            style={{
                                                              color: "black",
                                                            }}
                                                          >
                                                            NISM Admit Card
                                                            Available
                                                          </h3>
                                                        </div>
                                                      </div>
                                                      <div className="">
                                                        <div className="form-group text-center">
                                                          <p>
                                                            Your NISM
                                                            Registration is
                                                            completed and your
                                                            Admit card is now
                                                            available.
                                                          </p>
                                                          <a
                                                            onClick={
                                                              this.downloadAdmit
                                                            }
                                                            target="_blank"
                                                          >
                                                            Click here to
                                                            Download
                                                          </a>
                                                        </div>
                                                      </div>
                                                      <div className="">
                                                        {/* <button type="submit" className="btn-5 mr-20">Submit</button> */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </section>
                                              </div>
                                            </div>
                                          </Form>
                                        </div>
                                      </React.Fragment>
                                    </div>
                                    <div id="kydpopup">
                                      {/* <div className="modal-dialog modal-md" role="document"> */}
                                      <div class="modal-content">
                                        <div className="modal-header">
                                          <div class="scr-board">
                                            <h2>ExpertMFD </h2>
                                            <div class="bottom-line"></div>
                                          </div>
                                          {/* <h4 className="modal-title">FinX Core Login</h4> */}
                                        </div>
                                        <div className="modal-body">
                                          <div className="">
                                            <div className="">
                                              <div className="">
                                                <div class="reset">
                                                  {" "}
                                                  <h3 className="message-text">
                                                    Are you sure, do you want
                                                    CIEL to apply for KYD
                                                    Process, on your behalf ?
                                                  </h3>
                                                </div>
                                                <div className="download-kyd mt-20">
                                                  <h5 className="download-text">
                                                    KYD Form{" "}
                                                  </h5>
                                                  <button
                                                    className="btn-12"
                                                    onClick={this.downloadKYD}
                                                  >
                                                    Download
                                                  </button>
                                                </div>
                                                <div className="download-kyd">
                                                  <h5 className="download-text">
                                                    ARN Form{" "}
                                                  </h5>
                                                  <button
                                                    className="btn-12"
                                                    onClick={this.downloadARN}
                                                  >
                                                    Download
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <span className="validation">
                                          Note:-Once changed to applied, you
                                          cannot change it back. Please click OK
                                          to change or cancel
                                        </span>
                                        <div
                                          for="agree"
                                          className="assess-subtext-kyd"
                                        >
                                          <input
                                            type="checkbox"
                                            id="agree"
                                            name="agree"
                                            value="agree"
                                          />
                                          <span> Terms and Conditions</span>
                                        </div>
                                        <div className="text-center">
                                          <button
                                            type="button"
                                            className="btn-5 mt-20 mb-20"
                                            name="btnYes"
                                            onClick={this.KYDSubmit}
                                          >
                                            Submit
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>

                          <div id="audit" style={{ display: "block" }}>
                            <section className="registration">
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-md-12 col-sm-12 col-xs-12">
                                    <AuditTrail
                                      personnelProfileData={
                                        this.props.personnelData
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* </PopUpPortal> */}
                  </Form>
                </div>
              </div>

              <div
                className="modal fade"
                id="cancelExam"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modalLabel"
                data-keyboard="true"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-md" role="document">
                  <Form className="form_name">
                    <div className="modal-content">
                      <button
                        type="reset"
                        class="close"
                        aria-label="Close"
                        onClick={(e) => {
                          window.$("#cancelExam").modal("hide");
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>

                      <section className="registration">
                        <div className="modal-body">
                          <div classNames="container">
                            <div
                              className="modal-head"
                              style={{
                                padding: "9px 8px",
                              }}
                            >
                              <div
                                className="section-title-black"
                                style={{ textAlign: "center" }}
                              >
                                <h3 style={{ color: "black" }}>
                                  Your NISM exam date has been cancelled
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </Form>
                </div>
              </div>
            </React.Fragment>
          )}
        />

        <div
          className="modal fade"
          id="amcCount"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modalLabel"
          data-keyboard="true"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md" role="document">
            <div class="modal-content">
              <div className="modal-header">
                <div class="scr-board">
                  <h2>Empanelled AMC's</h2>
                  <div class="bottom-line"></div>
                </div>
              </div>
              <div className="modal-body">
                <div id="">
                  <table
                    width="100%"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                  >
                    <tr>{/* <th>AMC Names</th> */}</tr>
                    {this.state.AMCData.length > 0
                      ? this.state.AMCData.map((e, i) => {
                          i = i + 1;
                          return (
                            <tr>
                              <td>
                                {i}. {e.amcName}
                              </td>
                            </tr>
                          );
                        })
                      : "No Data Found"}
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  className="btn-5"
                  name="btnNo"
                  onClick={(e) => {
                    window.$("#amcCount").modal("hide");
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LearnerDashboard;
